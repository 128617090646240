import React from 'react'
import '../scss/style.scss'
import { Router, Route, Redirect } from 'react-router-dom'
// import Sidebar from './sidebar/Sidebar'
// import Header from './header/Header'
import Container from './container/Container'
import Login from './login/Login'
import Signup from './signup/Signup'
import ForgotPassword from './forgotPassword/ForgotPassword'
import { history } from '../_helpers'

const App = (props) => {
  let currentDate = new Date()
  let oldDate = localStorage.getItem('loginTime')
  let oldDateObj = new Date(oldDate)
  let difference = currentDate - oldDateObj
  // var hoursDifference = Math.floor(difference/1000/60/60);
  // difference -= hoursDifference*1000*60*60
  var minutesDifference = Math.floor(difference/1000/60)
  difference -= minutesDifference*1000*60
  if(minutesDifference >= 30 || oldDate === null || oldDate === undefined){
    localStorage.removeItem('token')
  }
  else{
    localStorage.setItem('loginTime', currentDate)
  }
  return(
    // <Router>
    //   {token !== 'test' ? <Login /> : 
    //     <div>
    //       <Header />
    //       <div className="container-fluid page-body-wrapper">
    //         <Sidebar />
    //         <Container />
    //       </div>    
    //     </div>
    //   }
    // </Router>
    <Router history={history}>
      <div>
        <Route path="/" exact render={() => <Redirect to="/login"/>}/>
        <Route path="/dashboard" component={Container}/>
        <Route path="/traineeDashboard" component={Container} />
        <Route path="/signup" exact component={Signup}/>
        <Route path="/login" exact component={Login}/>
        <Route path="/forgotpassword" exact component={ForgotPassword}/>
        <Route path="/resetpassword" component={Container} />
        <Route path="/notification" exact component={Container}/>
        <Route path="/notification-list" exact component={Container} />
        <Route path="/assessment" exact component={Container} />
        <Route path="/profile" exact component={Container} />
        <Route path="/viewTraineeProfile/:id" exact component={Container}  />
        <Route path="/users" exact component={Container} />
        <Route path="/viewAdminProfile/:id" exact component={Container} />
        <Route path="/viewTest/:id" exact component={Container} />
        <Route path="/userProfile" exact component={Container} />
        <Route path="/traineeusers" exact component={Container} />
        <Route path="/editProfile" exact component={Container} />
        <Route path="/testAttempt/:id" exact component={Container} />
        <Route path="/viewReport" exact component={Container} />
        <Route path="/userDetails/:id" exact component={Container} />
        <Route path="/traineeuserDetails/:id" exact component={Container} />
        <Route path="/profileAssigned" exact component={Container} />
        <Route path="/viewCurrentReport/:id" exact component={Container} />
        <Route path="/viewInviteeList" exact component={Container} />
      </div>
    </Router>
  )
}

export default App
