import { SEND_NOTIFICATION_REQUEST, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_ERROR } from '../constants/NotificationConstants'

const initialState = {
  loading: false,
  error: ''
}

const notification = (state = initialState, action) => {
  switch (action.type) {
    case SEND_NOTIFICATION_REQUEST:
      return{
        ...state,
        loading: true
      }
    case SEND_NOTIFICATION_SUCCESS:
      return{
        ...state,
        loading: false
      }
    case SEND_NOTIFICATION_ERROR:
      return{
        loading: false,
        error: action.err
      }
    default:
      return state
  }
} 

export default notification