import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class AdminProfilePurchasedGraph extends Component {
	render() {
		const { profilePurchased, maximumPurchasedValue, profileValue, categories } = this.props
		let Maximum
		if(maximumPurchasedValue <= 25){
			Maximum = 25
		}
		else if(maximumPurchasedValue > 25 && maximumPurchasedValue <= 50){
			Maximum = 50
		}
		else if(maximumPurchasedValue > 50){
			Maximum = maximumPurchasedValue
		}
		const options = {
			title:{
                text:'Profiles Purchased in Last 12 Months',
                style: {
                    color: '#000000',
					// font: '32px "Dejavu Sans"'
				},
				align: 'center',
				x: -10,
				verticalAlign: 'bottom',
				y: 15,
				widthAdjust: 10
			},
			chart: {
				type: 'column',
			},
			plotOptions: {
				series: {
					pointWidth: 25
				}
			},
			credits:{enabled:false},
			xAxis:{
				type: 'category',
				lineColor: '#726d6d',
				lineWidth: 1,
				labels: {
					style: {
						fontWeight: 'bold',
						color: '#000000',
					}
				},
				title: {
					text:  ''
				},
				reversed: true
			},
			yAxis:{
				min:0,
				max: Maximum,
				lineColor: '#726d6d',
				lineWidth: 1,
				labels: {
					style: {
						fontWeight: 'bold',
						color: '#000000',
					}
				},
				title: {
					text:  ''
				},
				gridLineColor: '#726d6d'
			},    
			tooltip: {
				pointFormat: '{point.y}',
				clusterFormat: '{point.y}: {point.y}'
			},
			series: [{
				showInLegend:false,
				data: profilePurchased
			}]
		}
		const totalProfileOptions = {
			chart: {
				type: 'column'
			},
			title: {
				text: 'Profiles Purchased in Last 12 Months',
				style: {
                    color: '#000000',
					// font: '32px "Dejavu Sans"'
				},
				align: 'center',
				x: -10,
				verticalAlign: 'bottom',
				y: 15,
				widthAdjust: 10
			},
			credits:{enabled:false},
			xAxis: {
				categories: categories,
				lineColor: '#726d6d',
				lineWidth: 1,
				labels: {
					style: {
						fontWeight: 'bold',
						color: '#000000',
					}
				},
				title: {
					text:  ''
				},
				reversed: true
			},
			yAxis: {
				min: 0,
				max: Maximum,
				lineColor: '#726d6d',
				lineWidth: 1,
				labels: {
					style: {
						fontWeight: 'bold',
						color: '#000000',
					}
				},
				title: {
					text:  ''
				},
				gridLineColor: '#726d6d',
				stackLabels: {
					enabled: true,
					style: {
						fontWeight: 'bold',
						color: ( // theme
							Highcharts.defaultOptions.title.style &&
							Highcharts.defaultOptions.title.style.color
						) || 'gray'
					}
				}
			},
			legend: {
				align: 'right',
				x: -30,
				verticalAlign: 'top',
				y: 25,
				floating: true,
				backgroundColor:
					Highcharts.defaultOptions.legend.backgroundColor || 'white',
				borderColor: '#CCC',
				borderWidth: 1,
				shadow: false
			},
			tooltip: {
				headerFormat: '<b>{point.x}</b><br/>',
				pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
			},
			plotOptions: {
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: false
					}
				}
			},
			series: profilePurchased
		}
		return (
			<HighchartsReact highcharts={Highcharts} options={profileValue === "Profile" ? options : totalProfileOptions} />
		)
	}
}

export default AdminProfilePurchasedGraph