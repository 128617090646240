import { call, put, takeLatest } from 'redux-saga/effects'
import { SEND_NOTIFICATION_REQUEST, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_ERROR } from '../constants/NotificationConstants'

function notificationSend(value){
}

function* sendNotification(data){
    try{
        const { value } = data
        yield call(notificationSend, value)
        yield put({ type: SEND_NOTIFICATION_SUCCESS })
    }
    catch(error){
        const err = error.message
        yield put({ type: SEND_NOTIFICATION_ERROR, err })
    }
}

export default () => {
    function * watcher() {
        yield takeLatest(SEND_NOTIFICATION_REQUEST, sendNotification)
    }
    return {watcher}
}

