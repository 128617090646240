import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import { logoutUser } from '../../actions/AuthActions'
import { useHistory } from 'react-router-dom'
import './Sidebar.scss'

const Sidebar = (props) => {
  let history = useHistory()
  let pathname = history.location.pathname
  let shortName = pathname.split('/')
  let path = shortName[1]
  let type = localStorage.getItem('type')

    useEffect(() => {
      $('.nav > li > a[href="'+pathname+'"]').parent().addClass('active')
      if(pathname === "/userProfile" || pathname === "/editProfile" || pathname === "/resetpassword"){
        $('.collapse').addClass('show')
      }
        $(document).on('mouseenter mouseleave', '.sidebar .nav-item', function(ev) {
            var body = $('body');
            var sidebarIconOnly = body.hasClass("sidebar-icon-only");
            var sidebarFixed = body.hasClass("sidebar-fixed");
            if (!('ontouchstart' in document.documentElement)) {
              if (sidebarIconOnly) {
                if (sidebarFixed) {
                  if (ev.type === 'mouseenter') {
                    body.removeClass('sidebar-icon-only');
                  }
                } else {
                  var $menuItem = $(this);
                  if (ev.type === 'mouseenter') {
                    $menuItem.addClass('hover-open')
                  } else {
                    $menuItem.removeClass('hover-open')
                  }
                }
              }
            }
        })
    })

    const logout = () => {
      props.logoutUser()
    }

    const viewReportBtn = () => {
      localStorage.setItem('report_paramter', "sidebar")
      localStorage.removeItem('report_user_id')
    }

    const cancelledInviteBtn = () => {
      localStorage.setItem('cancelled_paramter', "sidebar")
      localStorage.removeItem('current_profile_id')
    }

    return(
        <div>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    {type === "Admin" ? 
                    <li className="nav-item">
                        <a className="nav-link" href="/dashboard">
                        <i className="mdi mdi-home menu-icon"></i>
                        <span className="menu-title">Dashboard</span>
                        </a>
                    </li>
                    : type === "trainer" ? 
                    <li className="nav-item">
                    <a className="nav-link" href="/traineeDashboard">
                    <i className="mdi mdi-home menu-icon"></i>
                    <span className="menu-title">Dashboard</span>
                    </a>
                  </li> : null}
                    {type === "trainer" ? 
                    <li className={path === "viewTraineeProfile" || path === "viewTest" || path === "testAttempt" ? "nav-item active" : "nav-item"}>
                        <a className="nav-link" href="/assessment">
                        <i className="mdi mdi-checkbox-multiple-blank menu-icon"></i>
                        <span className="menu-title">Profile Library</span>
                        </a>
                    </li> : null}
                    {type === "Admin" ? 
                    <li className={path === "viewAdminProfile" || path === "viewTest" ? "nav-item active": "nav-item"}>
                        <a className="nav-link" href="/profile">
                        <i className="mdi mdi-account menu-icon"></i>
                        <span className="menu-title">Assessment Profiles</span>
                        </a>
                    </li>
                   : null}
                    <li className={path === "traineeuserDetails" ? "nav-item active" : "nav-item"}>
                        {type === "Admin" ?
                        <a className="nav-link" href="/users">
                        <i className="mdi mdi-account-multiple-outline menu-icon"></i>
                        <span className="menu-title">Customers</span>
                        </a>
                        : type === "trainer" ? <a className="nav-link" href="/traineeusers">
                        <i className="mdi mdi-account-multiple-outline menu-icon"></i>
                        <span className="menu-title">Assignee</span>
                        </a> : null}
                    </li>
                    {type === "trainer" || type === "user" ? 
                    <li className="nav-item">
                        <a className="nav-link" href="/profileAssigned">
                        <i className="mdi mdi-checkbox-multiple-blank menu-icon"></i>
                        <span className="menu-title">Assigned Profile</span>
                        </a>
                    </li> : null}
                    {/* <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#notification" aria-expanded="false" aria-controls="notification">
                        <i className="mdi mdi-bell-outline menu-icon"></i>
                        <span className="menu-title">Notifications</span>
                        <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="notification">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <a className="nav-link" href="/notification-list"> Notification </a></li>
                            <li className="nav-item"> <a className="nav-link" href="/notification"> Send Notification </a></li>
                        </ul>
                        </div>
                    </li> */}
                    <li className={pathname === "/userProfile" || pathname === "/editProfile" || pathname === "/resetpassword" ? 'nav-item active': 'nav-item'}>
                      <a className="nav-link collapsed" data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
                        <i className="mdi mdi-settings menu-icon"></i>
                        <span className="menu-title">Settings</span>
                        <i className="menu-arrow"></i>
                      </a>
                      <div className="collapse" id="auth">
                        <ul className="nav flex-column sub-menu">
                          <li className="nav-item"> 
                            <a className={pathname === "/userProfile" ? 'nav-link active': 'nav-link'} href="/userProfile"> User Profile </a>
                          </li>
                          <li className="nav-item"> 
                            <a className={pathname === "/editProfile" ? 'nav-link active': 'nav-link'} href="/editProfile"> Edit Profile </a>
                          </li>
                          <li className="nav-item"> 
                            <a className={pathname === "/resetpassword" ? 'nav-link active': 'nav-link'} href="/resetpassword"> Reset Password </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {type === "trainer" || type === "user" ? 
                    <li className={path === "viewCurrentReport" ? "nav-item active" : "nav-item"}>
                        <a className="nav-link" href="/viewReport" onClick={viewReportBtn}>
                        <i className="mdi mdi-checkbox-multiple-blank menu-icon"></i>
                        <span className="menu-title">View Report</span>
                        </a>
                    </li> : null}
                    {type === "trainer" || type === "Admin" ?
                    <li className="nav-item">
                        <a className="nav-link" href="/viewInviteeList" onClick={cancelledInviteBtn}>
                        <i className="mdi mdi-account-multiple-outline menu-icon"></i>
                        <span className="menu-title">Cancelled Invitees</span>
                        </a>
                    </li>
                    : null}
                    <li className="nav-item logoutBtn">
                      <a className="nav-link" type="button" onClick={logout} data-toggle="offcanvas"> 
                        <i className="mdi mdi-logout menu-icon"></i> 
                        <span className="menu-title">Logout</span>
                      </a>
                   </li>
                </ul>
            </nav>
        </div>
    )
}

const mapDispatchToProps = {
  logoutUser
}

export default connect(null, mapDispatchToProps)(Sidebar)