import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../_helpers'
import Footer from '../footer/Footer'
import Breadcrumb from '../breadcrumb/Breadcrumb'
import { resetPassword } from '../../actions/AuthActions'
import ReactLoading from "react-loading"

const ResetPassword = (props) => {
    const { passwordError, loggingIn } = props
    let pathname = props.location.pathname
    const [data, setData] = useState({
        newPassword: "", 
        confirmnewPassword: ""
    })
    const [error, setError] = useState({
        newPasswordError: "", 
        confirmnewPasswordError: "",
        err: ""
    })

	useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
            }
            else{
                history.push('/login') 
            }
        }
    })
    
    const handleChange = (event) => {
        const target = event.target
        const { name, value } = target
        setData({ ...data, [name]: value })
    }

    const passwordValidation = (data) => {
        const err = "This field is Required"
        const { newPassword, confirmnewPassword } = data
        if(newPassword === "" || confirmnewPassword === ""){
            if(newPassword === ""){
                setError({
                  ...error,
                  newPasswordError: err
                })
            }
            else if(confirmnewPassword === ""){
                setError({
                  ...error,
                  confirmnewPasswordError: err
                })
            }
        }
        else if(newPassword !== "" && confirmnewPassword !== ""){
            if(newPassword === confirmnewPassword){
                props.resetPassword(newPassword)
            }
            else{
                setError({ 
                    err: "Password dosen't matches" 
                })
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        passwordValidation(data)
    }
	
    return(
        <div className="main-panel">
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Profile" subTitle="Reset Password" />
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title pt-2">Reset Password</h4>
                                <form onSubmit={handleSubmit}>
                                    {loggingIn ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                                    {passwordError !== "" ? passwordError : null}
                                    {error.err && <p className="errorMsg"> {error.err} </p>}
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" 
                                               className="form-control" 
                                               name="newPassword"
                                               value={data.newPassword}
                                               onChange={handleChange}
                                               placeholder="Enter New Password" />
                                    </div>
                                    {error.newPasswordError && <p className="errorMsg"> {error.newPasswordError} </p>}
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" 
                                               className="form-control" 
                                               name="confirmnewPassword" 
                                               placeholder="Enter New Password"
                                               value={data.confirmnewPassword}
                                               onChange={handleChange} />
                                    </div>
                                    {error.confirmnewPasswordError && <p className="errorMsg"> {error.confirmnewPasswordError} </p>}
                                    <button type="submit" className="btn btn-primary mr-2">Submit</button>
                                    <a href="/userProfile"><button type="button" className="btn btn-secondary">Cancel</button></a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    passwordError: state.authReducer.passwordError,
    loggingIn: state.authReducer.loggingIn
})

const mapDispatchToProps = {
    resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)