import React from 'react'

const NotificationList = () => {
    return(
        <div>
            No Data 
        </div>
    )
}

export default NotificationList