import { call, put, takeLatest } from 'redux-saga/effects'
import { history } from '../_helpers'
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT_USER, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR } from '../constants/AuthConstants'
import firebase from '../components/firebase'
import "firebase/auth"
// import { db } from '../components/firebase'

function getUser(id){
    return firebase.firestore().collection("users").doc(id).get()
    .then(response => response)
}

function updateUser(data, id){
  return firebase.firestore().collection("users").doc(id).set(data, {merge: true})
        .then(response => response)
        .catch(error => { throw error })
}

function* loginApi (data) {
  const { email, password } = data
  try{
    const auth = firebase.auth()
    const value = yield call(
      [auth, auth.signInWithEmailAndPassword],
      email,
      password
    )
    const token = value.user.refreshToken
    const uid = value.user.uid
    localStorage.setItem('token', token)
    localStorage.setItem('email', email)
    localStorage.setItem('uid', uid)
    if(token){
      const list = yield call(getUser, uid)
      const currentUserList = list.data()
    //   if(currentUserList === undefined || currentUserList.status === "Inactive"){
    //     const err = "You are not authorized to access this website"
    //     yield put({ type: LOGIN_ERROR, err })
    //   }
    //   else{
    //     localStorage.setItem('currentUserRole', currentUserList.role)
    //     yield put({ type: LOGIN_SUCCESS })
    //     history.push('/dashboard')
    //   }
        if(currentUserList !== undefined){
          localStorage.setItem('type', currentUserList.type)
          let user_name = currentUserList.name
          let data = {'user_status': 1}
          yield call(updateUser, data, uid)
          localStorage.setItem('user_name', user_name)
          let currentDate = new Date()
          localStorage.setItem('loginTime', currentDate)
        }
        const msg = "Login Successfully"
        yield put({ type: LOGIN_SUCCESS, msg })
        if(currentUserList.type === 'Admin'){
          history.push('/dashboard')
        }
        else if(currentUserList.type === "trainer"){
          history.push('/traineeDashboard')
        }
        else{
          history.push('/profileAssigned')
        }
    }
    else{
      const err = "Invalid username OR password"
      yield put({ type: LOGIN_ERROR, err })
    }
  }
  catch(error){
    const err = "Invalid username OR password"
    yield put({ type: LOGIN_ERROR, err })
  }
}

function logoutuser(){
    localStorage.removeItem('token')
    history.push('/login')
}

function reauthenticate(newPassword){
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      user.updatePassword(newPassword)
      .then(response => response)
      .catch(function(error){
      })
    } else {}
  })
}

function* resetPassword(data){
  const { newPassword } = data
  try{
    yield call(reauthenticate, newPassword)
    const msg = "Password Changed Successfully"
    yield put({ type: RESET_PASSWORD_SUCCESS, msg })
    localStorage.removeItem('token')
    history.push({
      pathname: "/login",
      showMsg: true
    })
  }
  catch(error){
    const errorMsg = error.message
    yield put({ type: RESET_PASSWORD_ERROR, errorMsg })
  }
}

function changePassword(email){
  var auth = firebase.auth()
  auth.sendPasswordResetEmail(email)
  .then(response => response)
  .catch(function(error) {})
}

function* forgotPassword(data){
  const { email } = data.email
  try{
    yield call(changePassword, email)
    const msg = "Reset password email sent to your email address"
    yield put({ type: FORGOT_PASSWORD_SUCCESS, msg })
    history.push({
      pathname: "/login"
    })
  }
  catch(error){
    const errorMsg = error.message
    yield put({ type: FORGOT_PASSWORD_ERROR, errorMsg })
  }
}

export default () => {
    function * watcher() {
      yield takeLatest(LOGIN_REQUEST, loginApi)
      yield takeLatest(LOGOUT_USER, logoutuser)
      yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword)
      yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword)
    }
    return {watcher}
}

