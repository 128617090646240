import { fork } from 'redux-saga/effects'
import NotificationsSaga from './NotificationSaga'
import AuthSaga from './AuthSaga'
import HelperSaga from './HelperSaga'
import ProfileSaga from './ProfileSaga'
import DashboardSaga from './DashboardSaga'

export default function * sagas() {
  yield fork(NotificationsSaga().watcher)
  yield fork(AuthSaga().watcher)
  yield fork(HelperSaga().watcher)
  yield fork(ProfileSaga().watcher)
  yield fork(DashboardSaga().watcher)
}