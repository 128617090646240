import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ReactLoading from "react-loading"
import { validate } from '../validate/EmailValidate'
import { sendInvite, updateQuantity, getUserProfile, removeProfileErrorMsg } from '../../../actions/ProfileActions'

const InviteContainer = (props) => {
    const { profileId, profileName, orderId, remainingQuantity, loading, error, currentProfile, sendInvite, updateQuantity, inviteMsg, usersList, assigneeList, getUserProfile, profileErrorMsg, removeProfileErrorMsg } = props
    const [data, setData] = useState([])
    const [msg, setMsg] = useState('')
    const [emailValue, setEmailValue] = useState('')
    const [checkEmailValue, setCheckEmailValue] = useState(0)
    const [customerArray, setCustomers] = useState('')
    const [Update, setUpdate] = useState(true)
    const [InviteMsg, setInviteMsg] = useState('')
    const [ErrorMsg, setErrorMsg] = useState('')
    const [show, setShow] = useState(true)
    const [validatemsg, setValidatemsg] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    let type = localStorage.getItem('type')
    let uid = localStorage.getItem('uid')

    const handleClose = () => {
        setEmailValue('')
        setMsg('')
        setValidatemsg('')
        _.forEach(data, function(list){
            document.getElementById(list.id).checked = false
        })
        setData([])
        setInviteMsg('')
        setErrorMsg('')
        getUserProfile(uid)
        setFirstName('')
        setLastName('')
        removeProfileErrorMsg()
    }

    useEffect(() => {
        window.$('#invite').modal('show')
    },[])

    const handleChange = (event, users, currentProfile) => {
        setMsg('')
        if(event.target.checked){
            users['checked'] = true
            currentProfile['id'] = profileId
            setData([...data, users])
        }
        else{
            users['checked'] = false
        }
    }

    const renderSendInviteTrainer = (event) => {
        const newUrl = window.location.href.split('/')
        var domainUrl = newUrl[0] + "//" + newUrl[2]
        event.preventDefault()
        setUpdate(true)
        setShow(true)
        const newData = _.uniq(_.filter(data, ['checked', true]))
        let customers = []
        if(emailValue === ''){
            if(newData.length <= 2 && newData.length > 0 && newData.length <= remainingQuantity){
                let firstname, lastname
                _.forEach(newData, function(item){
                    var nameSplit = item.name.split(' ')
                    if(nameSplit[0] === undefined){
                        firstname = ""
                    }
                    else{
                        firstname = nameSplit[0]
                    }
                    if(nameSplit[1] === undefined){
                        lastname = ""
                    }
                    else{
                        lastname = nameSplit[1]
                    }
                    customers.push({ 'email': item.email, 'phone': item.phone_number, 'first_name': firstname, 'last_name': lastname, 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                })
                setCustomers(customers)
                sendInvite(customers, profileId)
                setMsg('')
                setEmailValue('')
                setFirstName('')
                setLastName('')
            }
            else if(newData.length > 2){
                setMsg('Invite can be send to two users only at a time')
            }
            else if(newData.length > remainingQuantity){
                setMsg(`You have only ${remainingQuantity} quantity remaining for this profile`)
            }
            else{
                setMsg('Please enter an email or select any user')
            }
        }
        else{
            let count = 0, checkEmail
            let newArray = emailValue.split(',')
            _.forEach(newArray, function(item){
                let newItem = _.trim(item)
                checkEmail = validate(newItem)
                if(!checkEmail){
                    count = 1
                }
            })
            _.forEach(newArray, function(item){
                let newItem = _.trim(item)
                checkEmail = validateEmail(newItem)
                if(checkEmail){
                    count = 1
                }
            })
            if(count === 0){
                if(_.isEmpty(firstName)){
                    setMsg('Please enter first name')
                }
                else{
                    setMsg('')
                    if(newArray.length <= 1 && newData.length < 2){
                        if(newArray.length > 0 && newArray.length <= remainingQuantity){
                            _.forEach(newArray, function(item){
                                customers.push({ 'email': item, 'phone': '', 'first_name': firstName, 'last_name': lastName, 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                            })
                            if(newData.length > 0 && newData.length <= remainingQuantity - 1){
                                let firstname, lastname
                                _.forEach(newData, function(item){
                                    var nameSplit = item.name.split(' ')
                                    if(nameSplit[0] === undefined){
                                        firstname = ""
                                    }
                                    else{
                                        firstname = nameSplit[0]
                                    }
                                    if(nameSplit[1] === undefined){
                                        lastname = ""
                                    }
                                    else{
                                        lastname = nameSplit[1]
                                    }
                                    customers.push({ 'email': item.email, 'phone': item.phone_number, 'first_name': firstname, 'last_name': lastname, 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                                })
                                setCustomers(customers)
                                sendInvite(customers, profileId)
                                setMsg('')
                                setEmailValue('')
                                setFirstName('')
                                setLastName('')
                            }
                            else if(newData.length > remainingQuantity - 1){
                                setMsg(`You have only ${remainingQuantity} quantity remaining for this profile`)
                            }
                            else{
                                setCustomers(customers)
                                sendInvite(customers, profileId)
                                setMsg('')
                                setEmailValue('')
                                setFirstName('')
                                setLastName('')
                            }
                        }
                    }
                    else if(newArray.length === 1 && newData.length === 0 && newArray.length <= remainingQuantity){
                        setMsg('')
                        _.forEach(newArray, function(item){
                            customers.push({ 'email': item, 'phone': '', 'first_name': firstName, 'last_name': lastName, 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                        })
                        setCustomers(customers)
                        sendInvite(customers, profileId)
                        setEmailValue('')
                        setFirstName('')
                        setLastName('')
                    }
                    else if(newArray.length > remainingQuantity){
                        setMsg(`You have only ${remainingQuantity} quantity remaining for this profile`)
                    }
                    else{
                        if(newData.length > remainingQuantity){
                            setMsg(`You have only ${remainingQuantity} quantity remaining for this profile`)
                        }
                        else{
                            if(newArray.length <=1 && newData.length === 0){
                                setMsg('Invite can be send to one user only at a time')
                            }
                            else{
                                setMsg('Invite can be send to two users only at a time')
                            }
                        }
                    }
                }
            }
            else{
                let loginEmail = localStorage.getItem('email')
                const filterArray = _.filter(newArray, function(o){
                    return _.trim(_.toLower(o)) === loginEmail
                })
                if(filterArray.length !== 0){
                    setValidatemsg('You cannot assign an assessment to yourself. If you wish to take an assessment, click start assessment in the Profile Library section of your account dashboard')
                }
                else{
                    setMsg('Please enter a valid email address')
                    setValidatemsg('')
                }
            }
        }
    }

    const renderSendInvite = (event) => {
        event.preventDefault()
        const newUrl = window.location.href.split('/')
        var domainUrl = newUrl[0] + "//" + newUrl[2]
        const newData = _.uniq(_.filter(data, ['checked', true]))
        let customers = []
        setUpdate(true)
        setShow(true)
        if(emailValue === ''){
            if(newData.length <= 2 && newData.length > 0){
                let firstname, lastname
                _.forEach(newData, function(item){
                    var nameSplit = item.name.split(' ')
                    if(nameSplit[0] === undefined){
                        firstname = ""
                    }
                    else{
                        firstname = nameSplit[0]
                    }
                    if(nameSplit[1] === undefined){
                        lastname = ""
                    }
                    else{
                        lastname = nameSplit[1]
                    }
                    customers.push({ 'email': item.email, 'phone': item.phone_number, 'first_name': firstname, 'last_name': lastname, 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                })
                if(type === "trainer"){
                    setCustomers(customers)
                }
                sendInvite(customers, profileId)
                setMsg('')
                setEmailValue('')
                setFirstName('')
                setLastName('')
            }
            else if(newData.length > 2){
                setMsg('Invite can be send to two users only at a time')
            }
            else{
                setMsg('Please enter an email or select any user')
            }
        }
        else{
            let count = 0, checkEmail
            let newArray = emailValue.split(',')
            _.forEach(newArray, function(item){
                let newItem = _.trim(item)
                checkEmail = validate(newItem)
                if(!checkEmail){
                    count = 1
                }
            })
            _.forEach(newArray, function(item){
                let newItem = _.trim(item)
                checkEmail = validateEmail(newItem)
                if(checkEmail){
                    count = 1
                }
            })
            if(count === 0){
                if(_.isEmpty(firstName)){
                    setMsg('Please enter first name')
                }
                else{
                    setMsg('')
                    if(newArray.length <= 1 && newData.length < 2){
                        if(newArray.length > 0){
                            _.forEach(newArray, function(item){
                                customers.push({ 'email': item, 'phone': '', 'first_name': firstName, 'last_name': lastName, 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                            })
                            if(newData.length > 0){
                                let firstname, lastname
                                _.forEach(newData, function(item){
                                    var nameSplit = item.name.split(' ')
                                    if(nameSplit[0] === undefined){
                                        firstname = ""
                                    }
                                    else{
                                        firstname = nameSplit[0]
                                    }
                                    if(nameSplit[1] === undefined){
                                        lastname = ""
                                    }
                                    else{
                                        lastname = nameSplit[1]
                                    }
                                    customers.push({ 'email': item.email, 'phone': item.phone_number, 'first_name': firstname, 'last_name': lastname, 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                                })
                                if(type === "trainer"){
                                    setCustomers(customers)
                                }
                                sendInvite(customers, profileId)
                                setMsg('')
                                setEmailValue('')
                                setFirstName('')
                                setLastName('')
                            }
                            else{
                                if(type === "trainer"){
                                    setCustomers(customers)
                                }
                                sendInvite(customers, profileId)
                                setMsg('')
                                setEmailValue('')
                                setFirstName('')
                                setLastName('')
                            }
                        }
                    }
                    else if(newArray.length === 1 && newData.length === 0){
                        setMsg('')
                        _.forEach(newArray, function(item){
                            customers.push({ 'email': item, 'phone': '', 'first_name': firstName, 'last_name': lastName, 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                        })
                        if(type === "trainer"){
                            setCustomers(customers)
                        }
                        sendInvite(customers, profileId)
                        setEmailValue('')
                        setFirstName('')
                        setLastName('')
                    }
                    else{
                        if(newArray.length <=1 && newData.length === 0){
                            setMsg('Invite can be send to one user only at a time')
                        }
                        else{
                            setMsg('Invite can be send to two users only at a time')
                        }
                    }
                }
            }
            else{
                let loginEmail = localStorage.getItem('email')
                const filterArray = _.filter(newArray, function(o){
                    return _.trim(_.toLower(o)) === loginEmail
                })
                if(filterArray.length !== 0){
                    setValidatemsg('You cannot assign an assessment to yourself. If you wish to take an assessment, click start assessment in the Profile Library section of your account dashboard')
                }
                else{
                    setMsg('Please enter a valid email address')
                    setValidatemsg('')
                }
            }
        }
    }

    const validateEmail = (value) => {
        let loginEmail = localStorage.getItem('email')
        if(_.toLower(value) === loginEmail){
            setValidatemsg('You cannot assign an assessment to yourself. If you wish to take an assessment, click start assessment in the Profile Library section of your account dashboard')
            return true
        }
        else{
            setValidatemsg('') 
            return false
        }
    }

    const renderEmail = (event) => {
        const { value } = event.target
        setEmailValue(value)
        validateEmail(value)
        setCheckEmailValue(0)
        let checkEmail
        if(checkEmailValue === 0){
            checkEmail = validate(value)
        }
        if(checkEmail){
            setCheckEmailValue(1)
            setMsg('')
        }
        else{
            if(checkEmailValue === 0){
                setMsg('Please enter a valid email address')
            }
        }
    }

    const renderFirstName = (event) => {
        const { value } = event.target
        setFirstName(value)
        if(!_.isEmpty(value)){
            setMsg('')
        }
    }

    const renderLastName = (event) => {
        const { value } = event.target
        setLastName(value)
        if(!_.isEmpty(value)){
            setMsg('')
        }
    }

    const renderExistingUsers = () => {
        let newList = []
        _.forEach(usersList, function(user){
            _.forEach(assigneeList, function(list){
                if(list.id === user.id){
                    newList.push({ 'user_details': user, 'invitation_list': list })
                }
            })
        })
        if(_.isEmpty(newList)){
            return <p>Once you invite user then invited user list will be shown here</p>
        }
        return(
            newList.map((list, index) => {
                let obj = {}
                obj = _.find(list.invitation_list.invited_plan, {'profile_id': profileId, 'invited_by': uid})
                return(
                    <div className="card mb-2" key={index}>
                        <div className="card-body p-0">
                            <div className="media">
                                <div className="media-body row m-0 content-body">
                                    <a href="#" className="d-block col-9 nameText text-secondary">{_.isEmpty(_.trim(list.user_details.name)) ? '--' : list.user_details.name}</a> 
                                    <a href="#" className="d-block col-9 emailText text-secondary">{list.user_details.email}</a> 
                                    {obj === undefined ? 
                                    <div className="text-right checkbox-input lead col-3"> 
                                        <input type="checkbox" id={list.user_details.id} className="form-check-input largerCheckbox" name={list.user_details.name} onChange={(event) => handleChange(event, list.user_details, currentProfile)} />
                                    </div>
                                    : 
                                    <div className="text-right checkbox-input lead col-3">
                                        <a href="javascript:void(0);" className="text-success d-block checkbox-text">
                                            <i className="mdi mdi-checkbox-marked-circle"></i>
                                        </a>
                                        <label>Already Invited</label>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }

    if(InviteMsg !== '' && type === "trainer" && Update && !loading){
        updateQuantity(profileId, orderId, customerArray)
        setUpdate(false)
    }

    if(inviteMsg !== '' && show && !loading){
        setInviteMsg(inviteMsg)
        setShow(false)
    }

    if(error !== '' && show){
        setErrorMsg(error)
        setShow(false)
    }

    return(
        <div className="modal fade" data-backdrop="static" data-keyboard="false" id="invite" tabIndex="-1" aria-labelledby="inviteTitle"  aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <div className="text-center profile-name"> 
                            {/* <span className="mr-3"> 
                                <img src={currentProfile !==  undefined ? currentProfile.profile_image : ""} style={{ width: '36px', height: '50px' }} alt="" />
                            </span>  */}
                            <h3 className="text-center">    
                                {currentProfile !== undefined ? currentProfile.profile_name : null}
                            </h3>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    {validatemsg !== '' ? 
                     <div className="errorMessage">
                        <h5 className="errorMsg"> {validatemsg} </h5>
                    </div> 
                    : null}
                    {msg !== '' ? 
                    <div>
                        <div className="errorMessage">
                            <h5 className="errorMsg"> {msg} </h5>
                        </div> 
                        <div className="errorNote">
                            <label className="errorMsg note-msg"> 
                                {/* Note:- *Use comma ( , ) for multiple user
                                *You can assign only two user at once. */}
                                Note:- First Name and Email are mandatory
                            </label>
                        </div>
                    </div> 
                    : null}
                    {InviteMsg !== '' ? 
                        <div className="successMessage">
                            <h5 className="successMsg"> {InviteMsg} </h5> 
                        </div>
                    : null}
                    {/* {profileErrorMsg !== '' ? <h5 className="errorMsg text-center"> {profileErrorMsg} </h5> : null} */}
                    {ErrorMsg !== '' ? <h5 className="errorMsg text-center"> {ErrorMsg} </h5> : null}
                    <div className="modal-body pt-0 user-content">
                        <div className="footerBorder justify-content-start">
                            <form className="form-inline input-box">
                                <div className="form-group">
                                    <small className="text-muted new-user"> Invite new Users</small>
                                    <div className="name-container">
                                        <input type="text" className="form-control form-control-sm first-name" placeholder="Enter First Name" value={firstName} onChange={renderFirstName} />
                                        <input type="text" className="form-control form-control-sm last-name" placeholder="Enter Last Name" value={lastName} onChange={renderLastName} />
                                    </div>
                                    <input type="text"  className="form-control form-control-sm email-container" placeholder="email@example.com" value={emailValue} onChange={renderEmail} />
                                    <button type="submit" className="btn btn-primary form-control-sm invite-btn" disabled={msg !== '' || validatemsg !== '' ? 'disabled' : null} onClick={type === "trainer" ? renderSendInviteTrainer : renderSendInvite}>Invite</button>
                                </div>
                            </form>
                        </div>
                        <div className="invite-seprator"></div>
                        {loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : null}
                        <div className="existing-user">
                            <small className="text-muted existing-user-label"> Invite Existing Users</small>
                        </div>
                        {renderExistingUsers()}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    usersList: state.profileReducer.usersList,
    currentProfile: state.profileReducer.currentProfile,
    inviteMsg: state.profileReducer.inviteMsg,
    assigneeList: state.profileReducer.assigneeList,
    profileErrorMsg: state.profileReducer.profileErrorMsg
})

const mapDispatchToProps = {
    updateQuantity,
    sendInvite,
    getUserProfile,
    removeProfileErrorMsg
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteContainer) 