import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { convertDate, formatMoney } from '../date/convertDate'

const AdminDashboardContent = (props) => {
	const { userList } = props
    const renderContent = () => {
        return(
            <div className="row">
				<div className="col-md-12 stretch-card">
					<div className="card">
						<div className="card-body row">
							<div className="col-md-8">
								<h4 className="card-title pt-2">Recent Purchase Summary</h4>
                            </div>
							{getTable()}
						</div>
					</div>
				</div>
			</div>
        )
    }

    const getTable = () => {
        return(
            <div className="table-responsive">
				<table id="recent-purchases-listing" className="table table-bordered">
					<thead>
						<tr>
						    <th>S.No</th>
							<th>Order Id</th>
							<th>Name</th>
							<th>Line Items</th>
							<th>Amount</th>
							<th>Date</th>
						</tr>
					</thead>
                    {getTableBody()}
                </table>
			</div>
        )
    }

    const getTableBody = () => {
		let orderSummary =  [], output
		if(userList !== undefined){
			if(userList.length !== 0){
				_.forEach(userList, function(list){
					if(list.purchase_plan !== undefined){
						output = list.purchase_plan.reduce(function(o, cur) {
							// Get the index of the key-value pair.
							var occurs = o.reduce(function(n, item, i) {
							  return (item.order_id === cur.order_id) ? i : n;
							}, -1)
							// If the name is found,
							if (occurs >= 0) {
							  // append the current value to its list of values.
							  o[occurs].amount = o[occurs].amount.concat(cur.amount);
							  o[occurs].profile_id = o[occurs].profile_id.concat(cur.profile_id)
							  // Otherwise,
							} else {
							  // add the current item to o (but make sure the value is an array).
							  var obj = {
								order_id: cur.order_id,
								name: list.name,
								email: list.email,
								amount: [cur.amount],
								profile_id: [cur.profile_id],
								purchase_date: cur.purchase_date
							  };
							  o = o.concat([obj])
							}
							return o;
						}, [])
						output.map(list => {
							return orderSummary.push(list)
						})
						_.forEach(output, function(item){
							item['line_items'] = item.amount.length
						})
					}
				})
			}
		}
		let sortedArray = _.sortBy(orderSummary, ['purchase_date'])
		_.reverse(sortedArray)
		if(orderSummary.length === 0){
			return <tbody><tr><td>No Order Summary Available</td></tr></tbody>
		}
        return(
            <tbody>
				{sortedArray.map((summary, index) => {
					let purchasedDate = convertDate(summary.purchase_date)
					const amount = _.sum(summary.amount)
					const total = formatMoney(amount)
					if(index < 5){
						return(
							<tr key={index}>
								<td>{index +1}</td>
								<td>{summary.order_id}</td>
								<td>{!_.isEmpty(_.trim(summary.name)) ? summary.name : summary.email}</td>
								<td>{summary.line_items}</td>
								<td>&#163;{total}</td>
								<td>{purchasedDate}</td>
							</tr>
						)
					}
				})}
		    </tbody>
        )
    }

    return renderContent()
}

const mapStateToProps = (state) => ({
	userList: state.profileReducer.userList
})

export default connect(mapStateToProps)(AdminDashboardContent)