import React, { useState } from 'react'
import { CountryDropdown } from 'react-country-region-selector'
import { useEffect } from 'react'
import _ from 'lodash'

const CountryDropdownContainer = (props) => {
    const { input } = props 
    const [country, setCountry] = useState("India")

    useEffect(() => {
        if(input.value !== ""){
            setCountry(input.value)
        }
    })

    const handleChange = (value) => {
        setCountry(value)
        input.onChange(value)
    }

    return(
        <CountryDropdown value={country} onChange={handleChange} />
    )
}

export default CountryDropdownContainer