export const GET_PROFILES_REQUEST = 'GET_PROFILES_REQUEST'
export const GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS'
export const GET_PROFILES_ERROR = 'GET_PROFILES_ERROR'

export const GET_CURRENT_PROFILE_REQUEST = 'GET_CURRENT_PROFILE_REQUEST'
export const GET_CURRENT_PROFILE_SUCCESS = 'GET_CURRENT_PROFILE_SUCCESS'
export const GET_CURRENT_PROFILE_ERROR = 'GET_CURRENT_PROFILE_ERROR'

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'

export const GET_CURRENT_QUESTIONS_REQUEST = 'GET_CURRENT_QUESTIONS_REQUEST'
export const GET_CURRENT_QUESTIONS_SUCCESS = 'GET_CURRENT_QUESTIONS_SUCCESS'
export const GET_CURRENT_QUESTIONS_ERROR = 'GET_CURRENT_QUESTIONS_ERROR'

export const SEND_INVITE_REQUEST = 'SEND_INVITE_REQUEST'
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS'
export const SEND_INVITE_ERROR = 'SEND_INVITE_ERROR'

export const UPDATE_INVITE_STATUS_REQUEST = 'UPDATE_INVITE_STATUS_REQUEST'
export const UPDATE_INVITE_STATUS_SUCCESS = 'UPDATE_INVITE_STATUS_SUCCESS'
export const UPDATE_INVITE_STATUS_ERROR = 'UPDATE_INVITE_STATUS_ERROR'

export const UPDATE_QUANTITY_REQUEST = 'UPDATE_QUANTITY_REQUEST'
export const UPDATE_QUANTITY_SUCCESS = 'UPDATE_QUANTITY_SUCCESS'
export const UPDATE_QUANTITY_ERROR = 'UPDATE_QUANTITY_ERROR'

export const GET_ASSIGNEE_LIST_REQUEST = 'GET_ASSIGNEE_LIST_REQUEST'
export const GET_ASSIGNEE_LIST_SUCCESS = 'GET_ASSIGNEE_LIST_SUCCESS'
export const GET_ASSIGNEE_LIST_ERROR = 'GET_ASSIGNEE_LIST_ERROR'

export const GET_PROFILE_INVITATION_REQUEST = 'GET_PROFILE_INVITATION_REQUEST'
export const GET_PROFILE_INVITATION_SUCCESS = 'GET_PROFILE_INVITATION_SUCCESS'
export const GET_PROFILE_INVITATION_ERROR = 'GET_PROFILE_INVITATION_ERROR'

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR'

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR'

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR'

export const ADD_TEST_RESULT_REQUEST = 'ADD_TEST_RESULT_REQUEST'
export const ADD_TEST_RESULT_SUCCESS = 'ADD_TEST_RESULT_SUCCESS'
export const ADD_TEST_RESULT_ERROR = 'ADD_TEST_RESULT_ERROR'

export const GET_USER_RESULT_REQUEST = 'GET_USER_RESULT_REQUEST'
export const GET_USER_RESULT_SUCCESS = 'GET_USER_RESULT_SUCCESS'
export const GET_USER_RESULT_ERROR = 'GET_USER_RESULT_ERROR'

export const GET_CURRENT_RESULT_REQUEST = 'GET_CURRENT_RESULT_REQUEST'
export const GET_CURRENT_RESULT_SUCCESS = 'GET_CURRENT_RESULT_SUCCESS'
export const GET_CURRENT_RESULT_ERROR = 'GET_CURRENT_RESULT_ERROR'

export const GET_GENERAL_NOTES_REQUEST = 'GET_GENERAL_NOTES_REQUEST'
export const GET_GENERAL_NOTES_SUCCESS = 'GET_GENERAL_NOTES_SUCCESS'
export const GET_GENERAL_NOTES_ERROR = 'GET_GENERAL_NOTES_ERROR'

export const GET_GUIDELINES_REQUEST = 'GET_GUIDELINES_REQUEST'
export const GET_GUIDELINES_SUCCESS = 'GET_GUIDELINES_SUCCESS'
export const GET_GUIDELINES_ERROR = 'GET_GUIDELINES_ERROR'

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST'
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR'

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR'

export const GET_INSTRUCTIONS_REQUEST = 'GET_INSTRUCTIONS_REQUEST'
export const GET_INSTRUCTIONS_SUCCESS = 'GET_INSTRUCTIONS_SUCCESS'
export const GET_INSTRUCTIONS_ERROR = 'GET_INSTRUCTIONS_ERROR'

export const CANCEL_INVITE_REQUEST = 'CANCEL_INVITE_REQUEST'
export const CANCEL_INVITE_SUCCESS = 'CANCEL_INVITE_SUCCESS'
export const CANCEL_INVITE_ERROR = 'CANCEL_INVITE_ERROR'

export const GET_CANCEL_INVITATION_LIST = 'GET_CANCEL_INVITATION_LIST'
export const GET_CANCEL_INVITATION_SUCCESS = 'GET_CANCEL_INVITATION_SUCCESS'
export const GET_CANCEL_INVITATION_ERROR = 'GET_CANCEL_INVITATION_ERROR'

export const HIDE_CANCELLED_MSG = 'HIDE_CANCELLED_MSG'

export const GET_RESULT_BY_PROFILE_REQUEST = 'GET_RESULT_BY_PROFILE_REQUEST'
export const GET_RESULT_BY_PROFILE_SUCCESS = 'GET_RESULT_BY_PROFILE_SUCCESS'
export const GET_RESULT_BY_PROFILE_ERROR = 'GET_RESULT_BY_PROFILE_ERROR'

export const UPDATE_ASSESSMENT_VALUE_REQUEST = 'UPDATE_ASSESSMENT_VALUE_REQUEST'
export const UPDATE_ASSESSMENT_VALUE_SUCCESS = 'UPDATE_ASSESSMENT_VALUE_SUCCESS'
export const UPDATE_ASSESSMENT_VALUE_ERROR = 'UPDATE_ASSESSMENT_VALUE_ERROR'

export const REMOVE_PROFILE_ERROR_MSG = 'REMOVE_PROFILE_ERROR_MSG'