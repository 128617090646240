import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Footer from '../../footer/Footer'
import { authHeader, history } from '../../../_helpers'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import UserDashboardHeader from './UserDashboardHeader'
import './Dashboard.scss'
import _ from 'lodash'
import ReactLoading from "react-loading"
import { getUserProfile, getAssigneeList, getProfileInvitation, getProfiles } from '../../../actions/ProfileActions'
import UserPurchasedProfileGraph from './UserPurchasedProfileGraph'
import UserAssigneeGraph from './UserAssigneeGraph'
import { renderMonthDiff } from '../date/convertDate'
import UserDetailsTable from '../profile/UserDetailsTable'
import { getMaxValue } from '../library/CommonMethods'

const TraineeDashboard = (props) => {
	let pathname = props.location.pathname
	const { loading, error, getUserProfile, getAssigneeList, getProfileInvitation, currentUser, getProfiles, Profilelist, assigneeList } = props
	let uid = localStorage.getItem('uid')

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
				authHeader(pathname)
				getUserProfile(uid)
				getAssigneeList()
				getProfileInvitation(uid)
				getProfiles()
            }
            else{
                history.push('/login') 
            }
        }
	},[pathname, getUserProfile, uid, getAssigneeList, getProfiles, getProfileInvitation])
	
	const renderGraph = () => {
		var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
		let purchasedProfile = [], array = [], totalAssignee = [], totalAssigneeValue = [], purchasedProfileValue = []
		var currentDate = new Date()
		var oldDate = new Date()
		oldDate.setDate(1)
		oldDate.setMonth(oldDate.getMonth() + 1)
		oldDate.setFullYear(oldDate.getFullYear()-1)
		const diff = renderMonthDiff(oldDate, currentDate)
		let newAssignee = 0
		if(!_.isEmpty(assigneeList)){
			_.forEach(assigneeList, function(list){
				_.forEach(list.invited_plan, function(plan){
					var date = new Date(plan.invited_date.seconds * 1000)
					const monthName = date.getMonth()
					var month =  monthNames[monthName].toString().substr(0, 3)
					array.push({ 'month': month + " " + date.getFullYear().toString().substr(-2) })
				})
			})
		}
		_.forEach(diff, function(month){
			const count = array.filter((obj) => obj.month === month).length
			if(count >0){
				newAssignee = 1
			}
			totalAssignee.push([month, count])
			totalAssigneeValue.push({ 'label': month, 'y': count })
		})
		const maximumValue = getMaxValue(totalAssigneeValue)
		if(!_.isEmpty(currentUser)){
			_.forEach(currentUser.purchase_plan, function(plan){
				_.forEach(Profilelist, function(profile){
					if(profile.id === plan.profile_id){
						let wordArray= _.words(profile.profile_name)
						let ProfileName = ""
						if(wordArray.length > 1){
							_.forEach(wordArray, function(value){
								ProfileName += value[0]
							})
						}
						else{
							ProfileName = wordArray[0]
						}
						purchasedProfile.push([ProfileName, plan.quantity])
						purchasedProfileValue.push({ 'label':ProfileName, 'y': plan.quantity })
					}
				})
			})
		}
		const maximumPurchasedValue = getMaxValue(purchasedProfileValue)
		return(
			<div className="row graphContainer mb-4">
				<div className="col-sm-6">
					<div className="graph">
						<UserPurchasedProfileGraph purchasedProfile={purchasedProfile} maximumPurchasedValue={maximumPurchasedValue !== undefined ? maximumPurchasedValue.y : null} />									
					</div>
				</div>
				{newAssignee === 1 ? 
				<div className="col-md-6">
					<div className="graph">
						<UserAssigneeGraph totalAssignee={totalAssignee} maximumValue={maximumValue}  />
					</div>
				</div>
				: <div className="col-md-6 stretch-card">
					<div className="card">
						<div className="card-body row newRegister">
							<div className="col-md-12 text-center mt-3">
								<p>No Assignee has been assigned for Last 12 Months</p>
							</div>
						</div>
					</div>
				</div>}
				{/* <div className="col-sm-6">
					<div className="graph">
						<div className="card-body row totalAssignee"> 
							{newAssignee === 1 ?  <UserAssigneeGraph totalAssignee={totalAssignee} maximumValue={maximumValue}  />: <div className="col-md-12 text-center mt-3"><p>No Assignee has been assigned for Last 12 Months</p></div>}
							{newAssignee === 1 ? <div className="col-md-12 text-center mt-3">
								<h4>Total Assignee for last 12 months</h4>
							</div> : null}
						</div>
					</div>
				</div> */}
			</div>
		)
	}

    return(
        <div className="main-panel">
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Dashboard" subTitle="Assignee" />
				{error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
				<UserDashboardHeader />
				{renderGraph()}
				{loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : null}
				<div className="row">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            {currentUser !== null ? <UserDetailsTable currentUser={currentUser} showAction={false} /> : null}
                        </div>
                    </div>
                </div> 
			</div>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
	loading: state.profileReducer.loading,
	error: state.profileReducer.error,
	currentUser: state.profileReducer.currentUser,
	Profilelist: state.profileReducer.Profilelist,
	assigneeList: state.profileReducer.assigneeList
})

const mapDispatchToProps = {
	getUserProfile,
	getAssigneeList,
	getProfileInvitation,
	getProfiles
}

export default connect(mapStateToProps, mapDispatchToProps)(TraineeDashboard)