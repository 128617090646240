import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { forgotPassword } from '../../actions/AuthActions'
import ReactLoading from "react-loading"
import { authHeader } from '../../_helpers'
import { validate } from '../pages/validate/EmailValidate'
import $ from 'jquery'

const ForgotPassword = (props) => {
    const { forgotPassword, loggingIn, passwordError } = props
    let pathname = props.location.pathname
    const [data, setData] = useState("")
    const [emailError, setEmailError] = useState("")
    
    useEffect(() => {
        //checkbox and radios
        $(".form-check label,.form-radio label").append('<i class="input-helper"></i>')
        if(authHeader){
            authHeader(pathname)
        }
    })

    const handleChange = (event) => {
        const target = event.target
        const { name, value } = target
        setData({ ...data, [name]: value })
    }

    const validation = () => {
        let checkEmail
        const { email } = data
        if(email === "" || email === undefined){
            setEmailError('Username is Required')
        }
        else{
            checkEmail = validate(email)
            if(checkEmail){
                forgotPassword(data)
                reset()
            }
            else{
                setEmailError('Please enter a valid email address')
            }
        }
    }

    const reset = () => {
        setEmailError('')
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        validation()
    }

    return(
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                    <div className="row flex-grow">
                        <div className="col-lg-6 d-flex align-items-center justify-content-center">
                            <div className="auth-form-transparent text-left p-3">
                                <div className="brand-logo">
                                    <img src="../../images/logo.png" alt="logo" />
                                </div>
                                <h6 className="font-weight-light">Please enter your email</h6>
                                <form className="pt-3">
                                    <div className="form-group">
                                        {passwordError !== "" ? <h5 className="errorMsg">{passwordError}</h5> : null}
                                        <label htmlFor="exampleInputEmail">Username</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend bg-transparent">
                                                <span className="input-group-text bg-transparent border-right-0">
                                                    <i className="mdi mdi-account-outline text-primary"></i>
                                                </span>
                                            </div>
                                            <input type="text" className="form-control form-control-lg border-left-0" id="exampleInputEmail" placeholder="Username" name="email" onChange={handleChange} />
                                        </div>
                                        {emailError && <p className="errorMsg"> {emailError}</p>}
                                    </div>
                                    <div className="my-2 d-flex justify-content-between align-items-center">
                                        <div className="form-check"></div>
                                        <a href="/login" className="auth-link text-black">Back</a>
                                    </div>
                                    <div className="my-3">
                                        <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={handleSubmit}>SUBMIT</button>
                                    </div>
                                </form>
                            </div>
                            {loggingIn ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}  
                        </div>
                        <div className="col-lg-6 login-half-bg d-flex flex-row">
                            <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright © 2020 LPT Productions. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loggingIn: state.authReducer.loggingIn,
    passwordError: state.authReducer.passwordError
})

const mapDispatchToProps = {
    forgotPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)