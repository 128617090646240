import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { formatMoney } from '../date/convertDate'

const UserDashboardHeader = (props) => {
    const { assigneeList, currentUser, invitationList } = props
    let totalAmount = [], invitationLength
    
    if(!_.isEmpty(currentUser)){
        _.forEach(currentUser.purchase_plan, function(plan){
            totalAmount.push(plan.amount)
        })
    }

    if(invitationList !== undefined){
        if(invitationList.length !== 0){
            invitationLength = invitationList.length
        }
    }

    const total = _.sum(totalAmount)
    const amount = formatMoney(total)

    return(
        <div className="row mb-4 dashboardLt">
            <div className="col-xl-3 col-md-6">
                <div className="card widget-box-two bg-white">
                    <div className="card-body total">
                        <div className="media">
                            <div className="media-body wigdet-two-content">
                                <p className="m-0 text-uppercase" title="Total Orders">Profiles Purchased</p>
                                <h2 className="">{!_.isEmpty(currentUser) ? currentUser.purchase_plan !== undefined ? currentUser.purchase_plan.length : 0 : 0}</h2>                                                 
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card widget-box-two bg-white">
                    <div className="card-body totalRevenue">
                        <div className="media">
                            <div className="media-body wigdet-two-content">
                                <p className="m-0 text-uppercase" title="Total Orders">Assigned Profiles</p>
                                <h2 className="">{invitationLength !== undefined ? invitationLength : 0}</h2>                                                 
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card widget-box-two bg-white">
                    <div className="card-body pendingOrders">
                        <div className="media">
                            <div className="media-body wigdet-two-content">
                                <p className="m-0 text-uppercase" title="Total Orders">Total Assignee</p>
                                <h2 className="">{assigneeList !== undefined ? assigneeList.length : 0}</h2>                                                 
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card widget-box-two bg-white">
                    <div className="card-body totalCustomers">
                        <div className="media">
                            <div className="media-body wigdet-two-content">
                                <p className="m-0 text-uppercase">Total Revenue</p>
                                <h2 className="">&#163;{amount}</h2>                                                 
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    assigneeList: state.profileReducer.assigneeList,
    currentUser: state.profileReducer.currentUser,
    invitationList: state.profileReducer.invitationList
})

export default connect(mapStateToProps)(UserDashboardHeader)