import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import { getUserProfile, updateUserProfile } from '../../../actions/ProfileActions'
import ReactLoading from "react-loading"
import FormContainer from './FormContainer'
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'

const EditProfile = (props) => {
    let pathname = props.location.pathname
    const { getUserProfile, loading, error, updateUserProfile } = props
    let uid = localStorage.getItem('uid')

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getUserProfile(uid)
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, uid, getUserProfile])

    const handleSubmit = (data) => {
        const { name } = data
        data.modified_date_time = new Date()
        updateUserProfile(data)
        localStorage.setItem('user_name', name)
    }

    return(
        <div className="main-panel">
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Profile" subTitle="Edit Profile" />
                {error !== "" ? <h3 className="errorMsg text-center"> {error} </h3> : null}
                {loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : null} 
                <FormContainer onSubmit={handleSubmit} />
            </div>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error
})

const mapDispatchToProps = {
    getUserProfile,
    updateUserProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)