import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { loginUser } from '../../actions/AuthActions'
import ReactLoading from "react-loading"
import { authHeader } from '../../_helpers'
import $ from 'jquery'

const Login = (props) => {
    const { loginUser, loggingIn, loginerror, successMsg } = props
    let pathname = props.location.pathname
    let showMsg = props.location.showMsg
    const [data, setData] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [msg, setMsg] = useState('')
    const [showSuccessMsg, setShowSuccessMsg] = useState(true)
    
    useEffect(() => {
        //checkbox and radios
        $(".form-check label,.form-radio label").append('<i class="input-helper"></i>')
        if(authHeader){
            authHeader(pathname)
        }
        const timer = setTimeout(() => {
            setMsg('')
		}, 5000);
		return () => clearTimeout(timer)
    })

    const handleChange = (event) => {
        const target = event.target
        const { name, value } = target
        setData({ ...data, [name]: value })
    }

    const validation = () => {
        const { email, password } = data
        if(email === ""){
            setEmailError('Username is Required')
        }
        else if(password === ""){
            setPasswordError('Password is Required')
        }
        else{
            loginUser(data)
            reset()
        }
    }

    const reset = () => {
        setEmailError('')
        setPasswordError('')
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        validation()
    }

    if(successMsg !== '' && showSuccessMsg){
		setMsg(successMsg)
		setShowSuccessMsg(false)
	}

    return(
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                    <div className="row flex-grow">
                        <div className="col-lg-6 d-flex align-items-center justify-content-center">
                            <div className="auth-form-transparent text-left p-3">
                                <div className="brand-logo">
                                    <img src="../../images/logo.png" alt="logo" />
                                </div>
                                <h4 className="login-heading">Welcome to LPT Productions Online</h4>
                                <h6 className="font-weight-light">Please log-in below</h6>
                                <form className="pt-3">
                                    <div className="form-group">
                                        {msg !== "" ? <h5 className="successMsg">{msg}</h5> : null}
                                        {showMsg ? <h5 className="successMsg">Password Changed!! Please login with a new password</h5> : null}
                                        {loginerror !== "" ? <h5 className="errorMsg">{loginerror}</h5> : null}
                                        <label htmlFor="exampleInputEmail">Username</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend bg-transparent">
                                            <span className="input-group-text bg-transparent border-right-0">
                                                <i className="mdi mdi-account-outline text-primary"></i>
                                            </span>
                                            </div>
                                            <input type="text" className="form-control form-control-lg border-left-0" id="exampleInputEmail" placeholder="Username" name="email" onChange={handleChange} />
                                        </div>
                                        {emailError && <p className="errorMsg"> {emailError}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword">Password</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend bg-transparent">
                                            <span className="input-group-text bg-transparent border-right-0">
                                                <i className="mdi mdi-lock-outline text-primary"></i>
                                            </span>
                                            </div>
                                            <input type="password" className="form-control form-control-lg border-left-0" id="exampleInputPassword" placeholder="Password" name="password" onChange={handleChange} />                        
                                        </div>
                                        {passwordError && <p className="errorMsg"> {passwordError}</p>}
                                    </div>
                                    <div className="my-2 d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                        {/* <label className="form-check-label text-muted">
                                        <input type="checkbox" className="form-check-input" />
                                            Keep me signed in
                                        </label> */}
                                    </div>
                                    <a href="/forgotpassword" className="auth-link text-black">Forgot password?</a>
                                </div>
                                <div className="my-3">
                                    <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={handleSubmit}>LOGIN</button>
                                </div>
                            </form>
                        </div>
                        {loggingIn ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}  
                    </div>
                    <div className="col-lg-6 login-half-bg textLayer row">
                        {/* <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright © 2020 LPT Productions. All rights reserved.</p> */}
                        <div className="welcome-login">
                            <h1>Welcome to</h1>
                            <h2>LPT Leadership</h2>
                            <h3>Task <span> | </span> Team <span> | </span> Individual</h3>
                        </div>
                        <div className="logincopyright"> <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright © 2020 LPT Productions. All rights reserved.</p> </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loggingIn: state.authReducer.loggingIn,
    loginerror: state.authReducer.loginerror,
    successMsg: state.authReducer.successMsg
})

const mapDispatchToProps = {
    loginUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)