import React, { useState } from 'react'
import { connect } from 'react-redux'
import { sendNotificationData } from '../../actions/NotificationActions'

const Notification = (props) => {
    const { sendNotificationData } = props
    const [notification, setNotification] = useState({
        title: "",
        body: ""
    })

    const handleChange = (event) => {
        const target = event.target
        const {name, value} = target
        setNotification({
        ...notification,
        [name]: value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        sendNotificationData(notification)
        setNotification({ title: "", body: "" })
    }

    return(
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title">Notifications</h4>
                        <form className="forms-sample" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="notification_title">Title</label>
                                <input type="text" className="form-control" id="notification_title" value={notification.title} name="title" placeholder="Title" onChange={(event) => handleChange(event)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="notification_body">Body</label>
                                <textarea className="form-control" id="notification_body" value={notification.body} name="body" rows="4"  onChange={(event) => handleChange(event)}></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2">Submit</button>
                            <button className="btn btn-light">Cancel</button>
                        </form>
                        </div>
                    </div>
                    </div>  
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    sendNotificationData
}

export default connect(null, mapDispatchToProps)(Notification)