import { useEffect, useRef } from 'react'
import _ from 'lodash'

export const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value;
    })
    return ref.current
}

export const getSeriesData = (resultArray) => {
  let array = []
  _.forEach(resultArray, function(value){
    if(value.label === "Task"){
      array.push({
        color:'rgba(90,155,212,.5)',
        name:value.label,
        data: [{
					x:66,
					y:158,
					marker:{
						radius:value.y,
						fillColor:'rgba(90,155,212,.5)',
						lineColor:'rgba(90,155,212,.75)',
						lineWidth:1,
						states:{
							hover:{
								radius:value.y
							} 
						}
					}
        }],
        tooltip: {
          headerFormat: '<b>{value.label}</b><br>',
          pointFormat: value.label + ': ' + value.y
        }
      })
    }
    if(value.label === "Individual"){
      array.push({
        color:'rgba(250,250,91,.5)',
        name:value.label,
        data: [{
					x:81,
					y:110,
					marker:{
						radius:value.y,
						fillColor:'rgba(250,250,91,.5)',
            lineColor:'rgba(250,250,91,.75)',
						lineWidth:1,
						states:{
							hover:{
								radius:value.y
							}
						}
					}
        }],
        tooltip: {
          headerFormat: '<b>{value.label}</b><br>',
          pointFormat: value.label + ': ' + value.y
        }
      })
    }
    if(value.label === "Team"){
      array.push({
        color:'rgba(241,90,96,.5)',
        name:value.label,
        data: [{
					x:50,
					y:110,
					marker:{
						radius:value.y,
						fillColor:'rgba(241,90,96,.5)',
            lineColor:'rgba(241,90,96,.75)',
						lineWidth:1,
						states:{
							hover:{
								radius:value.y
							}
						}
					}
        }],
        tooltip: {
          headerFormat: '<b>{value.label}</b><br>',
          pointFormat: value.label + ': ' + value.y
        }
      })
    }
    if(value.label === "non-management"){
      array.push({
        color:'rgba(139,137,137,.5)',
        name:value.label,
        data: [{
					x:200,
					y:150,
					marker:{
						radius:value.y,
						fillColor:'rgba(139,137,137,.5)',
            lineColor:'rgba(139,137,137,.75)',
						lineWidth:1,
						states:{
							hover:{
								radius:value.y
							}
						}
					}
        }],
        tooltip: {
          headerFormat: '<b>{value.label}</b><br>',
          pointFormat: value.label + ': ' + value.y
        }
      })
    }
  })
  return array
}

export const getMaxValue = (values) => {
  let firstValue = values[0]
  _.forEach(values, function(item){
    if(item.y > firstValue.y){
      firstValue = item.y
    }
  })
  return firstValue
}