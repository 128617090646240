import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class VennGraph extends Component {
	render() {
		const { title, seriesData } = this.props
		const options = {
            // type: "scatter",
			title:{
                text:title,
                style: {
                    color: '#4a4a4a',
					// font: '32px "Dejavu Sans"'
					textTransform: 'uppercase'
                }
			},
			credits:{enabled:false},
			legend:{},
			plotOptions: {
				series: {
					shadow:false,
					borderWidth:0,
					dataLabels: {
						enabled: false
					},
					marker:{
						symbol:'circle'
					},
					states: {
						inactive: {
							enabled: false
						}
					}                            
				}
			},
			xAxis:{
				min:0,
				max:270,
				lineWidth:0,
				gridLineWidth:0,
				title:{text:''},
				labels:{enabled:false}
			},
			yAxis:{
				min:0,
				max:270,
				lineWidth:0,
				gridLineWidth:0,
				title:{text:''},
				labels:{enabled:false}
			},    
			series: seriesData
		}
		return (
			<HighchartsReact highcharts={Highcharts} options={options} />
		)
	}
}

export default VennGraph