import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import CountryDropdownContainer from '../library/CountryDropdownContainer'
import Label from '../library/Label'

const validate = (values) => {
    const errors = {errorName:{}};
    var phoneno = /^\d{10}$/;
    if(values.phone_number) {
        if(!values.phone_number.match(phoneno)){
            errors.phone_number = {
                message: 'Phone Number is invalid'
            }
        }
    }
    if(values.name){
        if(!/^[a-zA-Z\s]*$/g.test(values.name)){
            errors.name = {
                message: 'Name is invalid'
            }
        }
    }
    return errors
}

class FormContainer extends Component{
    renderField({ input, label, type, placeholder, meta: { touched, error }, disabled }) {
        return (
            <div className="form-group">
                <label>{label} </label>
                <input {...input} className="form-control" placeholder={placeholder} type={type} disabled={disabled} />
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </div>
        )
    }

    render(){
        const { handleSubmit, disabled, reset } = this.props
        return(
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title pt-2">Edit Profile form</h4>
                            <form onSubmit={handleSubmit}>
                                <Field name="name" component={this.renderField} type="text" label="Name" placeholder="Enter Name" />
                                <Field name="email" component={this.renderField} type="text" label="Email Address" disabled={disabled} placeholder="Enter Email" />
                                <Field name="phone_number" component={this.renderField} type="text" label="Phone Number" placeholder="Enter Phone Number" />
                                <Label>Country</Label>
                                <div className="select-country">
                                    <Field name="country" component={CountryDropdownContainer} type="text" label="Country" placeholder="Enter Country" />
                                </div>
                                <div className="edit-profilebtn"> 
                                    <button type="submit" className="btn btn-primary mr-2">Submit</button>
                                    <button className="btn btn-secondary" onClick={reset}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    disabled: state.profileReducer.disabled,
    initialValues: state.profileReducer.currentUser
})

export default connect(mapStateToProps)(reduxForm({form: 'simple', validate, enableReinitialize: true })(FormContainer))