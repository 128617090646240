import { put, takeLatest, call } from 'redux-saga/effects'
import "firebase/auth"
import firebase from '../components/firebase'
import { db } from '../components/firebase'
import { history } from '../_helpers'
import { GET_PROFILES_REQUEST, GET_PROFILES_SUCCESS, GET_PROFILES_ERROR, GET_CURRENT_PROFILE_REQUEST, GET_CURRENT_PROFILE_SUCCESS, GET_CURRENT_PROFILE_ERROR, GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_ERROR, GET_CURRENT_QUESTIONS_REQUEST, GET_CURRENT_QUESTIONS_SUCCESS, GET_CURRENT_QUESTIONS_ERROR, SEND_INVITE_REQUEST, SEND_INVITE_SUCCESS, SEND_INVITE_ERROR, UPDATE_INVITE_STATUS_REQUEST, UPDATE_INVITE_STATUS_SUCCESS, UPDATE_INVITE_STATUS_ERROR, UPDATE_QUANTITY_REQUEST, UPDATE_QUANTITY_SUCCESS, UPDATE_QUANTITY_ERROR, GET_ASSIGNEE_LIST_REQUEST, GET_ASSIGNEE_LIST_SUCCESS, GET_ASSIGNEE_LIST_ERROR, GET_PROFILE_INVITATION_REQUEST, GET_PROFILE_INVITATION_SUCCESS, GET_PROFILE_INVITATION_ERROR, GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_ERROR, UPDATE_USER_PROFILE_REQUEST, UPDATE_USER_PROFILE_SUCCESS, UPDATE_USER_PROFILE_ERROR, GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_ERROR, ADD_TEST_RESULT_REQUEST, ADD_TEST_RESULT_SUCCESS, ADD_TEST_RESULT_ERROR, GET_USER_RESULT_REQUEST, GET_USER_RESULT_SUCCESS, GET_USER_RESULT_ERROR, GET_CURRENT_RESULT_REQUEST, GET_CURRENT_RESULT_SUCCESS, GET_CURRENT_RESULT_ERROR, GET_GENERAL_NOTES_REQUEST, GET_GENERAL_NOTES_SUCCESS, GET_GENERAL_NOTES_ERROR, GET_GUIDELINES_REQUEST, GET_GUIDELINES_SUCCESS, GET_GUIDELINES_ERROR, ADD_PRODUCT_REQUEST, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_ERROR, UPDATE_PRODUCT_REQUEST, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_ERROR, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_ERROR, GET_INSTRUCTIONS_REQUEST, GET_INSTRUCTIONS_SUCCESS, GET_INSTRUCTIONS_ERROR, CANCEL_INVITE_REQUEST, CANCEL_INVITE_SUCCESS, CANCEL_INVITE_ERROR, GET_CANCEL_INVITATION_LIST, GET_CANCEL_INVITATION_SUCCESS, GET_CANCEL_INVITATION_ERROR, GET_RESULT_BY_PROFILE_REQUEST, GET_RESULT_BY_PROFILE_SUCCESS, GET_RESULT_BY_PROFILE_ERROR, UPDATE_ASSESSMENT_VALUE_REQUEST, UPDATE_ASSESSMENT_VALUE_SUCCESS, UPDATE_ASSESSMENT_VALUE_ERROR } from '../constants/ProfileConstants'
import _ from 'lodash'
import { getcount , getPaginateData, getProfilecount, getPaginateProfileData } from './PaginationWithSearch'

async function getProfiles(){
    let array = []
    let categoryList
    categoryList = db.collection("profiles").orderBy("created_date_time", "desc")
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getProfilesData(){
    try{
        const profileList = yield call(getProfiles)
        yield put({ type: GET_PROFILES_SUCCESS, profileList })
    }
    catch(error){
        const err = "Error while getting Profile List"
        yield put({ type: GET_PROFILES_ERROR, err })
    }
}

function getCurrentProfile(id){
    return firebase.firestore().collection("profiles").doc(id).get()
    .then(response => response)
}

function* getCurrentProfileData(data){
    try{
        const { id } = data
        const list = yield call(getCurrentProfile, id)
        const currentprofile = list.data()
        currentprofile['id'] = id
        yield put({ type: GET_CURRENT_PROFILE_SUCCESS, currentprofile })
        localStorage.setItem('profile_results', currentprofile.profile_results)
    }
    catch(error){
        const err = "Error while getting Current Profile"
        yield put({ type: GET_CURRENT_PROFILE_ERROR, err })
    }
}

function* getUsersData(data){
    try{
        const { id, activePage, searchTerm } = data
        let userslist = [], count = []
        yield call(getTotalNodes, "users", count, searchTerm, userslist, activePage, id)
        yield put({ type: GET_USERS_SUCCESS, userslist, count  })
    }
    catch(error){
        const err = "Error while getting Users List"
        yield put({ type: GET_USERS_ERROR, err })
    }
}

async function getQuestionsList(id){
    const docRef = db.doc('profiles/' + id)
    let array = {}
    let categoryList = db.collection("profile_questions").where("profile_id", "==", docRef)
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array['data'] = {...doc.data()}
            array['id'] = doc.id
        })
    })
    return array
}

function* getCurrentQuestions(data){
    try{
        const { id } = data
        const questions = yield call(getQuestionsList, id)
        yield put({ type: GET_CURRENT_QUESTIONS_SUCCESS, questions})
    }
    catch(error){
        const err = "Error wwhile getting questions"
        yield put({ type: GET_CURRENT_QUESTIONS_ERROR, err })
    }
}

async function inviteSend(customers, profile_id){
    let uid = localStorage.getItem('uid')
    const requestBody = {
        api_call_type: "frontend_invitation",
        invited_by: uid,
        country_name: "United States",
        user_type: "user",
        profile_id: profile_id,
        line_items: [],
        customer: customers
    }
    return await fetch(process.env.REACT_APP_INVITE_API, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    })
    .then(response => response)
    .catch((error) => { 
        throw error
    })
}

async function getUserEmailList(email1, email2){
    let array = []
    let userList
    if(email1 === undefined){
        userList = db.collection("users").where("email", 'in', [email2])
    }
    else if(email2 === undefined){
        userList = db.collection("users").where("email", 'in', [email1])
    }
    else{
        userList = db.collection("users").where("email", 'in', [email1, email2])
    }
    await userList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* sendInviteData(data){
    try{
        const { customers, profile_id } = data
        // let email1, email2, list1, list2, profileInvitation1, profileInvitation2, msg = '', profileErrorMsg = '', userlist
        // let length = customers.length
        // if(length === 1){
        //     email1 = _.trim(_.toLower(customers[0].email))
        //     userlist = yield call(getUserEmailList, email1)
        //     if(userlist.length !== 0){
        //         const list = yield call(getProfileData, userlist[0].id)
        //         list1 = list.data()
        //     }
        // }
        // else if(length === 2){
        //     email1 = _.trim(_.toLower(customers[0].email))
        //     email2 = _.trim(_.toLower(customers[1].email))
        //     userlist = yield call(getUserEmailList, email1, email2)
        //     if(userlist.length !== 0){
        //         if(userlist.length === 1){
        //             const list = yield call(getProfileData, userlist[0].id)
        //             list1 = list.data()
        //         }
        //         else{
        //             const list = yield call(getProfileData, userlist[0].id)
        //             list1 = list.data()
        //             const newList = yield call(getProfileData, userlist[1].id)
        //             list2 = newList.data()
        //         }
        //     }
        // }
        // if(list1 !== undefined){
        //     profileInvitation1 = _.filter(list1.invited_plan, ['profile_id', profile_id])
        // }
        // if(list2 !== undefined){
        //     profileInvitation2 = _.filter(list2.invited_plan, ['profile_id', profile_id])
        // }
        // if(userlist.length === 0){
        //     yield call(inviteSend, customers, profile_id)
        //     msg = "Invitation sent successfully"
        // }
        // else{
        //     if(profileInvitation1 !== undefined && profileInvitation1.length === 0 && profileInvitation2 === undefined){
        //         yield call(inviteSend, customers, profile_id)
        //         msg = "Invitation sent successfully"
        //     }
        //     else if(profileInvitation1 === undefined && profileInvitation2 !== undefined && profileInvitation2.length === 0){
        //         yield call(inviteSend, customers, profile_id)
        //         msg = "Invitation sent successfully"
        //     }
        //     else if(profileInvitation1 === undefined && profileInvitation2 === undefined){
        //         yield call(inviteSend, customers, profile_id)
        //         msg = "Invitation sent successfully"
        //     }
        //     else{
        //         profileErrorMsg = "Same Profile has been assigned to this user"
        //     }
        // }
        // yield put({ type: SEND_INVITE_SUCCESS, msg, profileErrorMsg })
        yield call(inviteSend, customers, profile_id)
        const msg = "Invitation sent successfully"
        yield put({ type: SEND_INVITE_SUCCESS, msg })
        let assigneeValues = {
            activePage: undefined,
            searchTerm: undefined
        }
        let userValues = {
            id: undefined,
            activePage: undefined,
            searchTerm: undefined
        }
        // yield call(getAssigneeData, assigneeValues)
        // yield call(getUsersData, userValues)
    }
    catch(error){
        const err = "Error while sending Invite"
        yield put({ type: SEND_INVITE_ERROR, err })
    }
}

function updateStatus(data, id){
    return firebase.firestore().collection("profile_invitations").doc(id).set({'invited_plan': [{ 'invited_date': data.invited_date, 'invitation_status': data.invitation_status }]}, {merge: true})
          .then(response => response)
          .catch(error => { throw error })
}

function* updateInviteStatusData(data){
    try{
        const { items, id } = data
        yield updateStatus(items, id)
        yield put({ type: UPDATE_INVITE_STATUS_SUCCESS })
    }
    catch(error){
        const err = "Error while updating Invite Status"
        yield put({ type: UPDATE_INVITE_STATUS_ERROR, err })
    }
}

function getUser(){
    let id = localStorage.getItem('uid')
    return firebase.firestore().collection("users").doc(id).get()
    .then(response => response)
}

function updateData(data){
    let newData = { 'purchase_plan': data }
    let id = localStorage.getItem('uid')
    return firebase.firestore().collection("users").doc(id).set(newData, {merge: true})
        .then(response => response)
        .catch(error => { throw error })
}

function* updateQuantityData(data){
    try{
        const { profileId, orderId, customers } = data
        const list = yield call(getUser)
        const newList = list.data()
        _.forEach(newList.purchase_plan, function(item){
            if(item.order_id === orderId && item.profile_id === profileId){
                let remaining_quantity = item.remaining_quantity
                item.remaining_quantity = remaining_quantity - customers.length
            }
            return item
        })
        yield call(updateData, newList.purchase_plan)
        yield put({ type: UPDATE_QUANTITY_SUCCESS })
    }
    catch(error){
        const err = "Error while updating Quantity"
        yield put({ type: UPDATE_QUANTITY_ERROR, err })
    }
}

function* getAssigneeData(values){
    try{
        const { activePage, searchTerm } = values
        let list = [], count = []
        yield call(getTotalNodes, "profile_invitations", count, searchTerm, list, activePage)
        yield put({ type: GET_ASSIGNEE_LIST_SUCCESS, list, count })
    }
    catch(error){
        const err = "Error while getting assignee list"
        yield put({ type: GET_ASSIGNEE_LIST_ERROR, err })
    }
}

function getProfileData(id){
    return firebase.firestore().collection("profile_invitations").doc(id).get()
    .then(response => response) 
}

function* getProfileInvitationData(data){
    try{
        const { id, activePage, searchTerm } = data
        let newList = [], count = []
        yield call(getNodes, "profile_invitations", count, searchTerm, newList, activePage, id)
        yield put({ type: GET_PROFILE_INVITATION_SUCCESS, newList, count })
    }
    catch(error){
        const err = "Error while getting list"
        yield put({ type: GET_PROFILE_INVITATION_ERROR, err })
    }
}

function getuser(id){
    return firebase.firestore().collection("users").doc(id).get()
    .then(response => response)
}

function* getUsers(data){
    try{
        const { id } = data
        const list = yield call(getuser, id)
        const user = list.data()
        yield put({ type: GET_USER_PROFILE_SUCCESS, user })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_USER_PROFILE_ERROR, err })
    }
}

function updateUser(data){
    let id = localStorage.getItem('uid')
    return firebase.firestore().collection("users").doc(id).update(data)
    .then(response => response)
}

function* updateUserData(data){
    try{
        yield call(updateUser, data.data)
        yield put({ type: UPDATE_USER_PROFILE_SUCCESS })
        history.push('/userProfile')
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_USER_PROFILE_ERROR, err })
    }
}

async function getTotalNodes(collectionName, count, searchTerm, list, activePage, Id){
    if(searchTerm === undefined && activePage === undefined){}
    else{
        const totalnodes = await getcount(collectionName, searchTerm, Id)
        if(totalnodes.length === undefined){
            totalnodes.docs.map(doc => {
                return count.push(doc.id)
            })
        }
        else{
            totalnodes.map(id => {
                return count.push(id)
            })
        }
    }
    await getPaginateData(collectionName, activePage, list, searchTerm, Id)
}

async function getNodes(collectionName, count, searchTerm, list, activePage, Id){
    if(searchTerm === undefined && activePage === undefined){}
    else{
        const totalnodes = await getProfilecount(collectionName, searchTerm, Id)
        if(totalnodes.length === undefined){
            totalnodes.docs.map(doc => {
                return count.push(doc.id)
            })
        }
        else{
            totalnodes.map(id => {
                return count.push(id)
            })
        }
    }
    await getPaginateProfileData(collectionName, activePage, list, searchTerm, Id)
}

function* getAllUsersData(values){
    try{
        const { activePage, searchTerm } = values
        let list = [], count = []
        yield call(getTotalNodes, "users", count, searchTerm, list, activePage)
        yield put({ type: GET_ALL_USERS_SUCCESS, list, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_ALL_USERS_ERROR, err })
    }
}

function addResult(data){
    return firebase.firestore().collection('user_result').add(data)
    .then(response => response)
}

function updateProfileTestValue(user_id, newList){
    const { total_profile_test_given } = newList
    let newObj = {}
    newObj['total_profile_test_given'] = total_profile_test_given - 1
    return firebase.firestore().collection("profile_invitations").doc(user_id).set(newObj, {merge: true})
        .then(response => response)
        .catch(error => { 
            throw error 
        })
}

function* addTestData(data){
    try{
        const { result } = data
        const { user_id, profile_id, invited_by } = result
        yield call(addResult, result)
        const msg = "Data Added Successfully"
        const list = yield call(getProfileData, user_id)
        const newList = list.data()
        let profileTypee = localStorage.getItem('purchased_profile')
        if(profileTypee === "profile_assigned"){
            if(newList !== undefined){
                yield call(updateProfileTestValue, user_id, newList)
            }
        }
        let type = localStorage.getItem('type')
        let uid = localStorage.getItem('uid')
        const userList = yield call(getuser, uid)
        const user = userList.data()
        if(type === "trainer" && invited_by === uid){
            _.forEach(user.purchase_plan, function(plan){
                if(plan.profile_id === profile_id){
                    plan.remaining_quantity = plan.remaining_quantity - 1
                }
                return user
            })
            yield call(updateUser, user)
        }
        let values = {
            'profile_id': profile_id,
            'user_id': user_id,
            'key': 'addresult'
        }
        yield call(updateAssessmentData, values)
        yield put({ type: ADD_TEST_RESULT_SUCCESS, msg })
    }
    catch(error){
        const err = "Error while adding data"
        yield put({ type: ADD_TEST_RESULT_ERROR, err })
    }
}

async function getResult(profile_id, user_id){
    let array = {}
    let report_invited_by_id = localStorage.getItem('report_invited_by_id')
    let resultList = db.collection("user_result").where("profile_id", "==", profile_id).where("user_id", "==", user_id).where('invited_by', "==", report_invited_by_id)
    await resultList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array['data'] = doc.data()
            array['id'] = doc.id
        })
    })
    return array
}

function* getUserResultData(data){
    try{
        const { profile_id, user_id } = data
        const list = yield call(getResult, profile_id, user_id)
        yield put({ type: GET_USER_RESULT_SUCCESS, list })
    }
    catch(error){
        const err = "Error while getting list"
        yield put({ type: GET_USER_RESULT_ERROR, err })
    }
}

async function getCurrentResult(id){
    let array = []
    let existingUserList = db.collection("user_result").where("user_id", "==", id)
    await existingUserList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getCurrentResultData(data){
    try{
        const { id } = data
        const list = yield call(getCurrentResult, id)
        yield put({ type: GET_CURRENT_RESULT_SUCCESS, list })
    }
    catch(error){
        const err = "Error while getting list"
        yield put({ type: GET_CURRENT_RESULT_ERROR, err })
    }
}

async function getNoteandGuidelines(name, profileType){
    let array = {}
    let List
    if(name === "guidelines"){
        List = db.collection(name).where("guidelines_type", "==", profileType)
    }
    else{
        List = db.collection(name).where("notes_type", "==", profileType)
    }
    await List.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array['data'] = {...doc.data()}
            array['id'] = doc.id
        })
    })
    return array
}

function* getGeneralNotesData(data){
    try{
        const { profileType } = data
        const list = yield call(getNoteandGuidelines, "general_notes", profileType)
        yield put({ type: GET_GENERAL_NOTES_SUCCESS, list })
    }
    catch(error){
        const err = "Error while getting General Notes"
        yield put({ type: GET_GENERAL_NOTES_ERROR, err })
    }
}

function* getGuidelineData(data){
    try{
        const { profileType } = data
        const guideline = yield call(getNoteandGuidelines, "guidelines", profileType)
        yield put({ type: GET_GUIDELINES_SUCCESS, guideline })
    }
    catch(error){
        const err = "Error while getting Guidelines"
        yield put({ type: GET_GUIDELINES_ERROR, err })
    }
}

async function addProduct(CurrentProductData){
    const requestBody = {
        action_type: "add_product",
        product_data: {
            product: {
                title: CurrentProductData.profile_name,
                body_html: CurrentProductData.profile_description,
                vendor: "lpt",
                product_type: CurrentProductData.profile_type,
                variants: [{
                    price: CurrentProductData.profile_cost,
                    sku: CurrentProductData.profile_SKU,
                    requires_shipping: false
                }],
                images: [{
                    attachment: CurrentProductData.profile_image,
                    filename: `${_.trim(CurrentProductData.profile_name)}.png`
                }],
                tags:`dbid-${CurrentProductData.profile_id}`
            }
        }
    }
    return await fetch(process.env.REACT_APP_SHOPIFY_API, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    })
    .then(response => response.json())
    .catch((error) => { 
        throw error
    })
}

function updateProductId(profile_id, product_id){
    let data = {}
    let Product_id = _.toString(product_id)
    if(!_.isEmpty(Product_id)){
        data['profile_shopify_id'] = Product_id
    }
    else{
        data['profile_shopify_id'] = ""
    }
    return firebase.firestore().collection("profiles").doc(profile_id).set(data, {merge: true})
        .then(response => response)
        .catch(error => { 
            throw error 
        })
}

function* addProductData(data){
    try{
        const { CurrentProductData } = data
        const response = yield call(addProduct, CurrentProductData)
        yield call(updateProductId, CurrentProductData.profile_id, response.product_id)
        const msg = "Product push to shopify Successfully"
        yield put({ type: ADD_PRODUCT_SUCCESS, msg })
    }
    catch(error){
        const err = "Error while adding Data in Shopify"
        yield put({ type: ADD_PRODUCT_ERROR, err })
    }
}

async function updateProduct(CurrentProductData){
    const requestBody = {
        action_type: "update_product",
        product_id:CurrentProductData.product_id,
        product_data: {
            product: {
                title: CurrentProductData.profile_name,
                body_html: CurrentProductData.profile_description,
                vendor: "lpt",
                product_type: CurrentProductData.profile_type,
                variants: [{
                    price: CurrentProductData.profile_cost,
                    sku : CurrentProductData.profile_SKU,
                    inventory_management: null,
                    requires_shipping: false
                }],
                images: [{
                    attachment: CurrentProductData.profile_image,
                    filename: `${_.trim(CurrentProductData.profile_name)}.png`
                }],
                tags:`dbid-${CurrentProductData.profile_id}`
            }
        }
    }
    return await fetch(process.env.REACT_APP_SHOPIFY_API, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    })
    .then(response => response)
    .catch((error) => { 
        throw error
    })
}

function* updateProductData(data){
    try{
        const { CurrentProductData } = data
        yield call(updateProduct, CurrentProductData)
        const msg = "Product updated to shopify Successfully"
        yield put({ type: UPDATE_PRODUCT_SUCCESS, msg })
    }
    catch(error){
        const err = "Error while updating Data in Shopify"
        yield put({ type: UPDATE_PRODUCT_ERROR, err })
    }
}

async function deleteProduct(CurrentProductData){
    const requestBody = {
        action_type: "delete_product",
        product_id:CurrentProductData.product_id
    }
    return await fetch(process.env.REACT_APP_SHOPIFY_API, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    })
    .then(response => response)
    .catch((error) => { 
        throw error
    })
}

function* deleteProductData(data){
    try{
        const { CurrentProductData } = data
        yield call(deleteProduct, CurrentProductData)
        yield call(updateProductId, CurrentProductData.profile_id, "")
        const msg = "Product deleted from shopify Successfully"
        yield put({ type: DELETE_PRODUCT_SUCCESS, msg })
    }
    catch(error){
        const err = "Error while updating Data in Shopify"
        yield put({ type: DELETE_PRODUCT_ERROR, err })
    }
}

async function getInstruction(profileType){
    let array = {}
    let List = db.collection("instructions").where("instructions_type", "==", profileType)
    await List.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array['data'] = {...doc.data()}
            array['id'] = doc.id
        })
    })
    return array
}

function* getInstructionsData(data){
    try{
        const { profileType } = data
        const instruction = yield call(getInstruction, profileType) 
        yield put({ type: GET_INSTRUCTIONS_SUCCESS, instruction })
    }
    catch(error){
        const err = "Error while getting instructions"
        yield put({ type: GET_INSTRUCTIONS_ERROR, err })
    }
}

function addcancelInviteData(json, user_id){
    return firebase.firestore().collection("cancel_invitation").doc(user_id).set(json, {merge: true})
            .then(response => response)
            .catch(error => { throw error })
}

function updateProfileInvitation(newObj, user_id){
    return firebase.firestore().collection("profile_invitations").doc(user_id).set(newObj, {merge: true})
        .then(response => response)
        .catch(error => { 
            throw error 
        })
}

function deleteProfileInvitation(user_id){
    return firebase.firestore().collection("profile_invitations").doc(user_id).delete()
        .then(response => response)
        .catch(error => { 
            throw error 
        })
}

function deleteUserData(user_id){
    return firebase.firestore().collection("users").doc(user_id).delete()
    .then(response => response)
}

async function deleteAuthUser(email){
    const requestBody = {
        email_id: email
    }
    return await fetch(process.env.REACT_APP_DELETE_USER, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    })
    .then(response => response)
    .catch((error) => { 
        throw error
    })
}

function getSingleCancelInvitation(id){
    return firebase.firestore().collection("cancel_invitation").doc(id).get()
    .then(response => response)
}

async function sendCancelProfileMail(list){
    const requestBody = {
        name: list.name,
        profile_name: list.profile_name,
        loggedin_user: list.loggedIn_userName,
        user_email: list.email
    }
    return await fetch(process.env.REACT_APP_CANCEL_PROFILE_MAIL, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    })
    .then(response => response)
    .catch((error) => { 
        throw error
    })
}

function* cancelInviteData(data){
    try{
        const { json, user_id, invitations, user_status, usersList, current_profile_name, cancelUserType } = data
        let userType = localStorage.getItem('type')
        let profile_id, invited_by, newArray = [], totalInvitee = [], newObj, invitedPlan, newInvitations = [], newInviteById = [], loggedIn_userName
        let uid = localStorage.getItem('uid')
        const singleList = yield call(getSingleCancelInvitation, user_id)
        const singleInvitationList = singleList.data()
        if(singleInvitationList === undefined){
            yield call(addcancelInviteData, json, user_id)
        }
        else{
            _.forEach(singleInvitationList.invitations, function(invitation){
                newInvitations.push(invitation)
            })
            _.forEach(json.invitations, function(item){
                item['cancelled_date'] = new Date()
                newInvitations.push(item)
            })
            _.forEach(singleInvitationList.invited_by_id, function(id){
                newInviteById.push(id)
            })
            _.forEach(json.invited_by_id, function(id){
                newInviteById.push(id)
            })
            let newJson = {
                'modified_date_time': new Date(),
                'invited_by_id': _.uniq(newInviteById),
                'invitations': newInvitations
            }
            yield call(addcancelInviteData, newJson, user_id)
        }
        _.forEach(json.invitations, function(item){
            profile_id = item.profile_id
            invited_by = item.invited_by
        })
        _.forEach(invitations.invited_plan, function(plan){
            newArray.push({ 'id':  plan.invited_by })
        })
        totalInvitee = _.uniqBy(newArray, 'id')
        let invitedById
        let totalProfile = invitations.total_profile_test_given - 1
        let totalInvitedPlans = invitations.invited_plan.length
        if(totalInvitee.length === 1){
            if(totalInvitedPlans === 1){
                invitedPlan = _.filter(invitations.invited_plan, function(o){
                    return o.profile_id !== profile_id && o.invited_by !== invited_by
                })
                invitedById = _.filter(invitations.invited_by_id, function(id){
                    return id !== uid
                }) 
                newObj = {
                    'invited_by_id': invitedById,
                    'invited_plan': invitedPlan
                }
            }
            else{
                invitedPlan = _.filter(invitations.invited_plan, function(o){
                    return o.profile_id !== profile_id
                })
                newObj = {
                    'invited_plan': invitedPlan
                }
            }
        }
        else{
            let newinvitedId = []
            invitedPlan = _.filter(invitations.invited_plan, function(o){
                return !(o.invited_by === invited_by && o.profile_id === profile_id)
            })
            _.forEach(invitedPlan, function(plan){
                newinvitedId.push(plan.invited_by)
            })
            invitedById = _.uniq(newinvitedId) 
            newObj = {
                'invited_by_id': invitedById,
                'invited_plan': invitedPlan
            }
        }
        newObj['total_profile_test_given'] = totalProfile
        if(totalInvitedPlans === 1){
            if(user_status === 0){
                if(cancelUserType === "trainer"){
                    yield call(deleteProfileInvitation, user_id)
                }
                else{
                    yield call(deleteProfileInvitation, user_id)
                    yield call(deleteUserData, user_id)
                    yield call(deleteAuthUser, json.email)
                }
            }
            else{
                yield call(deleteProfileInvitation, user_id)
            }
        }
        else{
            yield call(updateProfileInvitation, newObj, user_id)
        }
        if(userType === "trainer"){
            const list = yield call(getUser)
            const user = list.data()
            _.forEach(user.purchase_plan, function(plan){
                if(plan.profile_id === profile_id){
                    plan.remaining_quantity = plan.remaining_quantity + 1
                }
                return user
            })
            yield call(updateUser, user)
        }
        _.forEach(usersList, function(list){
            if(list.id === uid){
                loggedIn_userName = list.name
            }
        })
        const msg = "Invitation cancelled succesfully"
        yield put({ type: CANCEL_INVITE_SUCCESS, msg })
        let cancelInviteMailData = {
            name: json.name,
            profile_name: current_profile_name,
            loggedIn_userName: loggedIn_userName,
            email: json.email
        }
        yield call(sendCancelProfileMail, cancelInviteMailData)
        let assigneeValues = {
            activePage: undefined,
            searchTerm: undefined
        }
        let userValues = {
            id: undefined,
            activePage: undefined,
            searchTerm: undefined
        }
        let userId = {
            id: uid
        }
        yield call(getAssigneeData, assigneeValues)
        yield call(getUsersData, userValues)
        yield call(getUsers, userId)
    }
    catch(error){
        const err = error.message
        yield put({ type: CANCEL_INVITE_ERROR, err })
    }
}

function* getCancelInviteData(values){
    try{
        const { activePage, searchTerm } = values
        let list = [], count = []
        yield call(getTotalNodes, "cancel_invitation", count, searchTerm, list, activePage)
        yield put({ type: GET_CANCEL_INVITATION_SUCCESS, list, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_CANCEL_INVITATION_ERROR, err })
    }
}

async function getResultProfile(profile_id){
    let array = []
    let resultList = db.collection("user_result").where("profile_id", "==", profile_id)
    await resultList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getresultData(values){
    try{
        const { profileId } = values
        const list = yield call(getResultProfile, profileId)
        yield put({ type: GET_RESULT_BY_PROFILE_SUCCESS, list })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_RESULT_BY_PROFILE_ERROR, err })
    }
}

function* updateAssessmentData(values){
    try{
        const { profile_id, user_id, key } = values
        let newObj = {}, msg= ''
        const profile_invited_by = localStorage.getItem('profile_invited_by')
        const list = yield call(getProfileData, user_id)
        const singleList = list.data()
        if(profile_invited_by === "undefined"){}
        else{
            if(singleList === undefined){
                msg = 'This Profile has been cancelled now!!'
            }
            else{
                let valueExists
                let obj = _.filter(singleList.invited_plan, { 'profile_id': profile_id, 'invited_by': profile_invited_by })
                if(obj.length === 0){
                    valueExists = false
                }
                else{
                    valueExists = true
                }
                _.forEach(singleList.invited_plan, function(plan){
                    if(key === undefined){
                        if(plan.profile_id === profile_id && plan.invited_by === profile_invited_by && valueExists){
                            plan.assessment_status = 1
                            return plan
                        }
                        else{
                            if(!valueExists){
                                msg = 'This Profile has been cancelled now!!'
                            }
                        }
                    }
                    else{
                        if(plan.profile_id === profile_id && plan.invited_by === profile_invited_by){
                            plan.assessment_status = 2
                        }
                        return plan
                    }
                })
                newObj['invited_plan'] = singleList.invited_plan
                yield call(updateProfileInvitation, newObj, user_id)    
            }
        }
        yield put({ type: UPDATE_ASSESSMENT_VALUE_SUCCESS, msg })
        if(msg !== ''){
            history.push('/profileAssigned')
        }
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_ASSESSMENT_VALUE_ERROR, err })
    }
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_PROFILES_REQUEST, getProfilesData)
      yield takeLatest(GET_CURRENT_PROFILE_REQUEST, getCurrentProfileData)
      yield takeLatest(GET_USERS_REQUEST, getUsersData)
      yield takeLatest(GET_CURRENT_QUESTIONS_REQUEST, getCurrentQuestions)
      yield takeLatest(SEND_INVITE_REQUEST, sendInviteData)
      yield takeLatest(UPDATE_INVITE_STATUS_REQUEST, updateInviteStatusData)
      yield takeLatest(UPDATE_QUANTITY_REQUEST, updateQuantityData)
      yield takeLatest(GET_ASSIGNEE_LIST_REQUEST, getAssigneeData)
      yield takeLatest(GET_PROFILE_INVITATION_REQUEST, getProfileInvitationData)
      yield takeLatest(GET_USER_PROFILE_REQUEST, getUsers)
      yield takeLatest(UPDATE_USER_PROFILE_REQUEST, updateUserData)
      yield takeLatest(GET_ALL_USERS_REQUEST, getAllUsersData)
      yield takeLatest(ADD_TEST_RESULT_REQUEST, addTestData)
      yield takeLatest(GET_USER_RESULT_REQUEST, getUserResultData)
      yield takeLatest(GET_CURRENT_RESULT_REQUEST, getCurrentResultData)
      yield takeLatest(GET_GENERAL_NOTES_REQUEST, getGeneralNotesData)
      yield takeLatest(GET_GUIDELINES_REQUEST, getGuidelineData)
      yield takeLatest(ADD_PRODUCT_REQUEST, addProductData)
      yield takeLatest(UPDATE_PRODUCT_REQUEST, updateProductData)
      yield takeLatest(DELETE_PRODUCT_REQUEST, deleteProductData)
      yield takeLatest(GET_INSTRUCTIONS_REQUEST, getInstructionsData)
      yield takeLatest(CANCEL_INVITE_REQUEST, cancelInviteData)
      yield takeLatest(GET_RESULT_BY_PROFILE_REQUEST, getresultData)
      yield takeLatest(GET_CANCEL_INVITATION_LIST, getCancelInviteData)
      yield takeLatest(UPDATE_ASSESSMENT_VALUE_REQUEST, updateAssessmentData)
    }
    return {watcher}
}