import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import ReactLoading from "react-loading"
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import { formatMoney } from '../date/convertDate'
import _ from 'lodash'
import { getProfiles, getUserProfile } from '../../../actions/ProfileActions'
import UserDetailsTable from './UserDetailsTable'
import UserDetailsPopup from './UserDetailsPopup'

const UserDetails = (props) => {
    let pathname = props.location.pathname
    const { getUserProfile, currentUser, loading, error, getProfiles, Profilelist } = props
    const [imageUrl, setImageUrl] = useState('')
    const [showImage, setShowImage] = useState(true)
    const [orderId, setOrderId] = useState('')
    const [idArray, setIdArray] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [amount, setAmount] = useState('')
    let { id } = props.match.params

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getUserProfile(id)
            }
            else{
                history.push('/login') 
            }
        }
    },[getUserProfile, pathname, id])

    const getUser = () => {
        let totalAmount, array = []
        if(currentUser.purchase_plan !== undefined){
            currentUser.purchase_plan.map(item => {
                array.push(item.amount)
            })
            totalAmount = _.sum(array)
        }
        const total = formatMoney(totalAmount)
        return(
            <div className="row">
                    <div className="col-md-9 grid-margin stretch-card">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="profile-sidebar">
                                        <div className="profile-userpic">
                                            <img src={imageUrl} className="img-responsive" alt="" />  
                                        </div>
                                        <div className="profile-usertitle">
                                            <div className="profile-usertitle-name">
                                                {currentUser.name !== '' || currentUser.name !== undefined || _.isEmpty(currentUser.name) ? _.capitalize(currentUser.name) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-md-7 mt-5 profileDetails">
                                    <p className="text-muted"> <span className="font-weight-bold"> Email Id :</span> {currentUser.email} </p>
                                    <p className="text-muted"> <span className="font-weight-bold"> Phone:</span> {currentUser.phone_number === undefined || currentUser.phone_number === null || _.isEmpty(currentUser.phone_number) ? '--' : currentUser.phone_number} </p>
                                    <p className="text-muted"> <span className="font-weight-bold"> Country :</span> {currentUser.country === undefined || currentUser.country === null ? '--' : _.capitalize(currentUser.country)} </p>
                                </div>      
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 grid-margin stretch-card resultListing">
                        <div className="card totalPurchase">
                            <div className="card-header">
                                Total Purchase
                            </div>
                            <div className="card-body">
                                <div className="pt-3 text-center">
                                    <h1> &#163;{total !== undefined ? total : 0}</h1> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
        )
    }

    if(currentUser !== '' && showImage){
        setImageUrl(currentUser.user_img_url)
        setShowImage(false)
        setShowModal(true)
    }

    const renderProfile = (id, orderid, amount) => {
        const items = _.uniq(id)
        setIdArray(items)
        setOrderId(orderid)
        setAmount(amount)
        getProfiles()
    }

    return(
        <div className="main-panel">
            <div className="content-wrapper pt-3">
                <Breadcrumb title="User" subTitle="User Details" />
                {error !== "" ? <h3 className="errorMsg text-center"> {error} </h3> : null}
                {currentUser !== null ? getUser() : null}  
                {loading ? <div className="loader-div"><ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /></div> : null} 
                <div className="row">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            {currentUser !== null ? <UserDetailsTable currentUser={currentUser} renderProfile={renderProfile} showAction={true} /> : null}
                        </div>
                    </div>
                </div> 
            </div>
            {showModal ? <UserDetailsPopup orderId={orderId} idArray={idArray} amount={amount} Profilelist={Profilelist} /> : null}
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentUser: state.profileReducer.currentUser,
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    Profilelist: state.profileReducer.Profilelist,
})

const mapDispatchToProps = {
    getUserProfile,
    getProfiles
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)