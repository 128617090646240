var monthNames = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
]

export const convertDate = (data) => {
    let milliseconds = data.seconds * 1000
    const dateObject = new Date(milliseconds)
    let monthName = dateObject.getMonth()
    var year = dateObject.getFullYear()
    var month =  monthNames[monthName]
    var date = dateObject.getDate()
    let new_date = date + " " + month + "," + " " + year

    return new_date
}

const addZero = (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
}

export const convertDateTime = (data) => {
    let milliseconds = data.seconds * 1000
    const dateObject = new Date(milliseconds)
    const hours = addZero(dateObject.getHours())
    var newHours = hours > 12 ? hours - 12 : hours 
    var ampm = hours >= 12 ? 'PM' : 'AM'
    const minutes = addZero(dateObject.getMinutes())
    const seconds = addZero(dateObject.getSeconds())
    let monthName = dateObject.getMonth()
    var year = dateObject.getFullYear()
    var month =  monthNames[monthName]
    var date = dateObject.getDate()
    let new_date = date + " " + month + "," + " " + year + " " + "at" + " " + newHours + ":" + minutes + ":" + seconds + " " + ampm

    return new_date
}

export const renderMonthDiff = (oldDate, currentDate) => {
    let from = monthNames[oldDate.getMonth()] + oldDate.getFullYear() 
    let to = monthNames[currentDate.getMonth()] + currentDate.getFullYear() 
    var arr = []
    var datFrom = new Date('1 ' + from)
    var datTo = new Date('1 ' + to)
    var fromYear =  datFrom.getFullYear()
    var toYear =  datTo.getFullYear()
    var diffYear = (12 * (toYear - fromYear)) + datTo.getMonth()

    for (var i = datFrom.getMonth(); i <= diffYear; i++) {
        arr.push(monthNames[i%12].toString().substr(0, 3) + " " + Math.floor(fromYear+(i/12)).toString().substr(-2))
    }        
    
    return arr
}

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
    const negativeSign = amount < 0 ? "-" : "";
  
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
  
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
}