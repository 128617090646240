import { LOGIN_REQUEST, LOGOUT_USER, RESET_PASSWORD_REQUEST, FORGOT_PASSWORD_REQUEST } from '../constants/AuthConstants'

export const loginUser = (data) => {
    const { email, password } = data
    return{
        type: LOGIN_REQUEST,
        email,
        password
    }
}

export const logoutUser = () => {
    return{
        type: LOGOUT_USER
    }
}

export const resetPassword = (newPassword) => {
    return{
        type: RESET_PASSWORD_REQUEST,
        newPassword
    }
}

export const forgotPassword = (email) => {
    return{
        type: FORGOT_PASSWORD_REQUEST,
        email
    }
}