import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import { uploadImage } from '../../../actions/HelperActions'
import ReactLoading from "react-loading"
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import { convertDate, formatMoney } from '../date/convertDate'
import _ from 'lodash'
import { getProfiles, getUserProfile } from '../../../actions/ProfileActions'

const UserProfile = (props) => {
    let pathname = props.location.pathname
    const { getUserProfile, currentUser, uploadImage, loading, error, imgError, imgSuccessMsg, getProfiles, Profilelist, imgLoader } = props
    const [imageUrl, setImageUrl] = useState('')
    const [showImage, setShowImage] = useState(true)
    const [orderId, setOrderId] = useState('')
    const [idArray, setIdArray] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [amount, setAmount] = useState('')
    let uid = localStorage.getItem('uid')
    const [successMsg, setSuccessMsg] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [showSuccessMsg, setShowSuccessMsg] = useState(true)
    const [showErrorMsg, setShowErrorMsg] = useState(true)
    const [showPopup, setShowPopup] = useState(false)

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getUserProfile(uid)
            }
            else{
                history.push('/login') 
            }
        }
    },[getUserProfile, pathname, uid])

    useEffect(() => {
        if(showPopup){
            window.$('#viewOrder').modal('show')
        }
        const timer = setTimeout(() => {
            setSuccessMsg('')
            setErrorMsg('')
		}, 4000);
		return () => clearTimeout(timer)
    })

    const handleImageChange = (e) => {
        e.preventDefault()
        let reader = new FileReader()
        let file = e.target.files[0]
        setShowPopup(false)
        setShowSuccessMsg(true)
        setShowErrorMsg(true)
        reader.onloadend = () => {
            setImageUrl(reader.result)
        }
        if(file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg"){
            uploadImage(file, "user_img_url", "lpt")
        }
        reader.readAsDataURL(file)
    }

    const handleClose = () => {
        setShowPopup(false)
    }

    if(imgSuccessMsg !== '' && showSuccessMsg){
        if(imgSuccessMsg !== undefined){
            setSuccessMsg(imgSuccessMsg)
            setShowSuccessMsg(false)
        }
    }
    
    if(imgError !== '' && showErrorMsg){
        if(imgError !== undefined){
		    setErrorMsg(imgError)
            setShowErrorMsg(false)
        }
    }

    const getUser = () => {
        let totalAmount, array = []
        if(currentUser.purchase_plan !== undefined){
            currentUser.purchase_plan.map(item => {
                array.push(item.amount)
            })
            totalAmount = _.sum(array)
        }
        const total = formatMoney(totalAmount)
        let currentUserName = _.trim(currentUser.name)
        return(
            <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="profile-sidebar">
                                        {successMsg !== "" ? successMsg === undefined ? null : <div className="successMessage imgMsg"><h5 className="successMsg imgError">{successMsg}</h5></div> : null}
                                        {errorMsg !== "" ? errorMsg === undefined ? null : <div className="errorMessage imgMsg"><h5 className="errorMsg imgError"> {errorMsg} </h5></div> : null}
                                        <div className="profile-userpic">
                                            <img src={imageUrl} className="img-responsive" alt="" />
                                            <label htmlFor="upload" className="btn-file">
                                                <i className="mdi mdi-camera"></i>
                                            </label>
                                            {imageUrl === undefined ? <i className="mdi mdi-upload menu-icon"></i> : null}
                                            <input className="d-none" id="upload" type="file" onChange={handleImageChange} accept="image/gif, image/jpeg, image/png" name="imagee" />  
                                        </div>
                                        <div className="profile-usertitle">
                                            <div className="profile-usertitle-name">
                                                {_.capitalize(currentUserName)}
                                            </div>
                                        </div>
                                        <div className="profile-userbuttons">
                                            <a href="/editProfile">
                                                <button type="button" className="btn btn-danger btn-sm">Edit Profile</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-md-7 mt-5 profileDetails">
                                    <p className="text-muted"> <span className="font-weight-bold"> Email Id :</span> {currentUser.email} </p>
                                    <p className="text-muted"> <span className="font-weight-bold"> Phone:</span> {currentUser.phone_number === undefined || currentUser.phone_number === null || _.isEmpty(currentUser.phone_number) ? '--' : currentUser.phone_number} </p>
                                    <p className="text-muted"> <span className="font-weight-bold"> Country :</span> {currentUser.country === undefined || currentUser.country === null || _.isEmpty(currentUser.country) ? '--' : _.capitalize(currentUser.country)} </p>
                                </div>      
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-3 grid-margin stretch-card resultListing">
                        <div className="card totalPurchase">
                            <div className="card-header">
                                Total Purchase
                            </div>
                            <div className="card-body">
                                <div className="pt-3 text-center">
                                    <h1> &#163;{total !== undefined ? total : 0}</h1> 
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                
        )
    }

    if(currentUser !== '' && showImage){
        setImageUrl(currentUser.user_img_url)
        setShowImage(false)
        setShowModal(true)
    }

    const getModal = () => {
        if(Profilelist === undefined || Profilelist.length === 0){
            return null
        }
        else{
            let newArray = []
            idArray.map(id => {
                newArray.push(_.find(Profilelist, ['id', id]))
            })
            _.forEach(newArray, function(item, key){
                item['amount'] = amount[key]
            })
            return(
                <div className="modal fade" id="viewOrder" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="viewOrderTitle"  aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <h3 className="text-center pt-4"> Order Id: #{orderId}</h3>
                            <button type="button" className="close btn-model-close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: '6.5rem' }} onClick={handleClose}>
                                <span aria-hidden="true">×</span>
                            </button>
                            {newArray.map(item => {
                                const total = formatMoney(item.amount)
                                return(
                                    <div className="modal-header border-0 modaltextInfo" key={item.id}>
                                        <div className="d-flex font-weight-bold"> 
                                            <div className="col-sm-3 mr-2"> <img src={item.profile_image} alt="" /></div> 
                                            <div className="pt-0">
                                                <h3 className="d-block pb-1 text-center">{item.profile_name}</h3> 
                                                <div className="modal-body">              
                                                    <p> Profile SKU: &nbsp; <span className="viewUserPopup">{item.Profile_SKU}</span></p>
                                                    <p> Amount: &nbsp; <span className="viewUserPopup">&#163;{total}</span></p> 
                                                    <p> Profile Description: &nbsp; <span className="viewUserPopup">{item.profile_description}</span></p>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        }
    }

    const getTable = () => {
        return(
            <div className="card-body">
                <h4 className="card-title pt-2">Order Summary</h4>
                <div className="table-responsive pt-3">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th> S.No </th>
                                <th> Order Id </th>
                                <th> Line Items </th>
                                <th> Amount </th>
                                <th> Purchase Date </th>
                                <th> Actions </th>
                            </tr>
                        </thead>
                        {getTableBody()}
                    </table>
                </div>
            </div>
        )
    }

    const renderProfile = (id, orderid, amount) => {
        const items = _.uniq(id)
        setIdArray(items)
        setOrderId(orderid)
        setAmount(amount)
        getProfiles()
        setShowPopup(true)
    }

    const getTableBody = () => {
        if(currentUser.purchase_plan === undefined){
            return <tbody><tr><td>No Order Summary Available</td></tr></tbody>
        }
        var output = currentUser.purchase_plan.reduce(function(o, cur) {
            // Get the index of the key-value pair.
            var occurs = o.reduce(function(n, item, i) {
              return (item.order_id === cur.order_id) ? i : n;
            }, -1)
            // If the name is found,
            if (occurs >= 0) {
              // append the current value to its list of values.
              o[occurs].amount = o[occurs].amount.concat(cur.amount);
              o[occurs].profile_id = o[occurs].profile_id.concat(cur.profile_id)
              // Otherwise,
            } else {
              // add the current item to o (but make sure the value is an array).
              var obj = {
                order_id: cur.order_id,
                amount: [cur.amount],
                profile_id: [cur.profile_id],
                purchase_date: cur.purchase_date
              };
              o = o.concat([obj])
            }
            return o;
        }, [])
        _.forEach(output, function(item){
            item['line_items'] = item.amount.length
        })
        return(
            <tbody>
                {output.map((item, index) => {
                    let purchase_date = convertDate(item.purchase_date)
                    const amount = _.sum(item.amount)
                    const total = formatMoney(amount)
                    return(
                        <tr key={index}>
                            <td> {index+1} </td>
                            <td className="font-weight-bold">{item.order_id}</td>
                            <td>{item.line_items}</td>
                            <td>&#163;{total}</td>
                            <td>{purchase_date}</td>
                            <td>
                                <button type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#viewOrder" onClick={() => renderProfile(item.profile_id, item.order_id, item.amount)}>
                                    View
                                </button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        )
    }

    return(
        <div className="main-panel">
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Profile" subTitle="My Profile" />
                {error !== "" ? <h3 className="errorMsg text-center"> {error} </h3> : null}
                {currentUser !== null ? getUser() : null}  
                {loading ? <div className="loader-div"><ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /></div> : null} 
                {imgLoader ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : null} 
                <div className="row">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            {currentUser !== null ? getTable() : null}
                        </div>
                    </div>
                </div> 
            </div>
            {showModal ? getModal() : null}
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentUser: state.profileReducer.currentUser,
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    imgError: state.helperReducer.imgError,
    imgSuccessMsg: state.helperReducer.imgSuccessMsg,
    Profilelist: state.profileReducer.Profilelist,
    imgLoader: state.helperReducer.imgLoader
})

const mapDispatchToProps = {
    getUserProfile,
    uploadImage,
    getProfiles
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)