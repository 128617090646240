import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import { getAssigneeList, getAllUsers } from '../../../actions/ProfileActions'
import ReactLoading from "react-loading"
import _ from 'lodash'
import Pagination from 'react-pagination-bootstrap'
import {itemsCountPerPage, pageRangeDisplayed, pageSize} from '../../../constants/HelperConstants'
import { usePrevious } from '../library/CommonMethods'

const TraineeUsers = (props) => {
    let pathname = props.location.pathname
    const { getAssigneeList, loading, error, assigneeList, getAllUsers, userList, count } = props
    const prevList = usePrevious(assigneeList)
    const [activePage, setActivePage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getAssigneeList(activePage)
                getAllUsers()
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getAssigneeList, getAllUsers, activePage])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        setShowLoader(false)
    }

    const getTable = () => {
        return(
            <table id="recent-purchases-listing" className="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Total Profiles</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                {assigneeList !== undefined ? assigneeList.length !== 0 ? getTableBody() : <tbody> No Assignee Available </tbody> :null}
            </table>
        )
    }

    const viewReportBtn = (id) => {
        localStorage.setItem('report_paramter', "traineeUsers")
        localStorage.setItem('report_user_id', id)
    }

    const getTableBody = () => {
        let newArray = []
        _.forEach(assigneeList, function(list){
            _.forEach(userList, function(newList){
                if(list.id === newList.id){
                    newArray.push({ 'user_details': newList, 'invitations': list})
                }
            })
        })
        if(prevList !== assigneeList){
            return(
                <tbody>
                    {newArray.map((item, index) => {
                        let uid = localStorage.getItem('uid')
                        let totalProfiles = _.filter(item.invitations.invited_plan, ['invited_by', uid])
                        let checkAssessmentStatus = _.filter(totalProfiles, ['assessment_status', 0])
                        let checkAssessmentStatus1 = _.filter(totalProfiles, ['assessment_status', 1])
                        let serialNo = ((activePage - 1) * pageSize) + 1
                        return(
                            <tr key={index}>
                                <td>{serialNo + index}</td>
                                <td>{_.isEmpty(_.trim(item.user_details.name)) ? '--' : item.user_details.name}</td>
                                <td>{item.user_details.email}</td>
                                <td>{totalProfiles.length}</td>
                                <td>{checkAssessmentStatus.length === 0 && checkAssessmentStatus1.length === 0 ? <label className="badge badge-success">Done</label> : <label className="badge badge-primary">Pending</label>}</td>
                                <td>
                                    <a href="/viewReport" onClick={() => viewReportBtn(item.user_details.id)}>
                                        <button type="button" className="btn btn-dark btn-icon-text btn-sm">
                                            <i className="mdi mdi-eye btn-icon-prepend"></i>
                                            All Reports
                                        </button> &nbsp;&nbsp;
                                    </a>
                                    <a href={`/traineeuserDetails/${item.user_details.id}`}>
                                        <button type="button" className="btn btn-primary btn-icon-text btn-sm">
                                            <i className="mdi mdi-eye btn-icon-prepend"></i>
                                            View Assignee
                                        </button>
                                    </a>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            )
        }
        else{
            return <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} />
        }
    }
    
    return(
        <div className="main-panel">
            {loading && showLoader ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> :
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Assignee" subTitle="List" />
                {error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
                <div className="row">
                    <div className="col-md-12 stretch-card">
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-md-9">
                                    <h4 className="card-title pt-2">Assignee</h4>
                                </div>
                                {/* <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" 
                                                   className="form-control p-3 text-left" 
                                                   placeholder="Search" 
                                                   aria-label="Recipient's username"
                                                   value={searchTerm}
                                                   onChange={handleChange} />
                                            <i className="mdi mdi-magnify search-btn"></i>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="table-responsive">
                                    {getTable()}
                                </div>
                                <div>
                                    {error === "" && count.length > pageSize ? 
                                    <Pagination activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={count.length}
                                                pageRangeDisplayed={pageRangeDisplayed}
                                                onChange={handlePageChange} />
                                    : error}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>}
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    assigneeList: state.profileReducer.assigneeList,
    userList: state.profileReducer.userList,
    count: state.profileReducer.count
})

const mapDispatchToProps = {
    getAssigneeList,
    getAllUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(TraineeUsers)