import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import './ViewTest.scss'
import { getProfiles, getAllUsers, getAssigneeList, getProfileInvitation, getUserResult, getGeneralNotes, getGuidelines } from '../../../actions/ProfileActions'
import ReactLoading from "react-loading"
import _ from 'lodash'
import GraphContainer from './GraphContainer'
import VennGraph from './VennGraph'
import ReactHtmlParser from 'react-html-parser'
import $ from 'jquery'
import { getSeriesData } from '../library/CommonMethods'
import ReactToPrint from "react-to-print"
import { convertDateTime } from '../date/convertDate'

class ComponentToPrint extends React.Component {
    render() {
        const { finalResultList, show, renderReport, renderGuideLines, renderGeneralNotes, userName, profileName, profileDateTime, profileType, renderNewGeneralNotes } = this.props
        const objEmpty = Object.keys(finalResultList).length === 0
        let profile_date = convertDateTime(profileDateTime)
        if(finalResultList !== undefined && !objEmpty && show){
            return(
                <div>
                    <h3 className='text-center pt-3'>{userName + ' ' + profileName + ' ' + 'Results'}</h3>
                    <h4 className='text-center pt-3'> on {profile_date} </h4>
                    {renderReport()}
                    <div className="row">
                        {profileType === "LSP"? 
                        <div className="col-12 stretch-card">
                            <div className="col-md-12 print-guideline-note-section">
                                {renderNewGeneralNotes()}  
                            </div>
                        </div>
                        : <div className="col-12 stretch-card">
                            <div className="col-md-6 print-guideline-note-section">  
                                {renderGuideLines()}
                            </div>
                            <div className="col-md-6 print-guideline-note-section">  
                                {renderGeneralNotes()}
                            </div>
                        </div>}
                    </div>
                </div>
            )
        }
        else{
            return null
        }
    }
}

const ViewReport = (props) => {
    let pathname = props.location.pathname
    const [Value, setValue] = useState('')
    const [profileType, setProfileType] = useState('')
    const { getAssigneeList, loading, error, assigneeList, userList, getAllUsers, getProfiles, Profilelist, getProfileInvitation, invitationList, getUserResult, resultList, getGeneralNotes, getGuidelines, generalNotes, guidelines } = props
    let uid = localStorage.getItem('uid')
    let parameter = localStorage.getItem('report_paramter')
    let report_user_id = localStorage.getItem('report_user_id')
    const [finalResultList, setFinalResultList] = useState('')
    const [show, setShow] = useState(false)
    const [Profile, setProfile] = useState('')
    const [userName, setUserName] = useState('')
    const [profileName, setProfileName] = useState('')
    const [profileDateTime, setProfileDateTime] = useState('')
    const componentRef = useRef()

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getAssigneeList()
                getAllUsers()
                getProfiles()
                if(report_user_id !== null){
                    getProfileInvitation(report_user_id)
                }
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getAssigneeList, getAllUsers, getProfiles, getProfileInvitation, report_user_id])

    useEffect(() => {
        $(document).ready(function() {

            // Save all selects' id in an array 
            // to determine which select's option and value would be changed
            // after you select an option in another select.
            var selectors = ['exampleFormControlSelect1', 'exampleFormControlSelect2']
          
            $('Select').on('change', function() {
              var index = selectors.indexOf(this.id)
            //   var value = this.value
          
              // check if is the last one or not
              if (index < selectors.length - 1) {
                var next = $('#' + selectors[index + 1])
          
                // Show all the options in next select
                $(next).find('option').show()
                // if (value != "") {
                //   // if this select's value is not empty
                //   // hide some of the options 
                //   $(next).find('option[data-value!=' + value + ']').hide()
                // }
                
                // set next select's value to be the first option's value 
                // and trigger change()
                $(next).val($(next).find("option:first").val()).change()
              }
            })
        })
        const timer = setTimeout(() => {
            if(Value !== 'Select' && Profile !== "Select"){
                setShow(true)
                if(!_.isEmpty(resultList)){
                    const { data } = resultList
                    setProfileDateTime(data.created_date_time)
                }
                setFinalResultList(resultList)
            }
            else{
                setFinalResultList('')
                setShow(false)
            }
        }, 1000)
        return () => clearTimeout(timer)
    })

    const changeAssignee = (event) => {
        const { value } = event.target
        setValue(value)
        _.forEach(userList, function(list){
            if(list.id === value){
                if(_.isEmpty(_.trim(list.name))){
                    setUserName(`${list.email}'s`)
                }
                else{
                    let newName = `${_.capitalize(_.trim(list.name))}'s`
                    setUserName(newName)
                }
            }
        })
        if(value !== 'Select' && value !== Value){
            setProfile('Select')
            setFinalResultList('')
            getProfileInvitation(value)
        }
        else if(value !== 'Select'){
            getProfileInvitation(value)
        }
        else{
            setProfile('Select')
        }
    }

    const changeProfile = (event) => {
        const { value } = event.target
        var nameSplit = value.split('__')
        setProfile(nameSplit[0])
        _.forEach(Profilelist, function(list){
            if(list.id === nameSplit[0]){
                setProfileName(list.profile_name)
            }
        })
        setFinalResultList('')
        localStorage.setItem('report_invited_by_id', nameSplit[1])
        if(parameter === "sidebar"){
            getUserResult(nameSplit[0], Value)
        }
        else{
            getUserResult(nameSplit[0], report_user_id)
        }
        let profileTypee
        if(nameSplit[0] !== 'Select'){
            Profilelist.map(list => {
                if(list.id === nameSplit[0]){
                    profileTypee = list.profile_type
                    setProfileType(list.profile_type)
                }
            })
            getGuidelines(profileTypee)
            getGeneralNotes(profileTypee)
        }
    }

    const renderUserReport = () => {
        let newArray = [], newList = [], uniqProfile
        _.forEach(assigneeList, function(list){
            _.forEach(userList, function(newList){
                if(list.id === newList.id){
                    newArray.push({ 'user_details': newList, 'invitations': list})
                }
            })
        })
        _.forEach(userList, function(list){
            if(list.purchase_plan !== undefined){
                if(list.id === uid){
                    newArray.push({ 'user_details': list })
                }
            }
            else{
                if(list.id === uid){
                    newArray.push({ 'user_details': list })
                }
            }
        })
        if(Value !== "Select"){
            if(invitationList !== undefined || !_.isEmpty(invitationList)){
                _.forEach(Profilelist, function(list){
                    _.forEach(invitationList, function(itemss){
                        _.forEach(itemss.invited_plan, function(item){
                            if(item.profile_id === list.id){
                                newList.push({ 'profile_id': list.id, 'profile_name': list.profile_name, 'invited_by': item.invited_by })
                            }
                        })
                    })
                })
            }
        }
        if(Value !== "Select"){
            if(Value === uid){
                const currentUser = _.find(userList, ['id', uid])
                _.forEach(currentUser.purchase_plan, function(plan){
                    _.forEach(Profilelist, function(list){
                        if(invitationList !== undefined || !_.isEmpty(invitationList)){
                            _.forEach(invitationList, function(item){
                                _.forEach(item.invited_plan, function(items){
                                    if(plan.profile_id === list.id && items.profile_id === list.id){
                                        newList.push({ 'profile_id': list.id, 'profile_name': list.profile_name, 'invited_by': items.invited_by, 'user_id': uid })
                                    }
                                })
                            })
                        }
                        else{
                            if(plan.profile_id === list.id){
                                newList.push({ 'profile_id': list.id, 'profile_name': list.profile_name })
                            }
                        }
                    })
                })
            }
        }
        let type = localStorage.getItem('type')
        if(type === "trainer"){
            let checkLoggedIn = _.filter(newList, ['user_id', uid])
            if(checkLoggedIn.length === 0){
                uniqProfile = _.filter(newList, ['invited_by', uid])
            }
            else{
                uniqProfile = _.uniqBy(newList, 'profile_id')
            }
        }
        else{
            uniqProfile = newList
        }
        // const uniqList = _.uniqBy(newList, 'profile_id')
        const objEmpty = Object.keys(finalResultList).length === 0
        return(
            <div className="row"> 
		        <div className="col-12 grid-margin mb-2 stretch-card">
                    <div className="card">
                        <div className="card-body pb-1 userReport">
                            <h4 className="card-title">User Report</h4>
                            <form className="forms-sample d-flex">
                                <div className="col-md-5">
                    	            <div className="form-group">
                                        <label htmlFor="">Select Assignee</label>
                                        <select className="form-control" id="exampleFormControlSelect1" onChange={changeAssignee}>
                                            <option value="Select" selected>
                                                Select Assignee
                                            </option>
                                            {newArray.map(list => {
                                                return(
                                                    <option key={list.user_details.id} value={list.user_details.id}>
                                                        {_.isEmpty(_.trim(list.user_details.name)) ? list.user_details.email : list.user_details.name} 
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>	
                                <div className="col-md-5">
                     	            <div className="form-group">
                                        <label htmlFor="">Select Profile</label>
                                        <select className="form-control" id="exampleFormControlSelect2" onChange={changeProfile}>
                                            <option value="Select" selected>
                                                Select Profile
                                            </option>
                                            {uniqProfile.map((list, index) => {
                                                return(
                                                    <option key={index} value={list.profile_id + '__' + list.invited_by}>
                                                        {list.profile_name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>	
                            </form>
                            {finalResultList !== undefined && !objEmpty && show ? 
                            <div className="print-report-btn">
                                <ReactToPrint
                                    trigger={() => <img style={{ cursor: 'pointer' }} src="../../images/print-icons.png" />}
                                    content={() => componentRef.current}
                                />
                                <div style={{ display: "none" }}><ComponentToPrint ref={componentRef} finalResultList={finalResultList} show={show} renderReport={renderReport} renderGuideLines={renderGuideLines} renderGeneralNotes={renderGeneralNotes} userName={userName} profileName={profileName} profileDateTime={profileDateTime} profileType={profileType} renderNewGeneralNotes={renderNewGeneralNotes} /></div>
                            </div>
                            : null}
                        </div>
                    </div>
                </div>
	        </div>
        )
    }

    const renderCurrentUserReport = () => {
        let newArray = [], newList = []
        let user_id = localStorage.getItem('report_user_id')
        let userName
        _.forEach(userList, function(list){
            if(list.id === user_id){
                newArray.push({ 'user_details': list })
                if(_.isEmpty(_.trim(list.name))){
                    userName = `${list.email}'s`
                }
                else{
                    userName = `${_.capitalize(_.trim(list.name))}'s`
                }
            }
        })
        if(invitationList !== undefined || !_.isEmpty(invitationList)){
            _.forEach(Profilelist, function(list){
                _.forEach(invitationList, function(itemss){
                    _.forEach(itemss.invited_plan, function(item){
                        if(item.profile_id === list.id){
                            newList.push({ 'profile_id': list.id, 'profile_name': list.profile_name, 'invited_by': item.invited_by })
                        }
                    })
                })
            })
        }
        const matchIdList = _.filter(newList, ['invited_by', uid])
        const newUniqList = _.uniqBy(matchIdList, 'profile_id')
        const objEmpty = Object.keys(finalResultList).length === 0
        return(
            <div className="row"> 
		        <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body userReport">
                            <h4 className="card-title">User Report</h4>
                            <form className="forms-sample d-flex">
                                <div className="col-md-5">
                    	            <div className="form-group">
                                        <label htmlFor="">Select Assignee</label>
                                        <select className="form-control" id="exampleFormControlSelect1">
                                            {newArray.map(list => {
                                                return(
                                                    <option key={list.user_details.id} value={list.user_details.id}>
                                                        {_.isEmpty(_.trim(list.user_details.name)) ? list.user_details.email : list.user_details.name} 
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>	
                                <div className="col-md-5">
                     	            <div className="form-group">
                                        <label htmlFor="">Select Profile</label>
                                        <select className="form-control" id="exampleFormControlSelect2" onChange={changeProfile}>
                                            <option value="Select" selected>
                                                Select Profile
                                            </option>
                                            {newUniqList.map((list, index) => {
                                                return(
                                                    <option key={index} value={list.profile_id + '__' + uid}>
                                                        {list.profile_name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>	
                            </form>
                            {finalResultList !== undefined && !objEmpty ? 
                            <div className="print-report-btn">
                                <ReactToPrint
                                    trigger={() => <img style={{ cursor: 'pointer' }} src="../../images/print-icons.png" />}
                                    content={() => componentRef.current}
                                />
                                <div style={{ display: "none" }}><ComponentToPrint ref={componentRef} finalResultList={finalResultList} show={show} renderReport={renderReport} renderGuideLines={renderGuideLines} renderGeneralNotes={renderGeneralNotes} userName={userName} profileName={profileName} profileDateTime={profileDateTime} profileType={profileType} renderNewGeneralNotes={renderNewGeneralNotes} /></div>
                            </div>
                            : null}
                        </div>
                    </div>
                </div>
	        </div>
        )
    }

    const renderReport = () => {
        if(finalResultList === undefined){
            return(
                <div className="row"> 
                    <div className="col-md-12 stretch-card">
                        <div className="card">
                            <div className="card-body row">
                                <h4> No Report Available </h4>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        const objEmpty = Object.keys(finalResultList).length === 0
        let resultArray = [], seriesData
        if(finalResultList !== undefined && !objEmpty && show){
            Object.entries(finalResultList.data.total_scores).map(([key, value]) => {
                let index
                if(_.startsWith(key, "Controlling")){
                    index = 0
                }
                else if(_.startsWith(key, "Training")){
                    index = 1
                }
                else if(_.startsWith(key, "Supporting")){
                    index = 2
                }
                else if(_.startsWith(key, "Entrusting")){
                    index = 3
                }
                resultArray.push({ 'label': key, 'y': value, 'Indexx': index })
            })
        }
        let newResultArray = _.orderBy(resultArray, ['Indexx'], ['asc'])
        if(finalResultList !== undefined && !objEmpty && show){
            seriesData = getSeriesData(resultArray)
        }
        return(
            <div className="row">
                {!objEmpty ?
                    <div className="col-md-12 stretch-card user-report-graph">
						<div className="col-md-6">										
							<div className="graph">		 
                                {profileType === "LSP" ? 
                                <GraphContainer title="LSP QUESTIONNAIRE" resultArray={newResultArray} />:
                                <VennGraph title="PLP QUESTIONNAIRE" seriesData={seriesData} />}
                                <div className="profile-graph"></div>
                            </div>
						</div>
						<div className="col-md-6">
							<div className="card">
								<div className="card-body">												 
									<div className="table-responsive">
										<table className="table table-striped table-bordered">
											<tbody>
                                                {finalResultList !== undefined ? 
                                                newResultArray.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td className="font-weight-bold"> {item.label} </td>	
                                                            <td> {item.y} </td>
                                                        </tr>
                                                    )
                                                })
                                                : null}
											</tbody>
										</table>
									</div>
								</div>
							</div>			 
						</div>										
					</div>
                : <div className="card-body row"><h4>No Report Available</h4></div>}
            </div>
        )
    }

    const getbalancedTable = () => {
        return (
            <div class="table-responsive pt-3">
                <table className="table table-bordered mt-3 mb-3 balanced-table">
                    <tr>
                        <td colSpan="2" className="font-weight-bold">KEY FUNCTIONS</td>
                        <td className="font-weight-bold">TASK</td>
                        <td className="font-weight-bold">TEAM</td>
                        <td className="font-weight-bold">INDIVIDUAL</td>
                    </tr>
                    <tr>
                        <td rowSpan="5"><p className="rotate-text"> COMMUNICATION</p></td>
                        <td>
                            DEFINE<br />OBJECTIVES
                        </td>
                        <td>
                            Clarify Task<br />Obtain Information<br />Identify resources<br /> and constraints
                                        </td>
                        <td>
                            Assemble team<br />Give reasons why<br />Define accountablity
                                        </td>
                        <td>
                            Involve each<br />person<br />Gain acceptance
                                        </td>
                    </tr>
                    <tr>
                        <td>
                            PLAN<br />DECIDE
                                        </td>
                        <td>
                            Consider options<br />Establish priorities<br />Plan time
                                        </td>
                        <td>
                            Consult<br />Encourage ideas<br />Agree standards
                                        </td>
                        <td>
                            Listen<br />Assess abilities<br />Delegate<br />Agree targets
                                        </td>
                    </tr>
                    <tr>
                        <td>ORGANISE</td>
                        <td>
                            Establish control<br />Brief Plan<br />Obtain feedback
                                        </td>
                        <td>
                            Structure<br />Answer questions<br />Prepare and train
                                        </td>
                        <td>
                            Check<br />understanding<br />Counsel<br />Enthuse
                                        </td>
                    </tr>
                    <tr>
                        <td>
                            CONTROL<br />SUPPORT
                                        </td>
                        <td>
                            Maintain standards<br />Report progress<br />Adjust plan if<br />necessary<br />Set personal<br />example
                                        </td>
                        <td>
                            Co-ordinate<br />Maintain external<br />co-operation<br />Relieve tension
                                        </td>
                        <td>
                            Guide and<br />encourage<br />Recognise effort<br />Discipline
                                        </td>
                    </tr>
                    <tr>
                        <td>REVIEW</td>
                        <td>
                            Evaluate results<br />against objectives<br />Consider future<br />action
                                        </td>
                        <td>
                            Recognise team’s<br />success<br />Learn from setbacks
                                        </td>
                        <td>
                            Appraise<br />performance<br />Identify further<br />training needs<br />Aid personal growth
                                        </td>
                    </tr>
                </table>
            </div>
        )
    }

    const getGraph = () => {
        return(
            <div className="add-scroll pt-3 communication-table"> 
                <table className="table table-bordered mt-1 mb-2 maintable">                 
                    <tr>
                        <td align="center" className="border-remove">Controlling / Organising</td>
                        <td align="center" className="border-remove">Training / Coaching</td>
                        <td align="center" className="border-remove">Supporting / Empathetic</td>
                        <td align="center" className="border-remove" >Entrusting / Empowering</td>
                    </tr>
                    <tr>
                        <td className="td-width">
                            <p className="text-left"> 
                                <i style={{ fontSize: '18px' }}> Authority of leader </i>  <br />
                                <span className="font-weight-bold"> Controlling Behaviour </span> 
                                involves telling people what to do, how to do it, when to do it and then closely monitoring their performance.
                            </p>
                        </td>   
                        <td> </td>
                        <td className="td-width">
                           <p className="table-right-col">  
                                <span className="end-heading"> Freedom of team members </span><br />
                                <span className="font-weight-bold"> Supportive Behaviour </span> 
                                involves listening to people, providing support, encouraging their efforts and facilitating their involvement in problem solving and decision making.
                            </p>
                        </td>
                        <td> </td>
                    </tr>
                    <div className="incline-line"></div>
                </table>     
            </div> 
        )
    }

    const getPdfGraph = () => {
        return(
            <div className="pt-3 communication-table"> 
                <table className="mt-1 mb-2 pdf-table"> 
                    <tr>
                        <td align="center" className="border-remove">Controlling / Organising</td>
                        <td align="center" className="border-remove">Training / Coaching</td>
                        <td align="center" className="border-remove">Supporting / Empathetic</td>
                        <td align="center" className="border-remove" >Entrusting / Empowering</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black' }}>
                            <p className="pdf-left-col-width"> <i style={{ fontSize: '18px' }}> Authority of leader </i>  <br />
                                <span className="font-weight-bold"> Controlling Behaviour </span> 
                                involves telling people what to do, how to do it, when to do it and then closely monitoring their performance.
                            </p>
                        </td> 
                        <td style={{ border: '1px solid black' }}></td>
                        <td style={{ border: '1px solid black' }}>
                            <p className="pdf-right-col-width">  
                                <span className="end-heading end-pdf-heading"> Freedom of team members </span><br />
                                <span className="font-weight-bold"> Supportive Behaviour </span> 
                                involves listening to people, providing support, encouraging their efforts and facilitating their involvement in problem solving and decision making.
                            </p>
                        </td>
                        <td style={{ border: '1px solid black' }}></td>
                    </tr>
                    <div className="pdf-line"></div>
                </table>     
            </div>  
        )
    }

    const renderGuideLines = () => {
        let  PlpGuideLines, LspGuidelines = {}, resultArray = [], newArray, balancedList = [], checkBalanced = false
        const objEmpty = Object.keys(finalResultList).length === 0
        if(finalResultList !== undefined && !objEmpty){
            Object.entries(finalResultList.data.total_scores).map(([key, value]) => {
                resultArray.push({ 'label': key, 'y': value })
            })
        }
        if(!objEmpty){
            var sortedArray = resultArray.sort((a, b) => parseFloat(b.y) - parseFloat(a.y))
            _.forEach(sortedArray, function(item){
                if(item.label !== "non-management"){
                    if(_.isEmpty(newArray)){
                        newArray = item.label
                        balancedList.push(item.y)
                    }
                    else{
                        newArray = newArray + '_' + item.label
                        balancedList.push(item.y)
                    }
                }
            })
            if(balancedList.length !== 0){
                _.forEach(balancedList, function(listItem){
                    var firstValue = balancedList[0]
                    if(firstValue === listItem){
                        checkBalanced = true
                    }
                    else{
                        checkBalanced = false
                    }
                })
            }
            if(checkBalanced){
                newArray = "balanced"
            }
            if(!_.isEmpty(guidelines)){
                const { data } = guidelines
                if(profileType === "PLP"){
                    Object.entries(data.guidelines).map(([key, value]) => {
                        if(_.lowerCase(key) === _.lowerCase(newArray)){
                            PlpGuideLines = value
                        }
                    })
                }
                else{
                    LspGuidelines = data.guidelines
                }
            }
        }
        var newData = []
        Object.entries(LspGuidelines).map(([key, value]) => {
            if(_.startsWith(key, "Controlling")){
                newData.push({ [key]: value, 'Indexx': 0 })
            }
            else if(_.startsWith(key, "Training")){
                newData.push({ [key]: value, 'Indexx': 1 })
            }
            else if(_.startsWith(key, "Supporting")){
                newData.push({ [key]: value, 'Indexx': 2 })
            }
            else if(_.startsWith(key, "Entrusting")){
                newData.push({ [key]: value, 'Indexx': 3 })
            }
        })
        let newResultArray = _.orderBy(newData, ['Indexx'], ['asc'])
        return(
            !objEmpty ? 
				<div className="card">
					<div className="card-body">
                        <h3>Guidelines for interpreting your scores</h3>
						<h4> {profileType === "PLP" ? "Action Centred Leadership Action Plan" : null} </h4>
                        {profileType === "LSP" ? <br /> : null}
                        {profileType === "LSP" ? 
                        !_.isEmpty(guidelines) ? <div>{guidelines.data.graph_heading}</div> : null
                        : null}
                        {profileType === "LSP" ? getGraph() : null}
                        {profileType === "LSP" ? <br /> : null}
                        {profileType === "LSP" ? <div>
                            <b>The controlling and supportive behaviours can involve different styles of implementation.</b>
                        </div> : null}
                        <br />
                        {profileType === "PLP" ? 
                        <div dangerouslySetInnerHTML={{ __html: PlpGuideLines}}></div>
                        : <div>
                            {newResultArray.map((item, index) => {
                                return Object.entries(item).map(([key, value]) => {
                                    if(key === "Indexx"){return null}
                                    else{
                                        return(
                                            <div key={index}>
                                                <div> <b>- {key}</b></div>
                                                <br />
                                                <div>{ReactHtmlParser(value)}</div>             
                                            </div>
                                        )
                                    }
                                })
                            })}
                        </div>}
                        {profileType === "PLP" && newArray === "balanced" ? 
                            getbalancedTable()
                        : null}
                        {profileType === "LSP" ? <div>
                            <b>
                                Effective leaders are adaptable in being able to move from one style to another according to the
                                situation they are in. There is no one right style. This profile helps identify the leader’s preferred style.
                            </b>
                        </div> : null}
					</div>
				</div>	
            : null
        )
    }

    const renderGeneralNotes = () => {
        const objEmpty = Object.keys(finalResultList).length === 0
        let GeneralNotes
        if(!_.isEmpty(generalNotes)){
            GeneralNotes = generalNotes.data.notes
        }
        let resultArray = []
        if(finalResultList !== undefined && !objEmpty){
            Object.entries(finalResultList.data.total_scores).map(([key, value]) => {
                let index
                if(_.startsWith(key, "Controlling")){
                    index = 0
                }
                else if(_.startsWith(key, "Training")){
                    index = 1
                }
                else if(_.startsWith(key, "Supporting")){
                    index = 2
                }
                else if(_.startsWith(key, "Entrusting")){
                    index = 3
                }
                resultArray.push({ 'label': key, 'y': value, 'Indexx': index })
            })
        }
        let newResultArray = _.orderBy(resultArray, ['Indexx'], ['asc'])
        return(
            !objEmpty ?
				<div className="card">
					<div className="card-body">
						<h3>{profileType === "PLP" ? "General Notes" : "Leadership Style Analysis"} </h3>
                        {profileType === "PLP" ? <h4>The Functional Leadership Model</h4> : null}									
						{profileType === "LSP" ? <h4>Analysis of your results:</h4> : null}
                        {profileType === "LSP" ? 
                        <div>
                            {newResultArray.map((item, index) => {
                                let value, Color
                                if(item.y <= 22.5){
                                    value = "Under Emphasis"
                                    Color ="blue"
                                }
                                else if(item.y > 22.5 && item.y < 32.5){
                                    value = "Accepted"
                                    Color ="green"         
                                }
                                else{
                                    value = "Over Emphasis"
                                    Color = "red" 
                                }
                                return(
                                    <div key={index}>
                                        {item.label} : <span style={{ color:Color }}>{value}</span>
                                    </div>
                                )
                            })}
                        </div> : null}
                        <br />
                        {!_.isEmpty(generalNotes) ?  <div>{ReactHtmlParser(GeneralNotes)}</div> : null}
                    </div>
				</div>	
            : null
        )
    }

    const renderNewGeneralNotes = () => {
        const objEmpty = Object.keys(finalResultList).length === 0
        let GeneralNotes
        if(!_.isEmpty(generalNotes)){
            GeneralNotes = generalNotes.data.notes
        }
        let resultArray = []
        if(finalResultList !== undefined && !objEmpty){
            Object.entries(finalResultList.data.total_scores).map(([key, value]) => {
                let index
                if(_.startsWith(key, "Controlling")){
                    index = 0
                }
                else if(_.startsWith(key, "Training")){
                    index = 1
                }
                else if(_.startsWith(key, "Supporting")){
                    index = 2
                }
                else if(_.startsWith(key, "Entrusting")){
                    index = 3
                }
                resultArray.push({ 'label': key, 'y': value, 'Indexx': index })
            })
        }
        let newResultArray = _.orderBy(resultArray, ['Indexx'], ['asc'])
        return(
            !objEmpty ?
				<div className="card" style = {{ border: '0', marginLeft: '8px', marginRight: '8px'}}>
					<h3>{profileType === "PLP" ? "General Notes" : "Leadership Style Analysis"} </h3>
                    {profileType === "PLP" ? <h4>The Functional Leadership Model</h4> : null}									
					{profileType === "LSP" ? <h4>Analysis of your results:</h4> : null}
                    {profileType === "LSP" ? 
                    <div>
                        {newResultArray.map((item, index) => {
                            let value, Color
                            if(item.y <= 22.5){
                                value = "Under Emphasis"
                                Color ="blue"
                            }
                            else if(item.y > 22.5 && item.y < 32.5){
                                value = "Accepted"
                                Color ="green"         
                            }
                            else{
                                value = "Over Emphasis"
                                Color = "red" 
                            }
                            return(
                                <div key={index}>
                                    {item.label} : <span style={{ color:Color }}>{value}</span>
                                </div>
                            )
                        })}
                    </div> : null}
                    <br />
                    {!_.isEmpty(generalNotes) ?  <div>{ReactHtmlParser(GeneralNotes)}</div> : null}
                    <br />
                    {renderNewGuideLines()}
				</div>	
            : null
        )
    }

    const renderNewGuideLines = () => {
        let  PlpGuideLines, LspGuidelines = {}, resultArray = [], newArray, balancedList = [], checkBalanced = false
        const objEmpty = Object.keys(finalResultList).length === 0
        if(finalResultList !== undefined && !objEmpty){
            Object.entries(finalResultList.data.total_scores).map(([key, value]) => {
                resultArray.push({ 'label': key, 'y': value })
            })
        }
        if(!objEmpty){
            var sortedArray = resultArray.sort((a, b) => parseFloat(b.y) - parseFloat(a.y))
            _.forEach(sortedArray, function(item){
                if(item.label !== "non-management"){
                    if(_.isEmpty(newArray)){
                        newArray = item.label
                        balancedList.push(item.y)
                    }
                    else{
                        newArray = newArray + '_' + item.label
                        balancedList.push(item.y)
                    }
                }
            })
            if(balancedList.length !== 0){
                _.forEach(balancedList, function(listItem){
                    var firstValue = balancedList[0]
                    if(firstValue === listItem){
                        checkBalanced = true
                    }
                    else{
                        checkBalanced = false
                    }
                })
            }
            if(checkBalanced){
                newArray = "balanced"
            }
            if(!_.isEmpty(guidelines)){
                const { data } = guidelines
                if(profileType === "PLP"){
                    Object.entries(data.guidelines).map(([key, value]) => {
                        if(_.lowerCase(key) === _.lowerCase(newArray)){
                            PlpGuideLines = value
                        }
                    })
                }
                else{
                    LspGuidelines = data.guidelines
                }
            }
        }
        var newData = []
        Object.entries(LspGuidelines).map(([key, value]) => {
            if(_.startsWith(key, "Controlling")){
                newData.push({ [key]: value, 'Indexx': 0 })
            }
            else if(_.startsWith(key, "Training")){
                newData.push({ [key]: value, 'Indexx': 1 })
            }
            else if(_.startsWith(key, "Supporting")){
                newData.push({ [key]: value, 'Indexx': 2 })
            }
            else if(_.startsWith(key, "Entrusting")){
                newData.push({ [key]: value, 'Indexx': 3 })
            }
        })
        let newResultArray = _.orderBy(newData, ['Indexx'], ['asc'])
        return(
            !objEmpty ? 
				<div className="card" style={{ border: '0'}}>
                    <h3 style={{ marginBottom: '0'}}>Guidelines for interpreting your scores</h3>
                    <h4> {profileType === "PLP" ? "Action Centred Leadership Action Plan" : null} </h4>
                    {profileType === "LSP" ? <br /> : null}
                    {profileType === "LSP" ? 
                    !_.isEmpty(guidelines) ? <div>{guidelines.data.graph_heading}</div> : null
                    : null}
                    {profileType === "LSP" ? getPdfGraph() : null}
                    {profileType === "LSP" ? <br /> : null}
                    {profileType === "LSP" ? <div>
                        <b className="plp-heading">The controlling and supportive behaviours can involve different styles of implementation.</b>
                    </div> : null}
                    <br />
                    {profileType === "PLP" ? 
                    <div dangerouslySetInnerHTML={{ __html: PlpGuideLines}}></div>
                    : <div>
                        {newResultArray.map((item, index) => {
                            return Object.entries(item).map(([key, value]) => {
                                if(key === "Indexx"){return null}
                                else{
                                    return(
                                        <div key={index}>
                                            <div style ={{ fontSize: '1rem', marginBottom: '8px', marginTop: '8px', fontWeight: 'bold' }}> 
                                                <b>- {key}</b>
                                            </div>
                                            <div>{ReactHtmlParser(value)}</div>             
                                        </div>
                                    )
                                }
                            })
                        })}
                    </div>}
                    {profileType === "PLP" && newArray === "balanced" ? 
                        getbalancedTable()
                    : null}
                    {profileType === "LSP" ? <div>
                        <b className="plp-heading">
                            Effective leaders are adaptable in being able to move from one style to another according to the
                            situation they are in. There is no one right style. This profile helps identify the leader’s preferred style.
                        </b>
                    </div> : null}
				</div>	
            : null
        )
    }

    return(
        <div className="main-panel">
            {loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : 
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Assessment Library" subTitle="View Report" />
                {error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
                {parameter === "sidebar" ? renderUserReport() : renderCurrentUserReport()}
                {renderReport()}
                <div className="row mt-4">
                    {finalResultList !== undefined ? <div className="col-md-6 stretch-card guideline-note-section">{renderGuideLines()}</div> : null}
                    {finalResultList !== undefined ? <div className="col-md-6 stretch-card guideline-note-section">{renderGeneralNotes()}</div> : null}
                </div>
            </div>}
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    assigneeList: state.profileReducer.assigneeList,
    userList: state.profileReducer.userList,
    Profilelist: state.profileReducer.Profilelist,
    invitationList: state.profileReducer.invitationList,
    resultList: state.profileReducer.resultList,
    guidelines: state.profileReducer.guidelines,
    generalNotes: state.profileReducer.generalNotes
})

const mapDispatchToProps = {
    getAssigneeList,
    getAllUsers,
    getProfiles,
    getProfileInvitation,
    getUserResult,
    getGeneralNotes, 
    getGuidelines
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewReport)