import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import { getProfiles, getAllUsers, getProfileInvitation, getCurrentUserResult } from '../../../actions/ProfileActions'
import ReactLoading from "react-loading"
import _ from 'lodash'
import { convertDate } from '../date/convertDate'
import Pagination from 'react-pagination-bootstrap'
import {itemsCountPerPage, pageRangeDisplayed, pageSize} from '../../../constants/HelperConstants'
import { usePrevious } from '../library/CommonMethods'

const ProfileAssigned = (props) => {
    let pathname = props.location.pathname
    const { getProfileInvitation, loading, error, invitationList, getAllUsers, userList, getProfiles, Profilelist, getCurrentUserResult, currentResult, count, assessmentMsg } = props
    const prevList = usePrevious(invitationList)
    let uid = localStorage.getItem('uid')
    const [activePage, setActivePage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getProfiles()
                getProfileInvitation(uid, activePage)
                getAllUsers()
                getCurrentUserResult(uid)
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getProfileInvitation, getAllUsers, getProfiles, uid, getCurrentUserResult, activePage])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        setShowLoader(false)
    }

    const getTable = () => {
        return(
            <table id="recent-purchases-listing" className="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Profile Name</th>
                        <th>Invited By</th>
                        <th>Invitation Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                {invitationList !== undefined ? invitationList.length !== 0 ? getTableBody() : <tbody> No Assigned Profile Available </tbody> : null} 
            </table>
        )
    }

    const handleClick = (name, profileType, profileAssigned, invitedBy) => {
        localStorage.setItem('profile_invited_by', invitedBy)
        localStorage.setItem('current_profile_name', name)
        localStorage.setItem('TestProfileType', profileType)
        localStorage.setItem('purchased_profile', profileAssigned)
    }
    
    const setName = (name, type, id, userName, invitedById) => {
        localStorage.setItem('report_profile_name', name)
        localStorage.setItem('report_profile_type', type)
        localStorage.setItem('report_profile_id', id)
        localStorage.setItem('report_user_name', userName)
        localStorage.setItem('report_currentUser_id', uid)
        localStorage.setItem('report_invited_by_id', invitedById)
    }

    const getTableBody = () => {
        let newArray = []
        _.forEach(invitationList, function(item){
            _.forEach(Profilelist, function(list){
                if(item.profile_id === list.id){
                    newArray.push({ 'profile_id': item.profile_id, 'profile_image': list.profile_image, 'profile_type': list.profile_type, 'profile_name': list.profile_name, 'invited_by': item.invited_by, 'invitation_status': item.invitation_status, 'invited_date': item.invited_date })
                }
            })
        })
        _.forEach(newArray, function(items){
            _.forEach(userList, function(lists){
                if(items.invited_by === lists.id){
                    items['invited_by_email'] = lists.email
                    items['invited_by_name'] = lists.name
                }
            })
        })
        let userName
        _.forEach(userList, function(list){
            if(list.id === uid){
                if(_.isEmpty(_.trim(list.name))){
                    userName = list.email
                }
                else{
                    userName = _.capitalize(_.trim(list.name))
                }
            }
        })
        if(prevList !== invitationList){
            return(
                <tbody>
                    {newArray.map((item, index) => {
                        const checkList = _.find(currentResult, { 'profile_id': item.profile_id, 'invited_by': item.invited_by })
                        let invited_date = convertDate(item.invited_date)
                        let serialNo = ((activePage - 1) * pageSize) + 1
                        return(
                            <tr key={index}>
                                <td>{serialNo + index}</td>
                                <td>
                                    <div className="d-flex font-weight-bold"> 
                                        <span className="mr-3"> 
                                            <img src={item.profile_image} alt="" />
                                        </span> 
                                        <span className="pt-3">
                                            {item.profile_name}
                                        </span> 
                                    </div>
                                </td>
                                <td>{item.invited_by_name !== undefined && !_.isEmpty(_.trim(item.invited_by_name)) ? item.invited_by_name : item.invited_by_email}</td>
                                <td>{invited_date}</td>
                                {/* <td>{item.invitation_status === 0 ? <label className="badge badge-danger">Pending</label> : <label className="badge badge-success">Done</label>}</td> */}
                                <td>{checkList === undefined ? <label className="badge badge-danger">Pending</label> : <label className="badge badge-success">Done</label>}</td>
                                <td>
                                    <a href={`/testAttempt/${item.profile_id}`} className={item.remaining_quantity === 0 || checkList !== undefined ? "test-btn" : ""} onClick={() => handleClick(item.profile_name, item.profile_type, "profile_assigned", item.invited_by)}>
                                        <button type="button" className="btn btn-secondary btn-sm" disabled={item.remaining_quantity === 0 || checkList !== undefined ? 'disabled' : null}>{checkList === undefined ? 'Start Assessment' : 'Assessment Given'}</button> &nbsp;&nbsp;
                                    </a>
                                    <a href={`/viewCurrentReport/${item.profile_id}`} onClick={() => setName(item.profile_name, item.profile_type, item.profile_id, userName, item.invited_by)}>
                                        <button type="button" className="btn btn-primary btn-icon-text btn-sm">
                                            <i className="mdi mdi-eye btn-icon-prepend"></i>
                                            View Reports
                                        </button> &nbsp;&nbsp;
                                    </a>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            )
        }
        else{
            return <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} />
        }
    }
    
    return(
        <div className="main-panel">
            {loading && showLoader ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> :
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Assigned Profile" subTitle="List" />
                {error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
                {assessmentMsg !== '' ? <h4 className="errorMsg text-center"> {assessmentMsg } </h4> : null}
                <div className="row">
                    <div className="col-md-12 stretch-card">
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-md-9">
                                    <h4 className="card-title pt-2">Assigned Profile</h4>
                                </div>
                                {/* <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" 
                                                   className="form-control p-3 text-left" 
                                                   placeholder="Search" 
                                                   aria-label="Recipient's username"
                                                   value={searchTerm}
                                                   onChange={handleChange} />
                                            <i className="mdi mdi-magnify search-btn"></i>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="table-responsive">
                                    {getTable()}
                                </div>
                                <div>
                                    {error === "" && count.length > pageSize ? 
                                    <Pagination activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={count.length}
                                                pageRangeDisplayed={pageRangeDisplayed}
                                                onChange={handlePageChange} />
                                    : error}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>}
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    invitationList: state.profileReducer.invitationList,
    userList: state.profileReducer.userList,
    Profilelist: state.profileReducer.Profilelist,
    currentResult: state.profileReducer.currentResult,
    count: state.profileReducer.count,
    assessmentMsg: state.profileReducer.assessmentMsg
})

const mapDispatchToProps = {
    getProfileInvitation,
    getAllUsers,
    getProfiles,
    getCurrentUserResult
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAssigned)