import { db } from '../components/firebase'
import _ from 'lodash'
import { take } from 'lodash'
import { pageSize } from '../constants/HelperConstants'

export const filterBy  = (term) => {
    const escapeRegExp = (str) =>
    str.replace('[-A-Z0-9+&@#\/%?=~_|!:,.;]')

    const re = new RegExp(escapeRegExp(term), 'i')
    return person => {
        for (let prop in person) {
        if (!person.hasOwnProperty(prop)) {
            continue;
        }
        if (re.test(person[prop])) {
            return true;
        }
        }
        return false;        
    }
}

export const paginate = (array, searchTerm) => {
    let newArray = []
     _.filter(array, function(o){
        if(_.startsWith(_.trim(_.lowerCase(o.name)), _.lowerCase(searchTerm)) || _.startsWith(_.trim(_.lowerCase(o.email)), _.lowerCase(searchTerm))){
            newArray.push(o)
        }
    })
    // return array.slice((page_number - 1) * page_size, page_number * page_size)
    return newArray
}

export const searchValue = (array, filter, searchKey1, searchKey2) => {
    var result
    if(typeof filter === "undefined" || filter.length === 0){
        result = array
    } 
    else{
        return result = _.filter(array, function (c) {
        // This part will transform every property value in a single string.
        var searchIn = Object.keys(c).reduce(function(res, val) { 
            return (val === searchKey1 || val === searchKey2) ? res+c[val] : res 
        }, '')
        return _.includes(_.lowerCase(searchIn),_.lowerCase(filter));
        })
    }
}

export const searchItem = async (data, searchTerm, examData, activePage, demo, collectionName) => {
    let array = []
    await data.get()
    .then(snapshot => {
        if (snapshot.empty) {
            return;
        }
        snapshot.forEach(doc => {
            array.push({ ...doc.data(), id: doc.id })
        })
    })
    if(array.length !== 0){
        // const value = sortBy(array.filter(filterBy(searchTerm)), ['created_date_time'])
        var value = ""
        if(collectionName === "users"){
            // value = searchValue(array, searchTerm, "name", "email")
            value = paginate(array, searchTerm)
        }
        else if(collectionName === "profile_invitations"){
            // value = searchValue(array, searchTerm, "name", "email")
            value = paginate(array, searchTerm)
        }
        if(activePage === 1){
            const item = take(value, pageSize)
            item.map(val => {
                return examData.push(val)
            })
        }
        else if(activePage > 1){
            if(demo  === true){
                const items = paginate(value, searchTerm)
                let getIndex = ((activePage) * pageSize) - pageSize
                _.forEach(items, function(item, index){
                    if(index >= getIndex && examData.length !== pageSize){
                        examData.push(item)
                    }
                })
                // items.map(item => {
                //     return examData.push(item)
                // })
            }
            return examData
        }
    }
}

export const getcount = async (collectionName, searchTerm, Id) => {
    let array = [], newArray = [], newArrayy = [], dbRef = ""
    if(collectionName === "users" && Id !== undefined){
        dbRef = db.collection(collectionName).where("user_status", "==", 1).where("purchase_plan_id", "array-contains", Id)
    }
    else if(collectionName === "users"){
        dbRef = db.collection(collectionName)
    }
    else if(collectionName === "profile_invitations"){
        let id = localStorage.getItem('uid')
        dbRef = db.collection(collectionName).where("invited_by_id", "array-contains", id)
    }
    else if(collectionName === "cancel_invitation"){
        let type = localStorage.getItem('type')
        let id = localStorage.getItem('uid')
        if(type === "Admin"){
            dbRef = db.collection(collectionName)
        }
        else{
            dbRef = db.collection(collectionName).where("invited_by_id", "array-contains", id)
        }
    }
    if(searchTerm === "" || searchTerm === undefined){
        await dbRef.get()
        .then(snapshot => {
            if (snapshot.empty) {
              return;
            }
            snapshot.forEach(doc => {
                newArrayy.push({...doc.data(), id: doc.id})
            })
        })
        let uid = localStorage.getItem('uid')
        _.forEach(newArrayy, function(items){
            if(items.id !== uid){
                array.push(items)
            }
        })
        if(collectionName === "users" && Id === undefined){
            return array
        }
        else{
            return newArrayy
        }
    }
    else{
        await dbRef.get()
        .then(snapshot => {
            if (snapshot.empty) {
              return;
            }
            snapshot.forEach(doc => {
                newArray.push({...doc.data(), id: doc.id})
            })
        })
        if(newArray.length !== 0){
            // const value = newArray.filter(filterBy(searchTerm))
            // const value = searchValue(newArray, searchTerm, "name", "email")
            const value = paginate(newArray, searchTerm)
            value.map(val => {
                return array.push(val.id)
            })
            return array
        }
    }
}

export const getPaginateData = async (collectionName, activePage, list, searchTerm, Id) => {
    var lastIndex
    let dbRef = ""
    const field = 'created_date_time'
    if(collectionName === "users" && Id !== undefined){
        dbRef = db.collection(collectionName).where("user_status", "==", 1).where("purchase_plan_id", "array-contains", Id).orderBy(field, "desc")
    }
    else if(collectionName === "users"){
        dbRef = db.collection(collectionName).orderBy(field, "desc")
    }
    else if(collectionName === "profile_invitations"){
        let id = localStorage.getItem('uid')
        dbRef = db.collection(collectionName).where("invited_by_id", "array-contains", id).orderBy(field, "desc")
    }
    else if(collectionName === "cancel_invitation"){
        let type = localStorage.getItem('type')
        let id = localStorage.getItem('uid')
        if(type === "Admin"){
            dbRef = db.collection(collectionName)
        }
        else{
            dbRef = db.collection(collectionName).where("invited_by_id", "array-contains", id)
        }
    }
    if(activePage === undefined && searchTerm === undefined){
        await dbRef.get()
        .then(snapshot => {
            if (snapshot.empty) {
                return;
            } 
            snapshot.forEach(doc => {
                list.push({ ...doc.data(), id:doc.id })
            })
        })
        .catch(err => {
            throw err
        })
    }
    else{
        if(activePage === 1){
            if(searchTerm !== "" && searchTerm !== undefined){
                await searchItem(dbRef, searchTerm, list, activePage, false, collectionName)
            }
            else{
                await dbRef.limit(pageSize).get()
                .then(snapshot => {
                    if (snapshot.empty) {
                        return;
                    } 
                    snapshot.forEach(doc => {
                        lastIndex = doc.data().created_date_time
                        list.push({ ...doc.data(), id:doc.id })
                    })
                })
                .catch(err => {
                    throw err
                })
            }
        } 
        else if(activePage > 1){
            const temPageSize = pageSize * (activePage - 1)
            if(searchTerm !== "" && searchTerm !== undefined){
                await searchItem(dbRef, searchTerm, list, activePage, false, collectionName)
            }
            else{
                await dbRef.limit(temPageSize).get()
                .then(snapshot => {
                    if (snapshot.empty) {
                        return
                    } 
                    snapshot.forEach(doc => {
                        lastIndex = doc.data().created_date_time
                    })
                })
                .catch(err => {
                })
            }
            if(searchTerm !== "" && searchTerm !== undefined){
                await searchItem(dbRef, searchTerm, list, activePage, true, collectionName)
            }
            else{
                await dbRef.startAfter(lastIndex).limit(pageSize).get()
                .then(snapshot => {
                    if (snapshot.empty) {
                        return;
                    } 
                    snapshot.forEach(doc => {
                        list.push({ ...doc.data(), id:doc.id })
                    })
                })
                .catch(err => {
                })
            }
        }
    }
    return list
}

export const getProfilecount = async (collectionName, searchTerm, Id) => {
    let array = [], newArray = [], newArrayy = [], dbRef = "", items = []
    if(collectionName === "profile_invitations" && Id !== undefined){
        dbRef = db.collection(collectionName).doc(Id)
    }
    if(searchTerm === "" || searchTerm === undefined){
        const values = await dbRef.get()
        const newList = values.data()
        if(newList !== undefined){
            items.push(newList)
        }
        _.forEach(items, function(item){
            _.forEach(item.invited_plan, function(plan){
                newArrayy.push(plan)
            })
        })
        return newArrayy
    }
    else{
        const values = await dbRef.get()
        const newList = values.data()
        if(newList !== undefined){
            newArray.push(newList)
        }
        if(newArray.length !== 0){
            // const value = newArray.filter(filterBy(searchTerm))
            // const value = searchValue(newArray, searchTerm)
            const value = paginate(newArray, searchTerm)
            value.map(val => {
                return array.push(val.id)
            })
            return array
        }
    }
}

export const getPaginateProfileData = async (collectionName, activePage, list, searchTerm, Id) => {
    let dbRef = "", newArray = [], lastIndex
    if(collectionName === "profile_invitations" && Id !== undefined){
        dbRef = db.collection(collectionName).doc(Id)
    }
    if(activePage === undefined && searchTerm === undefined){
        const values = await dbRef.get()
        const newList = values.data()
        if(newList !== undefined){
            list.push(newList)
        }
    }
    else{
        if(activePage === 1){
            if(searchTerm !== "" && searchTerm !== undefined){
                await searchItem(dbRef, searchTerm, list, activePage, false, collectionName)
            }
            else{
                const values = await dbRef.get()
                const newList = values.data()
                if(newList !== undefined){
                    newArray.push(newList)
                }
                _.forEach(newArray, function(item){
                    _.forEach(item.invited_plan, function(plan, index){
                        if(index < pageSize){
                            lastIndex = plan.invited_date
                            list.push(plan)
                        }
                    })
                })
            }
        } 
        else if(activePage > 1){
            const temPageSize = pageSize * (activePage - 1)
            if(searchTerm !== "" && searchTerm !== undefined){
                await searchItem(dbRef, searchTerm, list, activePage, false, collectionName)
            }
            else{
                const values = await dbRef.get()
                const newList = values.data()
                if(newList !== undefined){
                    newArray.push(newList)
                }
                _.forEach(newArray, function(item){
                    _.forEach(item.invited_plan, function(plan, index){
                        if(index < temPageSize){
                            lastIndex = plan.invited_date
                        }
                    })
                })
            }
            if(searchTerm !== "" && searchTerm !== undefined){
                await searchItem(dbRef, searchTerm, list, activePage, true, collectionName)
            }
            else{
                let getIndex
                _.forEach(newArray, function(list){
                   getIndex = _.findIndex(list.invited_plan, ['invited_date', lastIndex])
                })
                _.forEach(newArray, function(item){
                    _.forEach(item.invited_plan, function(plan, index){
                        if(index > getIndex){
                            if(list.length !== pageSize){
                                list.push(plan)
                            }
                        }
                    })
                })
            }
        }
    }
    return list
}