import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import ReactLoading from "react-loading"
import { getProfiles, getUsers, getAssigneeList, getCurrentProfile, addProductToShopify, updateProductToShopify, deleteProductToShopify, hideCancelledMsg, getResultByProfile } from '../../../actions/ProfileActions'
import { convertDate } from '../date/convertDate'
import InviteContainer from './InviteContainer'
import $ from 'jquery'
import ShopifyModal from './ShopifyModal'
import { usePrevious } from '../library/CommonMethods'
import CancelInviteModal from '../CancelInvite/CancelInviteModal'

const Profile = (props) => {
    let pathname = props.location.pathname
    const { getProfiles, loading, error, Profilelist, getCurrentProfile, addProductToShopify, updateProductToShopify, deleteProductToShopify, successMsg, getUsers, getAssigneeList, currentProfile, showInvite, showInviteMsg, hideCancelledMsg, getResultByProfile, userResultList } = props
    const [profileId, setProfileId] = useState('')
    const [profileName, setProfileName] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [ImageUrl, setImageUrl] = useState('')
    const [Button, showButton] = useState(false)
    const [SuccessMsg, setSuccessMsg] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [show, setShow] = useState(true)
    const [showMsg, setShowMsg] = useState(false)
    const prevmsg = usePrevious(successMsg)

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getProfiles()
                getAssigneeList()
                getUsers()
                $(".btn").click(function(){
                    $("#pushToShopify").modal('show');
                })
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getProfiles, getAssigneeList, getUsers])

    useEffect(() => {
        if(prevmsg !== successMsg){
            setShow(true)
        }
        if(!showInviteMsg && successMsg !== ''){
            setShowMsg(true)
        }
    })

    const getTable = () => {
        return(
            <table id="recent-purchases-listing" className="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Profile Name</th>
                        <th>SKU</th>
                        <th>Created At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                {getTableBody()}                   
            </table>
        )
    }

    const renderInvite = (id, name) => {
        getCurrentProfile(id)
        getAssigneeList()
        getUsers()
        setProfileId(id)
        setProfileName(name)
        setShowModal(true)
    }
    
    const renderCancelPopup = (list) => {
        getCurrentProfile(list.id)
        getResultByProfile(list.id)
        getAssigneeList()
        getUsers()
    }

    const getTableBody = () => {
        return(
            <tbody>
                {Profilelist.map((list, index) => {
                    let created_date = convertDate(list.created_date_time)
                    return(
                        <tr key={list.id}>
                            <td>{index + 1}</td>
                            <td>
                                <div className="d-flex font-weight-bold"> 
                                    <span className="mr-3"> 
                                        <img src={list.profile_image !== undefined ? list.profile_image : '--'} alt="" />
                                    </span> 
                                    <span className="pt-3">
                                        {list.profile_name !== undefined ? list.profile_name : '--'}
                                    </span> 
                                </div>
                            </td>
                            <td>
                                {list.Profile_SKU !== undefined ? list.Profile_SKU : '--'}
                            </td>
                            <td>{created_date}</td>
                            <td>
                                <a href={`/viewAdminProfile/${list.id}`}>
                                    <button type="button" className="btn btn-primary btn-sm">View</button>&nbsp;&nbsp;  
                                </a>
                                <button type="button" className="btn btn-success btn-sm" data-toggle="modal" data-target="#invite" disabled={list.remaining_quantity === 0 ? 'disabled' : null} onClick={() => renderInvite(list.id, list.profile_name)}>Invite</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-secondary btn-sm" data-toggle="modal" data-target="#pushToShopify" onClick={() => renderShopifyPopup(list)}>Push To Shopify</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-danger btn-sm" data-toggle="modal" data-target="#cancelInvite" onClick={() => renderCancelPopup(list)}>Cancel Invite</button>
                            </td>
                        </tr>
                    )
                })}                          
            </tbody>
        )
    }

    const renderShopifyPopup = (list) => {
        getCurrentProfile(list.id)
        let imageUrl
        var proxyUrl = 'https://cors-anywhere.herokuapp.com/', targetUrl = list.profile_image
        toDataUrl(proxyUrl + targetUrl, function(data) { 
            imageUrl = data.split('base64,')
            setImageUrl(imageUrl[1])})
        const timer = setTimeout(() => {
            showButton(true)
        }, 4000);
        return () => clearTimeout(timer)
    }

    const renderFinalProduct = (values) => {
        let CurrentProductData = {}
        CurrentProductData['profile_description'] = values.profile_description
        CurrentProductData['profile_name'] = values.profile_name
        CurrentProductData['profile_type'] = values.profile_type
        CurrentProductData['profile_SKU'] = values.Profile_SKU
        CurrentProductData['profile_cost'] = values.profile_cost
        CurrentProductData['profile_image'] = ImageUrl
        CurrentProductData['profile_id'] = values.id
        CurrentProductData['product_id'] = values.profile_shopify_id
        return CurrentProductData
    }

    async function toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest()
        xhr.onload = await function() {
          var reader = new FileReader()
          reader.onloadend = async function() {
            await callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
    }

    const handleSubmit = () => {}

    const mySubmitHandler = (values, key) => {
        setShow(true)
        if(key === "update"){
            const data = renderFinalProduct(values)
            updateProductToShopify(data)
        }
        else if(key === "push"){
            const data = renderFinalProduct(values)
            addProductToShopify(data)
        }
        else{
            const data = renderFinalProduct(values)
            deleteProductToShopify(data)
        }
    }

    if(successMsg !== '' && show && showMsg){
        setSuccessMsg(successMsg)
        setShow(false)
    }

    if(error !== '' && show){
        setErrorMsg(error)
        setShow(false)
    }

    const handleClose = () => {
        setSuccessMsg('')
        setErrorMsg('')
    }

    const closeInvitePopup = () => {
        hideCancelledMsg()
    }

    return(
        <div className="main-panel">
            {loading && showInvite ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> :
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Profile" subTitle="Profile List" />
                {error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
                <div className="row">
                    <div className="col-md-12 stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title pt-2">Assessment Profiles</h4>
                                <div className="table-responsive">
                                    {Profilelist !== undefined ? Profilelist.length !== 0 ? getTable() : <h3> No Profiles Available </h3> :null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
            }
            {showModal ? <InviteContainer id="invite" profileId={profileId} profileName={profileName} /> : null}
            <div className="modal fade" data-backdrop="static" data-keyboard="false" id="pushToShopify" tabIndex="-1" aria-labelledby="pushToShopifyTitle"  aria-modal="true" role="dialog">
                {loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : null}
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <div className="text-center font-weight-bold profile-name">
                                <h3 className="text-center">    
                                    {currentProfile !== undefined ? currentProfile.profile_name : null}
                                </h3>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        {errorMsg !== '' ? <div className="errorMessage"><h5 className="errorMsg text-center"> {errorMsg} </h5></div>: null}
                        {SuccessMsg !== '' ? <div className="successMessage"><h5 className="successMsg text-center"> {SuccessMsg} </h5></div> : null}
                        <div className="modal-body">
                            <div className="leadership-popup">
                                <form>
                                    <ShopifyModal showCurrentProfile={true} SuccessMsg={SuccessMsg} Button={Button} onSubmit={handleSubmit} mySubmitHandler={mySubmitHandler} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" data-backdrop="static" data-keyboard="false" id="cancelInvite" tabIndex="-1" aria-labelledby="cancelInviteTitle" aria-modal="true" role="dialog">
                {loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : null}
                <div className="modal-dialog modal-dialog-centered modal-cancelled">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <div className="text-center font-weight-bold profile-name">
                                <h3 className="text-center">
                                    {currentProfile !== undefined ? currentProfile.profile_name : null}
                                </h3>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeInvitePopup}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        {/* {errorMsg !== '' ? <div className="errorMessage"><h5 className="errorMsg text-center"> {errorMsg} </h5></div> : null}
                        {SuccessMsg !== '' ? <div className="successMessage"><h5 className="successMsg text-center"> {SuccessMsg} </h5></div> : null} */}
                        <div className="modal-body">
                            <div>
                                <form>
                                    <CancelInviteModal currentProfile={currentProfile} userResultList={userResultList}  />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    Profilelist: state.profileReducer.Profilelist,
    currentProfile: state.profileReducer.currentProfile,
    successMsg: state.profileReducer.successMsg,
    showInvite: state.profileReducer.showInvite,
    showInviteMsg: state.profileReducer.showInviteMsg,
    userResultList: state.profileReducer.userResultList
})

const mapDispatchToProps = {
    getProfiles,
    getUsers,
    getCurrentProfile,
    addProductToShopify,
    updateProductToShopify,
    deleteProductToShopify,
    getAssigneeList,
    hideCancelledMsg,
    getResultByProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)