import React from 'react'
import { useHistory } from 'react-router-dom'

const Breadcrumb = (props) => {
    const { title , subTitle } = props
    let history = useHistory()
    return(
        <div className="row">
            <div className="col-md-12 grid-margin mb-2">
                <div className="d-flex justify-content-between flex-wrap">
                    <div className="d-flex">
                        <i className="mdi mdi-home text-muted hover-cursor"></i>
                        <p className="text-muted mb-0 hover-cursor" onClick={history.goBack}>&nbsp;&nbsp;{title}&nbsp;/&nbsp;</p>
                        <p className="text-primary mb-0 hover-cursor">{subTitle}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Breadcrumb