import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { cancelInvite } from '../../../actions/ProfileActions'

const CancelInviteModal = (props) => {
    const { usersList, assigneeList, currentProfile, cancelInvite, cancelledMsg, userResultList, disabled } = props

    const getTable = (newList, currentProfile, invitationArray) => {
        return (
            <table id="recent-purchases-listing" className="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Email</th>
                        <th>Registered</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                {getTableBody(newList, currentProfile, invitationArray)}
            </table>
        )
    }

    const renderCancelinvite = (list, currentProfile, invitationArray) => {
        let newInvitationArray 
        let uid = localStorage.getItem('uid')
        let json = {
            'name': list.user.name,
            'email': list.user.email,
            'created_date_time': new Date(),
            'modified_date_time': new Date(),
            'invited_by_id': [
                uid
            ],
            'invitations': [
                {
                    'profile_id': currentProfile.id,
                    'invited_date': list.invitations.invited_date,
                    'invited_by': uid,
                    'cancelled_date': new Date(),
                    'assessment_status': 0
                }
            ]
        }
        _.forEach(invitationArray, function(item){
            if(item.id === list.user.id){
                newInvitationArray = item
            }
        })
        cancelInvite(json, list.user.id, newInvitationArray, list.user.user_status, usersList, list.current_profile_name, list.user.type)
    }

    const getTableBody = (newList, currentProfile, invitationArray) => {
        return (
            <tbody>
                {newList.map((list, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                {list.user.email}
                            </td>
                            <td>
                                {list.user.user_status === 0 ? <label className="badge badge-primary">Pending</label> : <label className="badge badge-success">Done</label>}
                            </td>
                            <td>
                                {list.invitations.assessment_status === 0 ? <label className="badge badge-primary">Pending</label> : list.invitations.assessment_status === 1 ? <label className="badge badge-info">In Progress</label> : <label className="badge badge-success">Done</label>}
                            </td>
                            <td>
                                {list.user.user_status === 0 ?
                                <button type="button" className="btn btn-danger btn-sm" data-toggle="modal" data-target="#confirmdelete" disabled={disabled} onClick={() => renderCancelinvite(list, currentProfile, invitationArray)}>Cancel Invite</button>
                                : list.user.user_status === 1 && !list.invitations.result && list.invitations.assessment_status !== 1 ? <button type="button" className="btn btn-danger btn-sm" data-toggle="modal" data-target="#confirmdelete" disabled={disabled} onClick={() => renderCancelinvite(list, currentProfile, invitationArray)}>Cancel Invite</button> : '--'}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        )
    }

    const cancelledInviteBtn = () => {
        localStorage.setItem('cancelled_paramter', "viewalllink")
        localStorage.setItem('current_profile_id', currentProfile.id)
    }  

    const getList = (currentProfile) => {
        let newList = [], invitationArray = []
        let uid = localStorage.getItem('uid')
        _.forEach(usersList, function(user){
            _.forEach(assigneeList, function(list){
                if(user.id === list.id){
                    _.forEach(list.invited_plan, function(profileList){
                        if(profileList.profile_id === currentProfile.id && profileList.invited_by === uid){
                            newList.push({ 'invitations': profileList, 'user': user, 'current_profile_name': currentProfile.profile_name })
                            invitationArray.push(list)
                        }
                    })
                }
            })
        })
        _.forEach(newList, function(list){
            list.invitations['result'] = false
        })
        _.forEach(newList, function(list){
            if(userResultList !== undefined && userResultList.length !== 0){
                _.forEach(userResultList, function(result){
                    if(result.profile_id === list.invitations.profile_id && result.user_id === list.user.id && result.invited_by === uid){
                        list.invitations['result'] = true
                    }
                })
            }
        })
        return(
            <div>
                <a href={`/viewInviteeList`} className="view-invitee-list" onClick={cancelledInviteBtn}>
                    View All
                </a>
                {newList.length !== 0 ? getTable(newList, currentProfile, invitationArray) : <h4 style={{ textAlign: 'center' }}>No Assignee</h4>}
            </div>
        )
    }

    return(
        <div>
            {cancelledMsg !== '' ? 
                <div className="successMessage cancel-success-msg">
                    <h5 className="successMsg"> {cancelledMsg} </h5> 
                </div> 
            : null}
            {currentProfile !== undefined ?  getList(currentProfile): null}
            {/* <div className="modal fade" data-backdrop="static" data-keyboard="false" id="confirmdelete" tabIndex="-1" aria-labelledby="confirmdeleteTitle" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <div className="text-center font-weight-bold profile-name">
                                <h3 className="text-center">
                                    Are you sure you want to cancel this invite ?
                                </h3>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <form>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

const mapStateToProps = (state) => ({
    usersList: state.profileReducer.usersList,
    assigneeList: state.profileReducer.assigneeList,
    disabled: state.profileReducer.cancel_disabled,
    cancelledMsg: state.profileReducer.cancelledMsg
})

const mapDispatchToProps = {
    cancelInvite
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelInviteModal)