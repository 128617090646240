import { put, takeLatest, call } from 'redux-saga/effects'
import "firebase/auth"
import firebase from '../components/firebase'
import { generateUniqueId } from './UniqueId'
import { UPLOAD_IMAGE_REQUEST, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_ERROR } from '../constants/HelperConstants'

async function uploadImages(data, img_name, folderName){
    try{
        let name
        name = data.name
        let value = generateUniqueId()
        let newName=""
        newName = newName.concat(value, '_', name)
        var storageRef = firebase.storage().ref()
        const fileRef = storageRef.child(`${folderName}/${newName}`)
        var uploadTask = await fileRef.put(data)
        var downloadURL = await uploadTask.ref.getDownloadURL()
        localStorage.setItem(img_name, downloadURL)
    }
    catch(error){
        throw error
    }
}

function uploadUrl(){
    let id = localStorage.getItem('uid')
    let downloadURL = localStorage.getItem('user_img_url')
    let data = {'user_img_url': downloadURL}
    return firebase.firestore().collection("users").doc(id).set(data, {merge: true})
        .then(response => response)
        .catch(error => { throw error })
}

function* uploadImageData(data){
    try{
        const { name, folderName } = data
        yield call(uploadImages, data.data, name, folderName) 
        yield call(uploadUrl)
        const msg = "Image Uploaded"
        yield put({ type: UPLOAD_IMAGE_SUCCESS, msg })
    }
    catch(error){
        const err = "Image cannot be uploaded.. Please try Again!"
        yield put({ type: UPLOAD_IMAGE_ERROR, err })
    }
}

export default () => {
    function * watcher() {
      yield takeLatest(UPLOAD_IMAGE_REQUEST, uploadImageData)
    }
    return {watcher}
}