import React, { Component } from 'react'
import CanvasJSReact from '../../../canvasjs.react'
var CanvasJSChart = CanvasJSReact.CanvasJSChart

class GraphContainer extends Component {
	render() {
		const { title, resultArray } = this.props
		const Lspoptions = {
			title:{
				text: title,
				fontColor: '#4a4a4a',
				fontSize: 24,
				fontWeight: 'bold',
				fontFamily: '"Roboto", sans-serif'
			},
			axisX:{
				interval: 1,
				labelAngle: 0,
				labelMaxWidth: 80,
				suffix: '      .'
			},
			axisY:{
				minimum: 0,
				stripLines:[
					{                
						startValue:0,
						endValue:7.5,                
						// color:"#eeeeee"   
						color: "#fdddcf"             
					},
					{                
						startValue:7.5,
						endValue:22.5,   
						label: "Under Emphasis",
						labelFontColor: "black",           
						color:"#a4aee7"    
					},
					{                
						startValue:22.5,
						endValue:32.5, 
						label: "Accepted",
						labelFontColor: "black", 
						color:"#99eba4"                                 
					},
					{                
						startValue:32.5,
						endValue:80, 
						label: "Over Emphasis",
						labelFontColor: "black",                
						color:"#e0bdbd"                
					}
				]                    
			},
			data: [{
                type: "line",
                dataPoints: resultArray
			}]
		}
		return (
			<CanvasJSChart options = {Lspoptions} />
		)
	}
}

export default GraphContainer