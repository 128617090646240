import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import Footer from '../../footer/Footer'
import './Profile.scss'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import { getCurrentProfile, getUsers } from '../../../actions/ProfileActions'
import ReactLoading from 'react-loading'
import { convertDate, formatMoney } from '../date/convertDate'
import _ from 'lodash'
import Pagination from 'react-pagination-bootstrap'
import {itemsCountPerPage, pageRangeDisplayed, pageSize} from '../../../constants/HelperConstants'
import { usePrevious } from '../library/CommonMethods'

const ViewAdminProfile = (props) => {
    let pathname = props.location.pathname
    const { currentProfile, getCurrentProfile, loading, error, getUsers, usersList, count } = props
    const prevList = usePrevious(usersList)
    let { id } = props.match.params
    const [activePage, setActivePage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getCurrentProfile(id)
                getUsers(id, activePage)
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getCurrentProfile, id, getUsers, activePage])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        setShowLoader(false)
    }

    const getDetails = () => {
        if(currentProfile === '' || currentProfile === undefined){
            return null
        }
        const total = formatMoney(currentProfile.profile_cost)
        return(
            <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="chartjs-size-monitor">
                            <div className="chartjs-size-monitor-expand">
                                <div className=""></div>
                            </div>
                            <div className="chartjs-size-monitor-shrink">
                                <div className=""></div>
                            </div>
                        </div>
                        <div className="card-body testName">
                            <img src={currentProfile.profile_image} style={{ width: '100px', height: '100px' }} alt=" " />
                            <p className="text-muted"> 
                                <span className="font-weight-bold"> Name of the Assessment :</span> 
                                &nbsp;&nbsp;&nbsp;{currentProfile.profile_name}
                            </p>
                            <p className="text-muted"> 
                                <span className="font-weight-bold"> Inventory :</span> 
                                &nbsp;&nbsp;&nbsp;{currentProfile.profile_inventory}
                            </p>
                            <p className="text-muted"> 
                                <span className="font-weight-bold"> SKU :</span> 
                                &nbsp;&nbsp;&nbsp;{currentProfile.Profile_SKU}
                            </p>
                            <p className="text-muted"> 
                                <span className="font-weight-bold"> Unit Price :</span> 
                                &nbsp;&nbsp;&nbsp; &#163;{total} each
                            </p>
                            <div className="decp">
                                <p> 
                                    <span className="font-weight-bold"> Description :</span> 
                                    &nbsp;&nbsp;&nbsp;{currentProfile.profile_description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body row">
                        <div className="col-md-8">
                            <h4 className="card-title mb-0">ASSESSMENT DETAILS</h4>
                        </div>
                        <div className="col-md-4">
                            <a href={`/viewTest/${id}`}>
                                <button type="button" className="btn btn-primary float-right">
                                    View Assessment
                                </button>
                            </a>
                        </div>
                            <div className="table-responsive pt-3">
                                <table className="table table-bordered test-d">
                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">No. of Question</td>
                                            <td>{currentProfile.profile_total_questions}</td>                         
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">No. of options in each question</td>
                                            <td>{currentProfile.profile_display_option}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">No. of profile results</td>
                                            <td>{currentProfile.profile_result_option}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Answer Type</td>
                                            <td>Rating from 0 to 5</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getTable = () => {
        return(
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-0">People who purchased this Assessment</h4>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>User</th>
                                            <th>Name</th>
                                            <th>Quantity</th>
                                            <th>Amount</th>
                                            <th>Purchase Date</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {usersList !== undefined ? usersList.length !== 0 ? getTableBody() : <tbody> No Purchased Assessment Available </tbody> : null}
                                </table>
                                <div>
                                    {error === "" && count.length > pageSize ? 
                                    <Pagination activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={count.length}
                                                pageRangeDisplayed={pageRangeDisplayed}
                                                onChange={handlePageChange} />
                                    : error}
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getTableBody = () => {
        if(prevList !== usersList){
            return(
                <tbody>
                    {usersList.map((item, listIndex) => {
                        let newItem = _.filter(item.purchase_plan, ['profile_id', id])
                        return newItem.map((data, index) => {
                            let purchase_date
                            if(data.purchase_date !== undefined){
                                purchase_date = convertDate(data.purchase_date)
                            }
                            const total = formatMoney(data.amount)
                            let serialNo = ((activePage - 1) * pageSize) + 1
                            return(
                                <tr key={index}>
                                    <td>{serialNo + listIndex}</td>
                                    <td className="py-1">
                                        {item.user_img_url !== undefined ?
                                        <img src={item.user_img_url} alt=" " />
                                        : "--"}
                                    </td>
                                    <td>{item.name !== undefined ?  !_.isEmpty(_.trim(item.name)) ? item.name : item.email : '--'}</td>
                                    <td>{data.quantity !== undefined ? data.quantity : "--"}</td>
                                    <td>&#163;{total !== undefined ? total : 0}</td>
                                    <td>{purchase_date !== undefined ? purchase_date : '--'}</td>
                                    <td>
                                        <a href={`/userDetails/${item.id}`}>
                                            <button type="button" className="btn btn-primary btn-sm"><i className="mdi mdi-eye"></i> &nbsp;User Details</button> 
                                        </a>
                                    </td>
                                </tr>
                            )
                        })
                    })}
                </tbody>
            )
        }
        else{
            return <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} />
        }
    }

    return(
        <div className="main-panel">
            {loading && showLoader ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : 
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Profile" subTitle="Profile Details" />
                {error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
                {getDetails()}  
                {getTable()}        
            </div>
            }
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    currentProfile: state.profileReducer.currentProfile,
    usersList: state.profileReducer.usersList,
    count: state.profileReducer.count
})

const mapDispatchToProps = {
    getCurrentProfile,
    getUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAdminProfile)