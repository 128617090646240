import React , { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash'

class ShopifyModal extends Component{
    renderField({ input, label, type, placeholder }) {
        return (
            <div className="form-group">
                <label>{label} </label>
                <input {...input} className="form-control" placeholder={placeholder} type={type} />
            </div>
        )
    }

    renderTextArea({ input, label, placeholder }) {
        return (
            <div className="form-group">
                <label>{label} </label>
                <textarea {...input} className="form-control" placeholder={placeholder} />
            </div>
        )
    }

    render(){
        const { initialValues, SuccessMsg, Button, handleSubmit, mySubmitHandler } = this.props
        return(
            <div className="leadershipcontainer">
                <div className="userProfileContainer">
                    <div className="profileImg">
                        <img src={initialValues !== undefined ? initialValues.profile_image  : ""} alt="" />
                    </div>
                    <div className="Profiledescription">
                        <Field name="profile_description" component={this.renderTextArea} label="Description" placeholder="Enter Description" /> 
                    </div>
                </div>
                <div className="secondSection">
                    <Field name="Profile_SKU" component={this.renderField} type="text" label="SKU" placeholder="Enter SKU" />    
                    <Field name="profile_cost" component={this.renderField} type="number" label="Price (in &#163;)" placeholder="Enter Price" />
                </div>
                {initialValues !== undefined ? _.isEmpty(initialValues.profile_shopify_id ) ? 
                    <div className="pushbtn">
                        {Button ? <button className="btn btn-primary" disabled={SuccessMsg !== "" ? "disabled" : null} onClick={handleSubmit((values) => {mySubmitHandler(values, "push")})}>Push To Shopify</button> : null}
                    </div>  
                    : <div className="pushbtn">
                        {Button ? <button className="btn btn-success" onClick={handleSubmit((values) => {mySubmitHandler(values, "update")})}>Update</button> : null} &nbsp;
                        {Button ? <button className="btn btn-danger" onClick={handleSubmit((values) => {mySubmitHandler(values, "delete")})}>Delete</button> : null}
                    </div>
                : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentProfile: state.profileReducer.currentProfile,
    initialValues: state.profileReducer.currentProfile
})

export default connect(mapStateToProps)(reduxForm({form: 'simple', enableReinitialize: true })(ShopifyModal))