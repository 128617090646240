import { combineReducers } from 'redux'
import notification from './notification.reducer'
import auth from './auth.reducer'
import helper from './helper.reducer'
import profile from './profile.reducer'
import dashboard from './dashboard.reducer'
import { reducer as reduxFormReducer } from 'redux-form'

const rootReducer = combineReducers({
  notificationReducer: notification,
  authReducer: auth,
  helperReducer: helper,
  profileReducer: profile,
  dashboardReducer: dashboard,
  form: reduxFormReducer
})

export default rootReducer