import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import ReactLoading from "react-loading"
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import _ from 'lodash'
import { convertDate } from '../date/convertDate'
import { getProfiles, getProfileInvitation, getUserProfile, getCurrentUserResult } from '../../../actions/ProfileActions'

const TraineeUserDetails = (props) => {
    let pathname = props.location.pathname
    const { getUserProfile, currentUser, loading, error, getProfileInvitation, invitationList, getProfiles, Profilelist, getCurrentUserResult, currentResult } = props
    let { id } = props.match.params
    let uid = localStorage.getItem('uid')

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getUserProfile(id)
                getProfileInvitation(id)
                getProfiles()
                getCurrentUserResult(id)
            }
            else{
                history.push('/login') 
            }
        }
    },[getUserProfile, pathname, getProfileInvitation, getProfiles, id, getCurrentUserResult, uid])

    const getUser = () => {
        let newList = []
        _.forEach(Profilelist, function(list){
            _.forEach(invitationList, function(itemss){
                _.forEach(itemss.invited_plan, function(item){
                    if(item.profile_id === list.id){
                        newList.push({ 'invitation_list': item, 'profile_list': list })
                    }
                })
            })
        })
        let currentUserName = _.trim(currentUser.name)
        return(
            <div className="row">
                    <div className="col-md-9 grid-margin stretch-card">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="profile-sidebar">
                                        <div className="profile-userpic">
                                            <img src={currentUser.user_img_url} className="img-responsive" alt="" />
                                        </div>
                                        <div className="profile-usertitle">
                                            <div className="profile-usertitle-name">
                                                {_.capitalize(currentUserName)}
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-md-7 mt-5 profileDetails">
                                    <p className="text-muted"> <span className="font-weight-bold"> Email Id :</span> {currentUser.email} </p>
                                    <p className="text-muted"> <span className="font-weight-bold"> Phone:</span> {currentUser.phone_number === undefined || currentUser.phone_number === null || _.isEmpty(currentUser.phone_number) ? '--' : currentUser.phone_number} </p>
                                    <p className="text-muted"> <span className="font-weight-bold"> Country :</span> {currentUser.country === undefined || currentUser.country === null || _.isEmpty(currentUser.country) ? '--' : _.capitalize(currentUser.country)} </p>
                                </div>      
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 grid-margin stretch-card resultListing">
                        <div className="card totalPurchase">
                            <div className="card-header">
                                Profile Assigned
                            </div>
                            <div className="card-body">
                                <div className="pt-3 text-center">
                                    <h1> {newList.length !== 0 ? newList.length : '--'}</h1> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
        )
    }

    const getTable = () => {
        return(
            <div className="card-body">
                <h4 className="card-title pt-2">Profile Assigned</h4>
                <div className="table-responsive pt-3">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th> S.No </th>
                                <th> Profile Name </th>
                                <th> SKU </th>
                                <th> Invited Date </th>
                                <th> Status </th>
                            </tr>
                        </thead>
                        {getTableBody()}
                    </table>
                </div>
            </div>
        )
    }

    const getTableBody = () => {
        if(invitationList === '' && Profilelist.length === 0){
            return null
        }
        let newList = []
        _.forEach(Profilelist, function(list){
            _.forEach(invitationList, function(itemss){
                _.forEach(itemss.invited_plan, function(item){
                    if(item.profile_id === list.id && item.invited_by === uid){
                        newList.push({ 'invitation_list': item, 'profile_list': list })
                    }
                })
            })
        })
        return(
            <tbody>
                {newList.map((plan, index) => {
                    let invited_date = convertDate(plan.invitation_list.invited_date)
                    const checkList = _.find(currentResult, ['profile_id', plan.invitation_list.profile_id])
                    return(
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <div className="d-flex font-weight-bold"> 
                                    <span className="mr-3"> 
                                        <img src={plan.profile_list.profile_image} alt="" />
                                    </span> 
                                    <span className="pt-3">
                                        {plan.profile_list.profile_name}
                                    </span> 
                                </div>
                            </td>
                            <td>{plan.profile_list.Profile_SKU}</td>
                            <td>{invited_date}</td>
                            <td>{checkList === undefined ? <label className="badge badge-danger">Pending</label> : <label className="badge badge-success">Done</label>}</td>
                        </tr>
                    )
                })}
            </tbody>
        )
    }

    return(
        <div className="main-panel">
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Assignee" subTitle="Assignee Details" />
                {error !== "" ? <h3 className="errorMsg text-center"> {error} </h3> : null}
                {currentUser !== null ? getUser() : null}  
                {loading ? <div className="loader-div"><ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /></div> : null} 
                <div className="row">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            {currentUser !== null ? getTable() : null}
                        </div>
                    </div>
                </div> 
            </div>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentUser: state.profileReducer.currentUser,
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    invitationList: state.profileReducer.invitationList,
    Profilelist: state.profileReducer.Profilelist,
    currentResult: state.profileReducer.currentResult
})

const mapDispatchToProps = {
    getUserProfile,
    getProfileInvitation,
    getProfiles,
    getCurrentUserResult
}

export default connect(mapStateToProps, mapDispatchToProps)(TraineeUserDetails)