import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class UserAssigneeGraph extends Component {
	render() {
		const { totalAssignee, maximumValue } = this.props
		let Maximum
		if(maximumValue <= 50){
			Maximum = 50
		}
		else if(maximumValue > 50 && maximumValue <= 100){
			Maximum = 100
		}
		else if(maximumValue > 100){
			Maximum = maximumValue
		}
		const options = {
			title:{
                text:'Total Assignee for last 12 months',
                style: {
                    color: '#000000',
					// font: '32px "Dejavu Sans"'
				},
				align: 'center',
				x: -10,
				verticalAlign: 'bottom',
				y: 15,
				widthAdjust: 10
			},
			chart: {
				type: 'column',
			},
			plotOptions: {
				series: {
					pointWidth: 25
				}
			},
			credits:{enabled:false},
			xAxis:{
				type: 'category',
				lineColor: '#726d6d',
				lineWidth: 1,
				labels: {
					style: {
						fontWeight: 'bold',
						color: '#000000',
					}
				},
				title: {
					text:  ''
				},
				reversed: true
			},
			yAxis:{
				min:0,
				max: Maximum,
				lineColor: '#726d6d',
				lineWidth: 1,
				labels: {
					style: {
						fontWeight: 'bold',
						color: '#000000',
					}
				},
				title: {
					text:  ''
				},
				gridLineColor: '#726d6d'
			},    
			tooltip: {
				pointFormat: '{point.y}',
				clusterFormat: '{point.y}: {point.y}'
			},
			series: [{
				showInLegend:false,
				data: totalAssignee
			}]
		}
		return (
			<HighchartsReact highcharts={Highcharts} options={options} />
		)
	}
}

export default UserAssigneeGraph