import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR } from '../constants/AuthConstants'

const initialState = {
  loginerror: '',
  loggingIn: false,
  passwordError: "",
  loginMsg: '',
  successMsg: ''
}

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loggingIn: !state.loggingIn
      }
    case LOGIN_SUCCESS:
      return {
        loginMsg: action.msg,
        loggingIn: !state.loggingIn
      }
    case LOGIN_ERROR:
      return {
        loginerror: action.err
      }
    case RESET_PASSWORD_REQUEST:
      return{
        loggingIn: !state.loggingIn
      }
    case RESET_PASSWORD_SUCCESS:
      return{
        loggingIn: !state.loggingIn
      }
    case RESET_PASSWORD_ERROR:
      return{
        passwordError: action.errorMsg
      }
    case FORGOT_PASSWORD_REQUEST:
      return{
        loggingIn: !state.loggingIn
      }
    case FORGOT_PASSWORD_SUCCESS:
      return{
        successMsg: action.msg,
        loggingIn: !state.loggingIn
      }
    case FORGOT_PASSWORD_ERROR:
      return{
        passwordError: action.errorMsg
      }
    default:
      return state
  }
} 

export default authentication