import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import Footer from '../../footer/Footer'
import './ViewTest.scss'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import { getCurrentQuestions } from '../../../actions/ProfileActions'
import ReactLoading from "react-loading"

const ViewTest = (props) => {
    let pathname = props.location.pathname
    const { getCurrentQuestions, loading, error, questionList } = props
    const { id } = props.match.params
    let profile_results = localStorage.getItem('profile_results')

	useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getCurrentQuestions(id)
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getCurrentQuestions, id])

    const renderQuestions = () => {
        if(questionList === '' || questionList === undefined){
            return null
        }
        const totalQuestions = questionList.data.questions
        let count = 0
        return(
            <div className="mt-4">
                <div className="accordion accordion-solid-header" id="accordion-4" role="tablist">
                    {Object.entries(totalQuestions).map(([key, value], index) => {
                        if(count === 0){
                        } 
                        else{
                            count = count + 1
                        }
                        return(
                            <div className="card" key={index}>
                                <div className="card-header" role="tab" id="heading-1">
                                    <h6 className="mb-0">
                                        <a data-toggle="collapse" href={"#collapse-" + index} aria-expanded={index === count ? "true" : "false"} aria-controls={"collapse-" + index} className="d-flex question-span">
                                            <span>{index + 1 + "." + " "}</span> &nbsp; 
                                            <span className="containerWidth">{key}</span>
                                        </a>
                                    </h6>
                                </div>
                                <div id={"collapse-" + index} className={index === count ? "collapse show" : "collapse"} role="tabpanel" aria-labelledby="heading-1" data-parent="#accordion-4">
                                    <div className="card-body">
                                        <ol type="A">
                                        {Object.values(value).map((item, index) => {
                                            return(
                                                <li key={index}>
                                                    <span>
                                                        {item}
                                                    </span>
                                                </li>
                                            )
                                        })}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderResult = () => {
        let array = profile_results.split(',')
        return(
            <div className="col-md-3 grid-margin stretch-card resultListing">
                <h4 className="card-title"> </h4> 
                <div className="card" style={{ width: '18rem' }}>
                    <div className="card-header">
                        Results
                    </div>
                    <ol className="list-group list-group-flush">
                        {array.map((list, index) => {
                            return <li className="list-group-item curtResult" key={index}> {list} </li>
                        })}
                    </ol>
                </div>
            </div>
        )
    }
    
    return(
        <div className="main-panel">
            {loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : 
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Profile" subTitle="View Assessment" />
                {error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
                <div className="row">
                    <div className="col-md-9 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title pt-2">Leadership Profile Assessment</h4>
                                {renderQuestions()}
                            </div>
                        </div>
                    </div>
                    {renderResult()}
                </div>
            </div>
            }
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    questionList: state.profileReducer.questionList
})

const mapsDispatchToProps = {
    getCurrentQuestions
}

export default connect(mapStateToProps, mapsDispatchToProps)(ViewTest)