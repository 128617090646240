import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import './ViewTest.scss'
import $ from 'jquery'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import Footer from '../../footer/Footer'
import { getCurrentQuestions, addTestResult, getInstructions, updateAssessmentValue } from '../../../actions/ProfileActions'
import _ from 'lodash'
import ReactLoading from "react-loading"
import ReactHtmlParser from 'react-html-parser'

const TestAttemptPage = (props) => {
    let { id } = props.match.params
    let pathname = props.location.pathname
    let uid = localStorage.getItem('uid')
    let testProfileType = localStorage.getItem('TestProfileType')
    const { getCurrentQuestions, loading, error, questionList, successMsg, addTestResult, getInstructions, instructions, updateAssessmentValue } = props
    const [showTest, setShowTest] = useState(false)
    const [show, setShow] = useState(1)
    const [response, showResponse] = useState(false)
    const [showQuestion, setShowQuestion] = useState(true)
    const [questionLength, setQuestionLength] = useState('')
    const [showLength, setShowLength] = useState(true)
    const [result, setResult] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [Length, setLength] = useState('')
    const [showSuccessMsg, setShowSuccessMsg] = useState(true)
    const [Question, setQuestion] = useState('')
    const [showDisabled, setShowDisabled] = useState(false)
    let current_profile_name = localStorage.getItem('current_profile_name')
    
    useEffect(() => {        
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getCurrentQuestions(id)
                getInstructions(testProfileType)
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getCurrentQuestions, id, getInstructions, testProfileType])

    const nextquestion = (questionsLength) => {
        let resultArray = []
        let size, totalScore = 0, found = false, newScoreArray = []
        Object.entries(result).map(([key, value]) => {
            resultArray.push(key)
            if(Question === key){
                Object.entries(value).map(([keyy, newvalue]) => {
                    size = Object.keys(value).length
                    let score = _.toNumber(newvalue.score)
                    newScoreArray.push(newvalue.score)
                    totalScore = totalScore + score
                })
            }
            setQuestion(key)
        })
        const scoreValue = _.includes(newScoreArray, "")
        if(size < Length || scoreValue){
            setErrorMsg('All fields are mandatory and should contain the numbers 1-5 or 0')
        }
        else if(totalScore < 5){
            setErrorMsg(`Ensure field values sum should be equal to 5 not ${totalScore}`)
        }
        else{
            setErrorMsg('')
            setShowDisabled(false)
            found = true
        }
        if(size === Length && _.isEmpty(errorMsg) && found){
            if(show < questionLength && show !== questionLength-1){
                setShow(show + 1)
                if(resultArray.length !== questionsLength){
                    // setErrorMsg('Ensure All field Required*')
                    setShowDisabled(true)
                }
            }
            else if(show === questionLength-1){
                setShow(show + 1)
                $('.finishQuestion').removeClass('finishQuestion')
                $('.nxtQuestion').addClass('finishQuestion')
            }
        }
    }

    const finalSubmit = () => {
        let size, totalScore = 0, found = false, newScoreArray = [], profileInvitedBy
        let profile_invited_by = localStorage.getItem('profile_invited_by')
        Object.entries(result).map(([key, value]) => {
            if(Question === key){
                Object.entries(value).map(([keyy, newvalue]) => {
                    size = Object.keys(value).length
                    let score = _.toNumber(newvalue.score)
                    newScoreArray.push(newvalue.score)
                    totalScore = totalScore + score
                })
            }
            setQuestion(key)
        })
        const scoreValue = _.includes(newScoreArray, "")
        if(size < Length || scoreValue){
            setErrorMsg('All fields are mandatory and should contain the numbers 1-5 or 0')
        }
        else if(totalScore < 5){
            setErrorMsg(`Ensure field values sum should be equal to 5 not ${totalScore}`)
        }
        else{
            setErrorMsg('')
            found = true
        }
        if(size === Length && _.isEmpty(errorMsg) && found){
            setShowTest(false)
            setShowQuestion(false)
            let question_id = questionList.id
            let total_scores = {}, newArray = []
            Object.entries(result).map(([Key, Value]) => {
                Object.values(Value).map(item  => {
                    let score = _.toNumber(item.score)
                    newArray.push({ 'result': item.result, 'score': score })
                })
            })
            var output = _(newArray)
                    .groupBy('result')
                    .map((objs, key) => ({
                        'result': key,
                        'score': _.sumBy(objs, 'score') }))
                    .value()
            _.forEach(output, function(item){
                total_scores[item.result] = item.score
            })
            if(profile_invited_by === "undefined"){
                profileInvitedBy = uid
            }
            else{
                profileInvitedBy = profile_invited_by
            }
            let finalResult = {
                'created_date_time': new Date(),
                'modified_date_time': new Date(),
                'profile_id': id,
                'profile_questions_id': question_id,
                'user_id': uid,
                'total_scores': total_scores,
                'answers': result,
                'invited_by': profileInvitedBy
            }
            addTestResult(finalResult)
        }
    }

    const renderTestPage = () => {
        setShowTest(true)
        updateAssessmentValue(id, uid)
    }

    if(successMsg !== '' && showSuccessMsg){
        showResponse(true)
        setShowSuccessMsg(false)
    }

    const renderResponse = () => {
        return(
            <div className="card-body response">
                <h3>
                    <i className="mdi mdi-check-circle"></i>
                </h3>
                <h3>
                    Congratulations you have completed your Profile. 
                </h3>
                <p> To check your result please visit the report page on the left of the screen. </p>
            </div>
        )
    }

    const handleChange = (event, question, answer, arrayLength) => {
        const { name, value } = event.target
        let obj = {}, total = 0, questions 
        // let score = _.toNumber(value)
        setLength(arrayLength.length)
        if(value > 5){
            setErrorMsg('Ensure field values should be less than or equal to 5')
        }
        // else if(_.isNaN(score)){
        //     setErrorMsg('Ensure all fields are number')
        // }
        else{
            if(_.isEmpty(result)){
                obj[question] = {}
                obj[question][answer] = {"result": name, "score": value}
                setResult(obj)
            }
            else{
                setErrorMsg('')
                let resultArray = _.pick(result, [question])
                if(_.isEmpty(resultArray)){
                    result[question] = {}
                    result[question][answer] = {"result": name, "score": value}
                    setResult(result)
                }
                else{
                    result[question][answer] = {"result": name, "score": value}
                    setResult(result)
                }
            }
            let questionList = _.pick(result, [question])
            Object.entries(questionList).map(([keyy, valuee]) => {
                questions = keyy
            })
            Object.entries(result).map(([Key, Value]) => {
                if(questions === Key){
                    Object.values(Value).map(item  => {
                        let score = _.toNumber(item.score)
                        total = total + score
                    })
                }
            })
            if(total > 5){
                setErrorMsg(`Ensure total of the fields gives 5, and not ${total}`)
            }
            else{
                setShowDisabled(false)
                setQuestion(question)
            }
        }
    }

    function restrictMinus(e) {
        var inputKeyCode = e.keyCode ? e.keyCode : e.which;
        if(inputKeyCode != null) {
            if (inputKeyCode === 45) e.preventDefault();
            if (isNaN(Number(e.key))) e.preventDefault();
            if (inputKeyCode >53) e.preventDefault()
        }
    }

    const renderTest = () => {
        const totalQuestions = questionList.data.questions
        let totalQuestionsArray = []
        return(
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        {loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : null}
                        {showQuestion ?
                        <div className="card-body">
                            <div className="totalQuestion">
                                <h4> Question {show}/{questionLength} </h4>
                            </div>
                            {Object.entries(totalQuestions).map(([key, value], index) => {
                                totalQuestionsArray.push(key)
                                let length = Object.entries(value)
                                return(
                                    <div key={index} className=" questionAnswerRow row active">
                                        {index+1 === show ? [
                                        <div key="div1" className="col-md-12 d-flex p-1">
                                            <h4>{show}.</h4> &nbsp;
                                            <h4 className="test-question">
                                                {key}
                                            </h4>
                                            {errorMsg !== '' ? <div className="errorMsg test-error-msg">{errorMsg}</div> : null}
                                        </div>,
                                        <div key="div2" className="col-md-12 quesAnsList">
                                            <ol type="A">
                                                {Object.entries(value).map(([Key, Value], index) => {
                                                    return(
                                                        <li key={index}>
                                                            <span>{Value}</span>
                                                            <input type="number" min="0" max="5" id="givenanswer" name={Key} className="form-control" onKeyPress={(e) => restrictMinus(e)} onChange={(event) => handleChange(event, key, Value, length)} required />
                                                            {/* <input type="text" maxLength="1" pattern="[0-5]+" name={Key} className="form-control" onChange={(event) => handleChange(event, key, Value, length)} required /> */}
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </div>]
                                        : null}
                                    </div>
                                )
                            })}
                            <div className="col-md-12 pr-0 nxthr">
                                <button type="button" className="btn btn-primary btn-sm float-right nxtQuestion" disabled={errorMsg !== "" || showDisabled ? "disabled" : null} onClick={() => nextquestion(totalQuestionsArray.length)} >Next</button>
                                <button type="button" className="btn btn-primary btn-sm float-right finishQuestion" onClick={finalSubmit}>Submit</button>
                            </div>
                        </div> :
                        response ? renderResponse() : null}
                    </div>
                </div>
            </div>
        )
    }

    if(questionList !== undefined && showLength){
        let size = _.size(questionList.data.questions)
        setQuestionLength(size)
        setShowLength(false)
    }

    return(
        <div className="main-panel">
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Profile Library" subTitle="Questionire" />
                {error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
                {!showTest && showQuestion ? 
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body leadershipPs ">
                                {loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : null}
                                <div className="test-profile-name">
                                    <h2> {current_profile_name} </h2>
                                </div>
                                <h4 className="card-title">Instruction For Completion</h4>
			                    <h3 className="mt4 mb-4"> <span> Total Questions :  </span> {questionLength} </h3>
                                {!_.isEmpty(instructions) ? <p>This Profile contains {questionLength} questions. {ReactHtmlParser(instructions.data.instructions)}</p> : null}
                                <div className="col-md-12 mt-4 text-center">
                                    <button type="button" className="btn btn-success" disabled={loading ? "disabled" : null} onClick={() => renderTestPage()}>Start</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : renderTest()}
            </div>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    questionList: state.profileReducer.questionList,
    successMsg: state.profileReducer.successMsg,
    instructions: state.profileReducer.instructions
})

const mapDispatchToProps = {
    getCurrentQuestions,
    addTestResult,
    getInstructions,
    updateAssessmentValue
}

export default connect(mapStateToProps, mapDispatchToProps)(TestAttemptPage)