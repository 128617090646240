import { UPLOAD_IMAGE_REQUEST, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_ERROR } from "../constants/HelperConstants";

const initialState = {
    imgError: '',
    imgSuccessMsg: '',
    imgLoader: false
}

const helper = (state = initialState, action) => {
    switch(action.type){
        case UPLOAD_IMAGE_REQUEST:
            return{
                imgLoader: true,
                imgSuccessMsg: '',
                imgError: ''
            }
        case UPLOAD_IMAGE_SUCCESS:
            return{
                imgLoader: false,
                imgSuccessMsg: action.msg
            }
        case UPLOAD_IMAGE_ERROR:
            return{
                imgLoader: false,
                imgError: action.err
            }
        default:
            return state
    }
}

export default helper