import React from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { Dashboard, TraineeDashboard } from '../pages/dashboard'
import Notification from '../notifications/Notification'
import NotificationList from '../notifications/NotificationList'
import Sidebar from '../sidebar/Sidebar'
import Header from '../header/Header'
import { Test, ViewTest, ViewReport, TestAttemptPage, ViewCurrentReport } from '../pages/test'
import { Profile, ViewAdminProfile, ViewTraineeProfile, EditProfile, UserProfile, UserDetails } from '../pages/profile'
import { Users, TraineeUsers, TraineeUserDetails, ProfileAssigned } from '../pages/users'
import ResetPassword from '../resetPassword/ResetPassword'
import { ViewInviteeList } from '../pages/CancelInvite'

const Container = (props) => {
    const { location } = props
    
    return(   
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/traineeDashboard" component={TraineeDashboard} />
                    <Route path="/resetpassword" component={ResetPassword} />
                    <Route path="/notification" component={Notification} />
                    <Route path="/notification-list" component={NotificationList} />
                    <Route path="/assessment" component={Test} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/viewTraineeProfile/:id" component={ViewTraineeProfile} /> 
                    <Route path="/users" component={Users} />
                    <Route path="/viewAdminProfile/:id" component={ViewAdminProfile} />
                    <Route path="/viewTest/:id" component={ViewTest} />
                    <Route path="/userProfile" component={UserProfile} />
                    <Route path="/traineeusers" component={TraineeUsers} />
                    <Route path="/editProfile" component={EditProfile} />
                    <Route path="/testAttempt/:id" component={TestAttemptPage} />
                    <Route path="/viewReport" component={ViewReport} />
                    <Route path="/userDetails/:id" component={UserDetails} />
                    <Route path="/traineeuserDetails/:id" component={TraineeUserDetails} />
                    <Route path="/profileAssigned" component={ProfileAssigned} />
                    <Route path="/viewCurrentReport/:id" component={ViewCurrentReport} />
                    <Route path="/viewInviteeList" component={ViewInviteeList} />
                </Switch>
            </div>
        </div>
    )
}

export default withRouter(Container)