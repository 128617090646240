import { GET_CUSTOMERS_REQUEST, GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_ERROR, GET_CURRENT_PROFILE_USER_REQUEST, GET_CURRENT_PROFILE_USER_SUCCESS, GET_CURRENT_PROFILE_USER_ERROR } from '../constants/DashboardConstants'

const initialState = {
    loading: false,
    error: '',
    customersList: '',
    currentProfileUser: ''
}

const dashboard = (state = initialState, action) => {
    switch(action.type){
        case GET_CUSTOMERS_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_CUSTOMERS_SUCCESS:
            return{
                ...state,
                loading: false,
                customersList: action.list
            }
        case GET_CUSTOMERS_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case GET_CURRENT_PROFILE_USER_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_CURRENT_PROFILE_USER_SUCCESS:
            return{
                ...state,
                loading: false,
                currentProfileUser: action.list
            }
        case GET_CURRENT_PROFILE_USER_ERROR:
            return{
                loading: false,
                error: action.err
            }
        default:
            return state
    }
}

export default dashboard