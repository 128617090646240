import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Footer from '../../footer/Footer'
import { authHeader, history } from '../../../_helpers'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import { getProfiles, getCurrentProfile, getUserProfile, getCurrentUserResult, getAssigneeList, getUsers, hideCancelledMsg, getResultByProfile } from '../../../actions/ProfileActions'
import ReactLoading from "react-loading"
import { convertDate } from '../date/convertDate'
import _ from 'lodash'
import InviteContainer from '../profile/InviteContainer'
import CancelInviteModal from '../CancelInvite/CancelInviteModal'

const Test = (props) => {
	let pathname = props.location.pathname
	const { currentUser, loading, error, getUserProfile, getProfiles, Profilelist, getCurrentProfile, getCurrentUserResult, currentResult, showInvite, getAssigneeList, getUsers, currentProfile, hideCancelledMsg, getResultByProfile, userResultList } = props
	let uid = localStorage.getItem('uid')
	const [profileId, setProfileId] = useState('')
	const [orderId, setOrderid] = useState('')
	const [profileName, setProfileName] = useState('')
    const [remainingQuantity, setRemainingQuantity] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [showValue, setShowValue] = useState(true)

	useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
				authHeader(pathname)
				getUserProfile(uid)
				getProfiles()
				getAssigneeList()
                getUsers()
                getCurrentUserResult(uid)
            }
            else{
                history.push('/login') 
            }
        }
	},[pathname, getUserProfile, getProfiles, uid, getCurrentUserResult, getAssigneeList, getUsers])

	const renderInvite = (id, order_id, remaining_quantity, name) => {
		getCurrentProfile(id)
		getAssigneeList()
        getUsers()
		setProfileId(id)
		setProfileName(name)
        setOrderid(order_id)
        setShowModal(true)
        setRemainingQuantity(remaining_quantity)
	}

	const getTable = () => {
        return(
            <table id="recent-purchases-listing" className="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Profile Name</th>
						<th>Purchase Date</th>
						<th>User Licence</th>
						<th>Quantity Left</th>
						<th>Actions</th>
                    </tr>
                </thead>
				{currentUser.purchase_plan !== undefined ? getTableBody() : <tbody>No Purchased Profiles Available</tbody>}              
            </table>
        )
	}

	const setOrderId = (order_id, profile_name) => {
		localStorage.setItem('order_id', order_id)
		localStorage.setItem('profile_namee', profile_name)
	}

	const handleClick = (name, profileType, purchasedProfile) => {
		localStorage.setItem('profile_invited_by', undefined)
		localStorage.setItem('current_profile_name', name)
		localStorage.setItem('TestProfileType', profileType)
		localStorage.setItem('purchased_profile', purchasedProfile)
	}

	const renderCancelPopup = (id) => {
		getCurrentProfile(id)
		getResultByProfile(id)
		getAssigneeList()
    	getUsers()
    }
	
    const getTableBody = () => {
		var newCurrentUser = _.clone(currentUser)
		_.forEach(currentUser.purchase_plan_id, function(id){
			_.forEach(newCurrentUser.purchase_plan, function(user){
				if(user.profile_id === id){
					if(Profilelist.length !== 0){
						let test = _.find(Profilelist, ['id', id])
						user['profile_image'] = test.profile_image
						user['profile_name'] =  test.profile_name
						user['profile_type'] = test.profile_type
					}
				}
				return newCurrentUser
			})
        })
        return(
            <tbody>
				{newCurrentUser.purchase_plan.map((list, index) => {
                    let created_date = convertDate(list.purchase_date)
                    const checkList = _.find(currentResult, {'profile_id': list.profile_id, 'invited_by': uid})
                    return(
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <div className="d-flex font-weight-bold"> 
                                    <span className="mr-3"> 
                                        <img src={list.profile_image} alt="" />
                                    </span> 
                                    <span className="pt-3">
                                        {list.profile_name}
                                    </span> 
                                </div>
                            </td>
                            <td>{created_date}</td>
							<td>Pack of {list.quantity}</td>
							<td>{list.remaining_quantity}</td>
							<td>
								<a href={`/testAttempt/${list.profile_id}`} className={list.remaining_quantity === 0 || checkList !== undefined ? "test-btn" : ""} onClick={() => handleClick(list.profile_name, list.profile_type, "purchased_profile")}>
									<button type="button" className="btn btn-secondary btn-sm" disabled={list.remaining_quantity === 0 || checkList !== undefined ? 'disabled' : null}>{checkList === undefined ? 'Start Assessment' : 'Assessment Given'}</button> &nbsp;&nbsp;
								</a>
								<a href={`/viewTraineeProfile/${list.profile_id}`} onClick={() => setOrderId(list.order_id, list.profile_name)}>
									<button type="button" className="btn btn-primary btn-sm">View</button> &nbsp;&nbsp;
								</a>
								<button type="button" className="btn btn-dark btn-sm" disabled={list.remaining_quantity === 0 ? 'disabled' : null} data-toggle="modal" data-target="#invite" onClick={() => renderInvite(list.profile_id, list.order_id, list.remaining_quantity, list.profile_name)}>Assign</button>&nbsp;&nbsp;
								<button type="button" className="btn btn-danger btn-sm" data-toggle="modal" data-target="#cancelInvite" onClick={() => renderCancelPopup(list.profile_id)}>Cancel Invite</button>
							</td>
                        </tr>
                    )
                })}    
            </tbody>
        )
	}
	
	if(props.getUserValue && showValue){
		getUserProfile(uid)
		setShowValue(false)
	}

	const handleClose = () => {
		hideCancelledMsg()
    }
	
    return(
	 <div className="main-panel">
		{loading && showInvite ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> :
    	<div className="content-wrapper pt-3">
			<Breadcrumb title="Profile Library" subTitle="List" />
			{error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
       		<div className="row">
                <div className="col-md-12 stretch-card">
                	<div className="card">
                    	<div className="card-body">
							<h4 className="card-title pt-2">Purchased Profiles</h4>
                    		<div className="table-responsive">
                                {currentUser !== '' ? getTable() : <h3> No Data </h3>}
                    		</div>
                    	</div>
                	</div>
                </div>
            </div>
		</div>}
        {showModal ? <InviteContainer id="invite" profileId={profileId} profileName={profileName} orderId={orderId} remainingQuantity={remainingQuantity} /> : null}
		<div className="modal fade" data-backdrop="static" data-keyboard="false" id="cancelInvite" tabIndex="-1" aria-labelledby="cancelInviteTitle" aria-modal="true" role="dialog">
            {loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : null}
            <div className="modal-dialog modal-dialog-centered modal-cancelled">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <div className="text-center font-weight-bold profile-name">
                            <h3 className="text-center">
                                {currentProfile !== undefined ? currentProfile.profile_name : null}
                            </h3>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    {/* {errorMsg !== '' ? <div className="errorMessage"><h5 className="errorMsg text-center"> {errorMsg} </h5></div> : null}
                    {SuccessMsg !== '' ? <div className="successMessage"><h5 className="successMsg text-center"> {SuccessMsg} </h5></div> : null} */}
                    <div className="modal-body">
                        <div>
                            <form>
                                <CancelInviteModal currentProfile={currentProfile} userResultList={userResultList} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<Footer />
        </div>
			
    )
}

const mapStateToProps = (state) => ({
	loading: state.profileReducer.loading,
    error: state.profileReducer.error,
	currentUser: state.profileReducer.currentUser,
	Profilelist: state.profileReducer.Profilelist,
	currentProfile: state.profileReducer.currentProfile,
	currentResult: state.profileReducer.currentResult,
	showInvite: state.profileReducer.showInvite,
	getUserValue: state.profileReducer.getUserValue,
	userResultList: state.profileReducer.userResultList
})

const mapDispatchToProps = {
	getUserProfile,
	getProfiles,
	getCurrentProfile,
	getCurrentUserResult,
	getAssigneeList, 
	getUsers,
	hideCancelledMsg,
	getResultByProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Test)