import { GET_CUSTOMERS_REQUEST, GET_CURRENT_PROFILE_USER_REQUEST } from '../constants/DashboardConstants'

export const getRegisterCustomers = () => {
    return{
        type: GET_CUSTOMERS_REQUEST
    }
}

export const getCurrentProfileUser = (id) => {
    return{
        type: GET_CURRENT_PROFILE_USER_REQUEST,
        id
    }
}