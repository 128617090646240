import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import { getAllUsers } from '../../../actions/ProfileActions'
import ReactLoading from 'react-loading'
import _ from 'lodash'
import Pagination from 'react-pagination-bootstrap'
import {itemsCountPerPage, pageRangeDisplayed, pageSize} from '../../../constants/HelperConstants'
import { formatMoney } from '../date/convertDate'
import { usePrevious } from '../library/CommonMethods'

const Users = (props) => {
    let pathname = props.location.pathname
    const { getAllUsers, loading, error, userList, count } = props
    const prevList = usePrevious(userList)
    const [searchTerm, setSearchTerm] = useState('')
    const [activePage, setActivePage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getAllUsers(activePage, searchTerm)
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getAllUsers, activePage, searchTerm])

    const handleChange = (event) => {
        const target =  event.target
        const { value } = target
        setSearchTerm(value)
        setShowLoader(false)
        setActivePage(1)
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        setShowLoader(false)
    }

    const getTable = () => {
        return(
            <div className="row">
                <div className="col-md-12 stretch-card">
                    <div className="card">
                        <div className="card-body row">
                            <div className="col-md-9">
                                <h4 className="card-title pt-2">Customers</h4>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" 
                                               className="form-control p-3 text-left" 
                                               placeholder="Search" 
                                               aria-label="Recipient's username"
                                               value={searchTerm}
                                               onChange={handleChange} />
                                        <i className="mdi mdi-magnify search-btn"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table id="recent-purchases-listing" className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Customer Type</th>
                                            <th>Total Revenue</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {prevList !== userList ? getTableBody() : <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} />}
                                </table>
                                <div>
                                    {error === "" && count.length > pageSize ? 
                                    <Pagination activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={count.length}
                                                pageRangeDisplayed={pageRangeDisplayed}
                                                onChange={handlePageChange} />
                                    : error}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getTableBody = () => {
        let total
        let newUser = _.filter(userList, ['type', 'user'])
        let newTrainer = _.filter(userList, ['type', 'trainer'])
        let newItem = []
        newItem = _.concat(newUser, newTrainer)
        if(newItem.length === 0){
            return <tbody> No Customers Found </tbody>
        }
        return(
            <tbody>
                 {newItem.map((item, index) => {
                    let amount = _.sumBy(item.purchase_plan, ('amount'))
                    total = formatMoney(amount)
                    let serialNo = ((activePage - 1) * pageSize) + 1
                    return(
                        <tr key={item.id}>
                            <td>{serialNo + index}</td>
                            <td>{_.isEmpty(_.trim(item.name)) ? '--' : item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.type}</td>
                            <td>&#163;{total === undefined ? '0' : total}</td>
                            <td>{item.user_status === 0 ? <label className="badge badge-danger"> Inactive </label> : <label className="badge badge-success">Active</label>}</td>
                            <td>
                                <a href={`/userDetails/${item.id}`}>
                                    <button type="button" className="btn btn-primary btn-sm"><i className="mdi mdi-eye"></i> &nbsp;User Details</button> 
                                </a>
                            </td>
                        </tr>
                     )
                })}                                         
            </tbody>
        )
    }
    
    return(
        <div className="main-panel">
            {loading && showLoader ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : 
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Customers" subTitle="Customer List" />
                {error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null} 
                {userList !== undefined ? userList.length !== 0 ? getTable() : getTable() :null}        
            </div>
            }
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    userList: state.profileReducer.userList,
    count: state.profileReducer.count
})

const mapDispatchToProps = {
    getAllUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)