import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_CUSTOMERS_REQUEST, GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_ERROR, GET_CURRENT_PROFILE_USER_REQUEST, GET_CURRENT_PROFILE_USER_SUCCESS, GET_CURRENT_PROFILE_USER_ERROR } from '../constants/DashboardConstants'
import { db } from '../components/firebase'

async function getList(){
    let array = []
    var currentDate = new Date()
    var oldDate = new Date()
    oldDate.setDate(1)
    oldDate.setMonth(oldDate.getMonth() + 1)
    oldDate.setFullYear(oldDate.getFullYear()-1)
    let customerList = db.collection("users").where("created_date_time", ">", oldDate).where("created_date_time", "<=", currentDate)
    await customerList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getCustomersData(){
    try{
        const list = yield call(getList)
        yield put({ type: GET_CUSTOMERS_SUCCESS, list })
    }
    catch(error){
        const err = "Error while getting customers list"
        yield put({ type: GET_CUSTOMERS_ERROR, err })
    }
}

async function getData(id){
    let array = []
    let customerList = db.collection("users").where("purchase_plan_id", "array-contains", id)
    await customerList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getCurrentData(data){
    try{
        const { id } = data
        const list = yield call(getData, id)
        yield put({ type: GET_CURRENT_PROFILE_USER_SUCCESS, list })
    }
    catch(error){
        const err = "Error while getting list"
        yield put({ type: GET_CURRENT_PROFILE_USER_ERROR, err })
    }
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_CUSTOMERS_REQUEST, getCustomersData)
      yield takeLatest(GET_CURRENT_PROFILE_USER_REQUEST, getCurrentData)
    }
    return {watcher}
}

