import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Footer from '../../footer/Footer'
import { authHeader, history } from '../../../_helpers'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import AdminDashboardHeader from './AdminDashboardHeader'
import AdminDashboardContent from './AdminDashboardContent'
import './Dashboard.scss'
import { getRegisterCustomers, getCurrentProfileUser } from '../../../actions/DashboardActions'
import { getProfiles, getAllUsers } from '../../../actions/ProfileActions'
import ReactLoading from "react-loading"
import _ from 'lodash'
import AdminNewCustomerGraph from './AdminNewCustomerGraph'
import AdminProfilePurchasedGraph from './AdminProfilePurchasedGraph'
import { renderMonthDiff } from '../date/convertDate'
import { useState } from 'react'
import { getMaxValue } from '../library/CommonMethods'
import { formValueSelector } from 'redux-form'

const Dashboard = (props) => {
	let pathname = props.location.pathname
	const [Value, setValue] = useState("Select")
	const { getRegisterCustomers, loading, error, customersList, getProfiles, Profilelist, getCurrentProfileUser, currentProfileUser, getAllUsers } = props
	let uid = localStorage.getItem('uid')

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
				authHeader(pathname)
				getRegisterCustomers()
				getProfiles()
				getAllUsers()
            }
            else{
                history.push('/login') 
            }
        }
	},[pathname, getRegisterCustomers, getProfiles, getAllUsers])

	const selectProfile = (event) => {
		const { value } = event.target
		setValue(value)
		if(value !== 'Select'){
            getCurrentProfileUser(value)
        }
	}

	const renderGraph = () => {
		var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
		let array = [], newCustomers = [], newArray = [], profilePurchased = [], newCustomersValue =  [], newProfilePurchasedValue = [], newProfileList = [], totalProfilePurchased = [], purchased_profile_id = [], newProfilePurchased = []
		var currentDate = new Date()
		var oldDate = new Date()
		oldDate.setDate(1)
		oldDate.setMonth(oldDate.getMonth() + 1)
		oldDate.setFullYear(oldDate.getFullYear()-1)
		const diff = renderMonthDiff(oldDate, currentDate)
		let newCustomer = 0
		if(!_.isEmpty(customersList)){
			_.forEach(customersList, function(list){
				if(list.id === uid){}
				else{
					var date = new Date(list.created_date_time.seconds * 1000)
					const monthName = date.getMonth()
					var month =  monthNames[monthName].toString().substr(0, 3)
					array.push({ 'month': month + " " + date.getFullYear().toString().substr(-2) })
				}
			})
		}
		_.forEach(diff, function(month){
			const count = array.filter((obj) => obj.month === month).length
			if(count >0){
				newCustomer = 1
			}
			newCustomers.push([month, count])
			newCustomersValue.push({ 'label': month, 'y': count })
		})
		const maximumValue = getMaxValue(newCustomersValue)
		if(!_.isEmpty(currentProfileUser)){
			_.forEach(currentProfileUser, function(user){
				_.forEach(user.purchase_plan, function(plan){
					if(plan.profile_id === Value){
						var date = new Date(plan.purchase_date.seconds * 1000)
						const monthName = date.getMonth()
						var month =  monthNames[monthName].toString().substr(0, 3)
						newArray.push({ 'month': month + " " + date.getFullYear().toString().substr(-2) })
					}
				})
			})
		}
		let newCount = 0
		if(Value !== 'Total Profiles'){
			_.forEach(diff, function(month){
				const count = newArray.filter((obj) => obj.month === month).length
				if(count >0){
					newCount = 1
				}
				profilePurchased.push([month, count])
				newProfilePurchasedValue.push({ 'label': month, 'y': count })
			})
		}
		else{
			_.forEach(Profilelist, function(profile){
				let wordArray= _.words(profile.profile_name)
				let ProfileName = ""
				if(wordArray.length > 1){
					_.forEach(wordArray, function(value){
						ProfileName += value[0]
					})
				}
				else{
					ProfileName = wordArray[0]
				}
				totalProfilePurchased.push({ 'profile_name': ProfileName, 'id': profile.id })
			})
			_.forEach(customersList, function(customer){
				if(customer.purchase_plan !== undefined){
					_.forEach(customer.purchase_plan, function(plan){
						_.forEach(totalProfilePurchased, function(profiles){
							if(profiles.id === plan.profile_id){
								var date = new Date(plan.purchase_date.seconds * 1000)
								const monthName = date.getMonth()
								var month =  monthNames[monthName].toString().substr(0, 3)
								purchased_profile_id.push({ 'purchased_profile_id': plan.profile_id, 'profile_name': profiles.profile_name })
								newArray.push({ 'month': month + " " + date.getFullYear().toString().substr(-2), 'profile_id': plan.profile_id })
							}
						})
					})
				}
			})
			let uniqueProfiles = _.uniqBy(purchased_profile_id, 'purchased_profile_id')
			_.forEach(uniqueProfiles, function(list){
				_.forEach(diff, function(month){
					const count = newArray.filter((obj) => obj.month === month && obj.profile_id === list.purchased_profile_id).length
					if(count >0){
						newCount = 1
					}
					newProfilePurchased.push({ 'id': list.purchased_profile_id, 'month': month, 'count': count, 'profile_name': list.profile_name })
				})
			})
		}
		let output
		output = newProfilePurchased.reduce(function(o, cur) {
			// Get the index of the key-value pair.
			var occurs = o.reduce(function(n, item, i) {
			  return (item.profile_name === cur.profile_name) ? i : n;
			}, -1)
			// If the name is found,
			if (occurs >= 0) {
			  // append the current value to its list of values.
			  o[occurs].count = o[occurs].count.concat(cur.count);
			//   o[occurs].profile_name = o[occurs].profile_name.concat(cur.profile_name)
			  // Otherwise,
			} else {
			  // add the current item to o (but make sure the value is an array).
			  var obj = {
				count: [cur.count],
				profile_name: cur.profile_name,
			  };
			  o = o.concat([obj])
			}
			return o;
		}, [])
		let arrr = [], newOutput = []
		_.forEach(output, function(item){
			let length = item.count.length
			let arr = item.count
			if(length > 12){
				let divideArray = _.chunk(arr, 12)
				_.forEach(divideArray, function(item, index){	
					_.forEach(item, function(value, indexx){
						if(index === 0){
							arrr[indexx] = item[indexx]
						}
						else{
							arrr[indexx] = arrr[indexx] + item[indexx]
						}
					})
				})
				newOutput.push({
					'count': arrr,
					'profile_name': item.profile_name
				})
			}
			else{
				newOutput.push({
					'count': item.count,
					'profile_name': item.profile_name
				})
			}
		})
		_.forEach(newOutput, function(items){
			let color 
			if(items.profile_name === "LSP"){
				color = 'rgba(90,155,212,.5)'
			}
			if(items.profile_name === "PLP"){
				color = 'rgba(241,90,96,.5)'
			}
			profilePurchased.push({
				'name': items.profile_name,
				data: items.count,
				color: color,
				showInLegend: false
			})
		})
		let maxvalue
		_.forEach(newOutput, function(items){
			let getmaxCount = output[0].count
			let oldMax = _.max(getmaxCount)
			let newMax = _.max(items.count)
			if(oldMax < newMax){
				maxvalue = newMax
			}
			else{
				maxvalue = oldMax
			}
		})
		const maximumPurchasedValue = getMaxValue(newProfilePurchasedValue)
		_.forEach(Profilelist, function(list){
			newProfileList.push(list)
		})
		newProfileList.push({ 'id': 'Total Profiles', 'profile_name': 'Total Profiles' })
		return(
			<div className="row mb-4">
				<div className="col-md-6 profile-purchased-graph">
					<div className="customBtnuser text-right">
						<div className="form-group">
							<div className="selectUsercombo mt-3 mr-2 row">
								<div className="choose-profile">
									<label>Choose Profile: </label>
								</div>
								<div>
									<select className="form-control" id="exampleFormControlSelect1" onChange={selectProfile}>
										<option value="Select" selected>
											Select Profile
										</option>
										{newProfileList.map(list => {
											return(
												<option key={list.id} value={list.id}>
													{list.profile_name}
												</option>
											)
										})}
									</select>
								</div>
							</div>
                        </div>
					</div>
					{newCount === 1 ? 
					<div className="graph">
						{Value !== 'Total Profiles' ?
						Value === "Select" ? 
						<div className="text-center profile-text">
							<p>This will show Profile purchased history for last 12 Months, Please select Profile to view history</p>
						</div> : 
						<AdminProfilePurchasedGraph profilePurchased={profilePurchased} maximumPurchasedValue={maximumPurchasedValue} profileValue="Profile" />
						:<AdminProfilePurchasedGraph profilePurchased={profilePurchased} maximumPurchasedValue={maxvalue} profileValue="TotalProfiles" categories={diff} />}
						</div>
					:
					<div className="text-center profile-text">
						<p>No Profile Purchased History found for Last 12 Months</p>
					</div>
					}
				</div>
				{newCustomer === 1 ? 
				<div className="col-md-6">
					<div className="graph customer-graph">
						<AdminNewCustomerGraph newCustomers={newCustomers} maximumValue={maximumValue} /> 
					</div>
				</div>
				: <div className="col-md-6 stretch-card">
					<div className="card">
						<div className="card-body row newRegister">
							<div className="col-md-12 text-center profile-text">
								<p>No new customers is registered for Last 12 Months</p>
							</div>
						</div>
					</div>
				</div>}
			</div>
		)
	}

    return(
        <div className="main-panel">
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Dashboard" subTitle="Users" />
				{error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
                <AdminDashboardHeader />
				{renderGraph()}
				{loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> : null}
				<AdminDashboardContent />
			</div>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
	loading: state.dashboardReducer.loading,
	error: state.dashboardReducer.error,
	customersList: state.dashboardReducer.customersList,
	Profilelist: state.profileReducer.Profilelist,
	currentProfileUser: state.dashboardReducer.currentProfileUser
})

const mapDispatchToProps = {
	getRegisterCustomers,
	getProfiles,
	getCurrentProfileUser,
	getAllUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)