import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getProfiles, getCancelInvitationList, getUsers } from '../../../actions/ProfileActions'
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import ReactLoading from "react-loading"
import _ from 'lodash'
import Pagination from 'react-pagination-bootstrap'
import {itemsCountPerPage, pageRangeDisplayed, pageSize} from '../../../constants/HelperConstants'
import { usePrevious } from '../library/CommonMethods'
import { convertDateTime } from '../date/convertDate'

const ViewInviteeList = (props) => {
    const { loading, error, getProfiles, Profilelist, getCancelInvitationList, cancelInviteeList, count, getUsers, usersList } = props
    const [activePage, setActivePage] = useState(1)
    const prevList = usePrevious(cancelInviteeList)
    let type = localStorage.getItem('type')

    useEffect(() => {
       getProfiles()
       getCancelInvitationList(activePage, "")
       getUsers()
    },[getProfiles, getCancelInvitationList, activePage, getUsers])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    const getTable = () => {
        return(
            <table id="recent-purchases-listing" className="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Assignee</th>
                        <th>Email</th>
                        <th>Profile Name</th>
                        {type === "Admin" ? <th>Cancelled By</th> : null}
                        <th>Cancelled Date</th>
                    </tr>
                </thead>
                {prevList !== cancelInviteeList ? getTableBody() : <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} />}                   
            </table>
        )
    }

    const getTableBody = () => {
        let newList = []
        let uid = localStorage.getItem('uid')
        let cancelled_paramter = localStorage.getItem('cancelled_paramter')
        let current_profile_id = localStorage.getItem('current_profile_id')
        _.forEach(cancelInviteeList, function(list){
            _.forEach(list.invitations, function(item){
                if(type === "trainer"){
                    if(item.invited_by === uid){
                        if(cancelled_paramter === "sidebar"){
                            newList.push({ 'name': list.name, 'email': list.email, 'user_id': list.id, 'profile_id': item.profile_id, 'created_date_time': list.created_date_time, 'invited_by': item.invited_by })
                        }
                        else{
                            if(current_profile_id === item.profile_id){
                                newList.push({ 'name': list.name, 'email': list.email, 'user_id': list.id, 'profile_id': item.profile_id, 'created_date_time': list.created_date_time, 'invited_by': item.invited_by })
                            }
                        }
                    }
                }
                else{
                    if(cancelled_paramter === "sidebar"){
                        newList.push({ 'name': list.name, 'email': list.email, 'user_id': list.id, 'profile_id': item.profile_id, 'created_date_time': list.created_date_time, 'invited_by': item.invited_by })
                    }
                    else{
                        if(current_profile_id === item.profile_id){
                            newList.push({ 'name': list.name, 'email': list.email, 'user_id': list.id, 'profile_id': item.profile_id, 'created_date_time': list.created_date_time, 'invited_by': item.invited_by })
                        }
                    }
                }
            })
        })
        _.forEach(newList, function(list){
            _.forEach(Profilelist, function(profile){
                if(profile.id === list.profile_id){
                    list['profile_name'] = profile.profile_name
                }
            })
        })
        _.forEach(newList, function(list){
            _.forEach(usersList, function(user){
                if(user.id === list.invited_by){
                    list['cancelled_by'] = user.name
                }
            })
        })
        if(newList.length === 0){
            return <tbody> No Invitee Cancelled </tbody>
        }
        return(
            <tbody>
                {newList.map((list, index) => {
                    let CancelledDate = convertDateTime(list.created_date_time)
                    return(
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{list.name !== undefined ?  !_.isEmpty(_.trim(list.name)) ? _.capitalize(list.name) : '--' : '--'}</td>
                            <td>{list.email}</td>
                            <td>{list.profile_name}</td>
                            {type === "Admin" ? <td>{_.isEmpty(_.trim(list.cancelled_by)) ? '--' : _.capitalize(_.trim(list.cancelled_by))}</td> : null} 
                            <td>{CancelledDate}</td>
                        </tr>
                    )
                })}                          
            </tbody>
        )
    }

    return(
        <div className="main-panel">
            {loading ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> :
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Profile" subTitle="Invitee List" />
                {error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
                <div className="row">
                    <div className="col-md-12 stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title pt-2">Cancelled Invitees</h4>
                                <div className="table-responsive">
                                    {cancelInviteeList !== undefined ? cancelInviteeList.length !== 0 ? getTable() : <h3> No Invitee Cancelled </h3> :null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {error === "" && count.length > pageSize ? 
                    <Pagination activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={count.length}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange} />
                    : error}
                </div> 
			</div>}
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    Profilelist: state.profileReducer.Profilelist,
    cancelInviteeList: state.profileReducer.cancelInviteeList,
    count: state.profileReducer.count,
    usersList: state.profileReducer.usersList
})

const mapDispatchToProps = {
    getProfiles,
    getCancelInvitationList,
    getUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewInviteeList)