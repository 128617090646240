import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { authHeader, history } from '../../../_helpers'
import './Profile.scss'
import Footer from '../../footer/Footer'
import Breadcrumb from '../../breadcrumb/Breadcrumb'
import ReactLoading from 'react-loading'
import { getCurrentProfile, sendInvite, updateInviteStatus, getUserProfile, getAllUsers, getAssigneeList, updateQuantity } from '../../../actions/ProfileActions'
import _ from 'lodash'
import { convertDate, formatMoney } from '../date/convertDate'
import { validate } from '../validate/EmailValidate'
import Pagination from 'react-pagination-bootstrap'
import {itemsCountPerPage, pageRangeDisplayed, pageSize} from '../../../constants/HelperConstants'
import { usePrevious } from '../library/CommonMethods'

const ViewTraineeProfile = (props) => {
    let pathname = props.location.pathname
    let { id } = props.match.params
    let uid = localStorage.getItem('uid')
    const { currentProfile, getCurrentProfile, loading, error, getUserProfile, currentUser, getAllUsers, userList, getAssigneeList, assigneeList, sendInvite, inviteMsg, count, updateQuantity, profileErrorMsg } = props
    const prevList = usePrevious(assigneeList)
    const [inviteValue, setInviteValue] = useState('')
    const [customerArray, setCustomers] = useState('')
    const [msg, setMsg] = useState('')
    const [activePage, setActivePage] = useState(1)
    const [showLoader, setShowLoader] = useState(true)
    const [Update, setUpdate] = useState(true)
    let type = localStorage.getItem('type')
    let orderId = localStorage.getItem('order_id')
    let profileName = localStorage.getItem('profile_namee')

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getCurrentProfile(id)
                getUserProfile(uid)
                getAllUsers()
                getAssigneeList(activePage)
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getCurrentProfile, getUserProfile, getAllUsers, getAssigneeList, id, uid, activePage])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        setShowLoader(false)
    }

    const getDetails = () => {
        if(currentProfile === '' || currentProfile === undefined){
            return null
        }
        let order_id = localStorage.getItem('order_id')
        let obj = {}, user
        if(currentUser !== ''){
            user = currentUser.purchase_plan.filter((item) =>  item.order_id === order_id && item.profile_id === id)
        }
        _.forEach(user, function(item){
            obj['quantity'] = item.quantity
            obj['amount'] = item.amount
        })
        const total = formatMoney(obj.amount)
        return(
            <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="chartjs-size-monitor">
                            <div className="chartjs-size-monitor-expand">
                                <div className=""></div>
                            </div>
                            <div className="chartjs-size-monitor-shrink">
                                <div className=""></div>
                            </div>
                        </div>
                        <div className="card-body testName">
                            <img src={currentProfile.profile_image} style={{ width: '100px', height: '100px' }} alt=" " />
                            <p className="text-muted"> 
                                <span className="font-weight-bold"> Name of the Profile :</span> 
                                &nbsp;&nbsp;&nbsp;{currentProfile.profile_name}
                            </p>
                            <p className="text-muted"> 
                                <span className="font-weight-bold"> Quantity Purchase :</span> 
                                &nbsp;&nbsp;&nbsp; {obj.quantity}
                            </p>
                            <p className="text-muted"> 
                                <span className="font-weight-bold"> Total Price :</span> 
                                &nbsp;&nbsp;&nbsp;&#163;{total}
                            </p>
                            {/* <p className="text-muted"> 
                                <span className="font-weight-bold"> Discount :</span> 
                                &nbsp;&nbsp;&nbsp; &#163; 
                            </p> */}
                            <div className="decp">
                                <p> 
                                    <span className="font-weight-bold"> Description :</span> 
                                    &nbsp;&nbsp;&nbsp;{currentProfile.profile_description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body row">
                        <div className="col-md-8">
                            <h4 className="card-title mb-0">ASSESSMENT DETAILS</h4>
                        </div>
                        <div className="col-md-4">
                            <a href={`/viewTest/${id}`}>
                                <button type="button" className="btn btn-primary float-right">
                                    View Assessment
                                </button>
                            </a>
                        </div>
                            <div className="table-responsive pt-3">
                                <table className="table table-bordered test-d">
                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">No. of Question</td>
                                            <td>{currentProfile.profile_total_questions}</td>                         
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">No. of options in each question</td>
                                            <td>{currentProfile.profile_display_option}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">No. of profile results</td>
                                            <td>{currentProfile.profile_result_option}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Answer Type</td>
                                            <td>Rating from 0 to 5</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderAssignedPeople = () => {
        return(
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-0">Assigned People</h4>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>User</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Assigned Date</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {assigneeList !== undefined ? assigneeList.length !== 0 ?renderAssigneeTableBody() : <tbody>No Assignee People Available</tbody> : null}
                                </table>
                                <div>
                                    {error === "" && count.length > pageSize ? 
                                    <Pagination activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={count.length}
                                                pageRangeDisplayed={pageRangeDisplayed}
                                                onChange={handlePageChange} />
                                    : error}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const setName = (user_name, currentUser_id, invitedById) => {
        const { profile_name, profile_type } = currentProfile
        localStorage.setItem('report_profile_name', profile_name)
        localStorage.setItem('report_profile_type', profile_type)
        localStorage.setItem('report_profile_id', id)
        localStorage.setItem('report_currentUser_id', currentUser_id)
        localStorage.setItem('report_user_name', user_name)
        localStorage.setItem('report_invited_by_id', invitedById)
    }

    const renderAssigneeTableBody = () => {
        let newArray = []
        _.forEach(assigneeList, function(data){
            let test = _.filter(data.invited_plan, { "profile_id": id, "invited_by": uid })
            newArray.push({ 'id': data.id, 'invited_plan': test, 'invited_by_id': data.invited_by_id })
        })
        let newList = []
        _.forEach(newArray, function(item){
            _.forEach(userList, function(user){
                if(item.id === user.id && user.user_status === 1){
                    newList.push({ 'user_details': user, 'invitations': item })
                }
            })
        })
        if(prevList !== assigneeList){
            if(newList.length !== 0){
                return(
                    <tbody>
                        {newList.map((list, listIndex)  => {
                            let userName
                            if(!_.isEmpty(_.trim(list.user_details.name))){
                                userName = _.capitalize(_.trim(list.user_details.name))
                            }
                            else{
                                userName = list.user_details.email
                            }
                            return list.invitations.invited_plan.map((item, index) => {
                                let assigned_date = convertDate(item.invited_date)
                                let serialNo = ((activePage - 1) * pageSize) + 1
                                if(list.user_details.user_status === 1){
                                    return(
                                        <tr key={index}>
                                            <td>{serialNo + listIndex}</td>
                                            <td><img src={list.user_details.user_img_url} alt="" /></td>
                                            <td>{!_.isEmpty(_.trim(list.user_details.name)) ? list.user_details.name : '--'}</td>
                                            <td>{list.user_details.email}</td>
                                            <td>{assigned_date}</td>
                                            <td>
                                                <a href={`/viewCurrentReport/${id}`} onClick={() => setName(userName, list.user_details.id, item.invited_by)}>
                                                    <button type="button" className="btn btn-primary btn-sm">View Report</button> 
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                }
                                else{
                                    return <tr key={index}><td>No Assignee People Available</td></tr>
                                }
                            })
                        })}
                    </tbody>
                )
            }
            else{
                return <tbody>No Assignee People Available</tbody>
            }
        }
        else{
            return <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} />
        }
    }

    const handleChange = (event) => {
        const { value } = event.target
        let checkEmail = validate(value)
        if(checkEmail){
            setInviteValue(value)
            setMsg('')
        }
        else{
            if(inviteValue === ''){
                setMsg('Please enter a valid email address')
            }
            else{
                setMsg('Invite can be send to one user only at a time')
            }
        }
    }

    const renderSendInvite = (event) => {
        event.preventDefault()
        const newUrl = window.location.href.split('/')
        var domainUrl = newUrl[0] + "//" + newUrl[2]
        let newArray = inviteValue.split(',')
        let customers = []
        if(inviteValue !== ''){
            if(newArray.length === 0){
                setMsg('Please enter invitee email address')
            }
            else if(newArray.length === 1 && msg === ''){
                const user = _.find(userList, ['email', inviteValue])
                if(user === undefined){
                    _.forEach(newArray, function(item){
                        customers.push({ 'email': item, 'phone': '', 'first_name': '', 'last_name': '', 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                    })
                    sendInvite(customers, id)
                    setCustomers(customers)
                    setInviteValue('')
                }
                else{
                    const assignee = _.find(assigneeList, ['id', user.id])
                    if(assignee === undefined){
                        _.forEach(newArray, function(item){
                            customers.push({ 'email': item, 'phone': '', 'first_name': '', 'last_name': '', 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                        })
                        sendInvite(customers, id)
                        setCustomers(customers)
                        setInviteValue('')
                    }
                    else{
                        const invited_plan = _.find(assignee.invited_plan, ['invited_by', uid])
                        if(invited_plan.invitation_status === 0 || invited_plan.invitation_status === 1){
                            setMsg('User is already invited')
                        }
                        else if(invited_plan.invitation_status === 2){
                            _.forEach(newArray, function(item){
                                customers.push({ 'email': item, 'phone': '', 'first_name': '', 'last_name': '', 'profile_test_name': profileName, 'profile_test_url': domainUrl })
                            })
                            sendInvite(customers, id)
                            setCustomers(customers)
                            setInviteValue('')
                            // let data = {
                            //     'invited_date': new Date(),
                            //     'invitation_status': 0
                            // }
                            // updateInviteStatus(data, assignee.id)
                        }
                        else{
                            setMsg('')
                        }
                    }
                }
            }
            else{
                setMsg('Invite can be send to one user only at a time')
            }
        }
        else{
            setMsg('Please enter invitee email address')
        }
    }

    const renderInvitePeople = () => {
        const user = _.find(userList, ['id', uid])
        let order_id = localStorage.getItem('order_id')
        let newList
        if(user !== undefined){
            newList = _.find(user.purchase_plan, { 'profile_id': id, 'order_id': order_id })
        }
        return(
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            {msg !== '' ? <h5 className="text-center errorMsg"> {msg} </h5> : null}
                            {inviteMsg !== '' ? <h5 className="text-center successMsg"> {inviteMsg} </h5> : null}
                            {/* {profileErrorMsg !== '' ? <h5 className="text-center errorMsg"> {profileErrorMsg} </h5> : null} */}
                            <h4 className="card-title">Invite New Member</h4>                  
                            <div className="form-group">
                                <div className="input-group">
                                    <input type="text" className="form-control text-left" placeholder="email@example.com" aria-label="Invite New Member" defaultValue={inviteValue} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <button className="btn btn-sm btn-primary" type="button" onClick={renderSendInvite} disabled={newList !== undefined ? newList.remaining_quantity === 0 ? 'disabled' : null : null}>Invite  Member</button>
                                    </div>
                                </div>
                            </div>  
                            <div className="table-responsive pt-3">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>User</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Assigned Date</th>
                                            <th>Status</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    {assigneeList !== undefined ? assigneeList.length !==0 ? renderInviteTableBody() : <tbody>No Invitee Found</tbody> : null}
                                </table>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        )
    }

    const renderInviteTableBody = () => {
        let newArray = []
        _.forEach(assigneeList, function(data){
            let test = _.filter(data.invited_plan, ["profile_id", id])
            newArray.push({ 'id': data.id, 'invited_plan': test, 'invited_by_id': data.invited_by_id })
        })
        let newList = []
        _.forEach(newArray, function(item){
            _.forEach(userList, function(user){
                if(item.id === user.id && user.user_status === 0){
                    newList.push({ 'user_details': user, 'invitations': item })
                }
            })
        })
        if(newList.length !== 0){
            return(
                <tbody>
                    {newList.map((list)  => {
                        return list.invitations.invited_plan.map((item, index) => {
                            let assigned_date = convertDate(item.invited_date)
                            if(list.user_details.user_status === 0){
                                return(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td><img src={list.user_details.user_img_url} alt="" /></td>
                                        <td>{!_.isEmpty(_.trim(list.user_details.name)) ? list.user_details.name : '--'}</td>
                                        <td>{list.user_details.email}</td>
                                        <td>{assigned_date}</td>
                                        <td>
                                            <label className="badge badge-danger">Pending</label>
                                        </td>
                                        {/* <td>
                                            {item.invitation_status === 0 ? <button className="btn btn-sm btn-secondary">Cancel Invite</button> : null}
                                        </td> */}
                                    </tr>
                                )
                            }
                            else{
                                return <tr key={index}><td>No Invitee Found</td></tr>
                            }
                        })
                    })}
                </tbody>
            )
        }
        else{
            return <tbody>No Invitee Found</tbody>
        }
    }

    if(inviteMsg !== '' && type === "trainer" && Update){
        updateQuantity(id, orderId, customerArray)
        setUpdate(false)
    }

    return(
        <div className="main-panel">
            {loading && showLoader ? <ReactLoading className="loader-container profile-loader" type="spin" color="#007bff" height={30} width={30} /> :
            <div className="content-wrapper pt-3">
                <Breadcrumb title="Profile Library" subTitle="Trainee Details" />
                {error !== "" ? <h4 className="errorMsg text-center"> {error} </h4> : null}
                {getDetails()}          
                {renderAssignedPeople()}
                {renderInvitePeople()}
            </div>
            }
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.profileReducer.loading,
    error: state.profileReducer.error,
    currentUser: state.profileReducer.currentUser,
    currentProfile: state.profileReducer.currentProfile,
    userList: state.profileReducer.userList,
    assigneeList: state.profileReducer.assigneeList,
    inviteMsg: state.profileReducer.inviteMsg,
    count: state.profileReducer.count,
    profileErrorMsg: state.profileReducer.profileErrorMsg
})

const mapDispatchToProps = {
    getCurrentProfile,
    getUserProfile,
    getAllUsers,
    getAssigneeList,
    sendInvite,
    updateInviteStatus,
    updateQuantity
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTraineeProfile)