import React, { useEffect } from 'react'
import _ from 'lodash'
import { formatMoney } from '../date/convertDate'

const UserDetailsPopup = (props) => {
    const { Profilelist, orderId, amount, idArray } = props

    useEffect(() => {
        window.$('#viewOrder').modal('show')
    })

    if(Profilelist === undefined || Profilelist.length === 0){
        return null
    }
    else{
        let newArray = []
        idArray.map(id => {
            newArray.push(_.find(Profilelist, ['id', id]))
        })
        _.forEach(newArray, function(item, key){
            item['amount'] = amount[key]
        })
        return(
            <div className="modal fade" id="viewOrder" tabIndex="-1" aria-labelledby="viewOrderTitle"  aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <h3 className="text-center pt-4"> Order Id: #{orderId}</h3>
                        <button type="button" className="close btn-model-close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: '6.5rem' }}>
                            <span aria-hidden="true">×</span>
                        </button>
                        {newArray.map(item => {
                            const total = formatMoney(item.amount)
                            return(
                                <div className="modal-header border-0 modaltextInfo" key={item.id}>
                                    <div className="d-flex font-weight-bold"> 
                                        <div className="col-sm-3 mr-2"> <img src={item.profile_image} alt="" /></div> 
                                        <div className="pt-0">
                                            <h3 className="d-block pb-1 text-center">{item.profile_name}</h3> 
                                            <div className="modal-body">              
                                                <p> Profile SKU: &nbsp; <span className="viewUserPopup">{item.Profile_SKU}</span></p>
                                                <p> Amount: &nbsp; <span className="viewUserPopup">&#163;{total}</span></p> 
                                                <p> Profile Description: &nbsp; <span className="viewUserPopup">{item.profile_description}</span></p>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default UserDetailsPopup