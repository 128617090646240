import { GET_PROFILES_REQUEST, GET_CURRENT_PROFILE_REQUEST, GET_USERS_REQUEST, GET_CURRENT_QUESTIONS_REQUEST, SEND_INVITE_REQUEST, UPDATE_INVITE_STATUS_REQUEST, UPDATE_QUANTITY_REQUEST, GET_ASSIGNEE_LIST_REQUEST, GET_PROFILE_INVITATION_REQUEST, GET_USER_PROFILE_REQUEST, UPDATE_USER_PROFILE_REQUEST, GET_ALL_USERS_REQUEST, ADD_TEST_RESULT_REQUEST, GET_USER_RESULT_REQUEST, GET_CURRENT_RESULT_REQUEST, GET_GENERAL_NOTES_REQUEST, GET_GUIDELINES_REQUEST, ADD_PRODUCT_REQUEST, UPDATE_PRODUCT_REQUEST, DELETE_PRODUCT_REQUEST, GET_INSTRUCTIONS_REQUEST, CANCEL_INVITE_REQUEST, GET_CANCEL_INVITATION_LIST, HIDE_CANCELLED_MSG, GET_RESULT_BY_PROFILE_REQUEST, UPDATE_ASSESSMENT_VALUE_REQUEST, REMOVE_PROFILE_ERROR_MSG } from '../constants/ProfileConstants'

export const getCurrentQuestions = (id) => {
    return{
        type: GET_CURRENT_QUESTIONS_REQUEST,
        id
    }
}

export const getProfiles = () => {
    return{
        type: GET_PROFILES_REQUEST
    }
}

export const getCurrentProfile = (id) => {
    return{
        type: GET_CURRENT_PROFILE_REQUEST,
        id
    }
}

export const getUsers = (id, activePage) => {
    return{
        type: GET_USERS_REQUEST,
        id,
        activePage
    }
}

export const sendInvite = (customers, profile_id) => {
    return{
        type: SEND_INVITE_REQUEST,
        customers, 
        profile_id
    }
}

export const updateInviteStatus = (items, id) => {
    return{
        type: UPDATE_INVITE_STATUS_REQUEST,
        items,
        id
    }
}

export const updateQuantity = (profileId, orderId, customers) => {
    return{
        type: UPDATE_QUANTITY_REQUEST,
        profileId,
        orderId,
        customers
    }
}

export const getAssigneeList = (activePage, searchTerm) => {
    return{
        type: GET_ASSIGNEE_LIST_REQUEST,
        activePage,
        searchTerm
    }
}

export const getProfileInvitation = (id, activePage, searchTerm) => {
    return{
        type: GET_PROFILE_INVITATION_REQUEST,
        id,
        activePage, 
        searchTerm
    }
}

export const getUserProfile = (id) => {
    return{
        type: GET_USER_PROFILE_REQUEST,
        id
    }
}

export const updateUserProfile = (data) => {
    return{
        type: UPDATE_USER_PROFILE_REQUEST,
        data
    }
}

export const getAllUsers = (activePage, searchTerm) => {
    return{
        type: GET_ALL_USERS_REQUEST,
        activePage, 
        searchTerm
    }
}

export const addTestResult = (result) => {
    return{
        type: ADD_TEST_RESULT_REQUEST,
        result
    }
}

export const getUserResult = (profile_id, user_id) => {
    return{
        type: GET_USER_RESULT_REQUEST,
        profile_id,
        user_id
    }
}

export const getCurrentUserResult = (id) => {
    return{
        type: GET_CURRENT_RESULT_REQUEST,
        id
    }
}

export const getGeneralNotes = (profileType) => {
    return{
        type: GET_GENERAL_NOTES_REQUEST,
        profileType
    }
}

export const getGuidelines = (profileType) => {
    return{
        type: GET_GUIDELINES_REQUEST,
        profileType
    }
}

export const addProductToShopify = (CurrentProductData) => {
    return{
        type: ADD_PRODUCT_REQUEST,
        CurrentProductData
    }
}

export const updateProductToShopify = (CurrentProductData) => {
    return{
        type: UPDATE_PRODUCT_REQUEST,
        CurrentProductData
    }
}

export const deleteProductToShopify = (CurrentProductData) => {
    return{
        type: DELETE_PRODUCT_REQUEST,
        CurrentProductData
    }
}

export const getInstructions = (profileType) => {
    return{
        type: GET_INSTRUCTIONS_REQUEST,
        profileType
    }
}

export const cancelInvite = (json, user_id, invitations, user_status, usersList, current_profile_name, cancelUserType) => {
    return{
        type: CANCEL_INVITE_REQUEST,
        json, 
        user_id,
        invitations,
        user_status,
        usersList,
        current_profile_name,
        cancelUserType
    }
}

export const getCancelInvitationList = (activePage, searchTerm) => {
    return{
        type: GET_CANCEL_INVITATION_LIST,
        activePage,
        searchTerm
    }
}

export const hideCancelledMsg = () => {
    return{
        type: HIDE_CANCELLED_MSG
    }
}

export const getResultByProfile = (profileId) => {
    return{
        type: GET_RESULT_BY_PROFILE_REQUEST,
        profileId
    }
}

export const updateAssessmentValue = (profile_id, user_id) => {
    return{
        type: UPDATE_ASSESSMENT_VALUE_REQUEST,
        profile_id,
        user_id
    }
}

export const removeProfileErrorMsg = () => {
    return{
        type: REMOVE_PROFILE_ERROR_MSG
    }
}