import React from 'react'

const Footer = () => {
    return(
        <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2020 LPT Productions. All rights reserved.
                    {/* <a href="https://www.bootstrapdash.com/" rel="noopener noreferrer" target="_blank">Leadership</a>. */}
                </span>
            </div>
        </footer>
    )
}

export default Footer