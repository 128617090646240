import React from 'react'
import _ from 'lodash'
import { convertDate, formatMoney } from '../date/convertDate'

const UserDetailsTable = (props) => {
    const { currentUser, renderProfile, showAction } = props
    const getTable = () => {
        return(
            <div className="table-responsive pt-3">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th> S.No </th>
                            <th> Order Id </th>
                            <th> Line Items </th>
                            <th> Amount </th>
                            <th> Purchase Date </th>
                            {showAction ? <th> Actions </th> : null}
                        </tr>
                    </thead>
                    {getTableBody()}
                </table>
            </div>
        )
    }

    const getTableBody = () => {
        if(currentUser.purchase_plan === undefined){
            return <tbody><tr><td>No Order Summary Available</td></tr></tbody>
        }
        var output = currentUser.purchase_plan.reduce(function(o, cur) {
            // Get the index of the key-value pair.
            var occurs = o.reduce(function(n, item, i) {
              return (item.order_id === cur.order_id) ? i : n;
            }, -1)
            // If the name is found,
            if (occurs >= 0) {
              // append the current value to its list of values.
              o[occurs].amount = o[occurs].amount.concat(cur.amount);
              o[occurs].profile_id = o[occurs].profile_id.concat(cur.profile_id)
              // Otherwise,
            } else {
              // add the current item to o (but make sure the value is an array).
              var obj = {
                order_id: cur.order_id,
                amount: [cur.amount],
                profile_id: [cur.profile_id],
                purchase_date: cur.purchase_date
              };
              o = o.concat([obj])
            }
            return o;
        }, [])
        _.forEach(output, function(item){
            item['line_items'] = item.amount.length
        })
        if(_.isEmpty(output)){
            return <tbody><tr><td>No Order Summary Available</td></tr></tbody>
        }
        return(
            <tbody>
                {output.map((item, index) => {
                    let purchase_date = convertDate(item.purchase_date)
                    const amount = _.sum(item.amount)
                    const total = formatMoney(amount)
                    if(!showAction){
                        if(index < 5){
                            return(
                                <tr key={index}>
                                    <td> {index+1} </td>
                                    <td className="font-weight-bold">{item.order_id}</td>
                                    <td>{item.line_items}</td>
                                    <td>&#163;{total}</td>
                                    <td>{purchase_date}</td>
                                    {showAction ?
                                    <td>
                                        <button type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#viewOrder" onClick={() => renderProfile(item.profile_id, item.order_id, item.amount)}>
                                            View
                                        </button>
                                    </td>
                                    : null}
                                </tr>
                            )
                        }
                    }
                    else{
                        return(
                            <tr key={index}>
                                <td> {index+1} </td>
                                <td className="font-weight-bold">{item.order_id}</td>
                                <td>{item.line_items}</td>
                                <td>&#163;{total}</td>
                                <td>{purchase_date}</td>
                                {showAction ?
                                <td>
                                    <button type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#viewOrder" onClick={() => renderProfile(item.profile_id, item.order_id, item.amount)}>
                                        View
                                    </button>
                                </td>
                                : null}
                            </tr>
                        )
                    }
                })}
            </tbody>
        )
    }

    return(
        <div className="card-body">
            <h4 className="card-title pt-2">{showAction ? "Order Summary" : "Recent Purchase Summary"}</h4>
            {getTable()}
        </div>
    )
}

export default UserDetailsTable